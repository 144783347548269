import { useState, useEffect } from 'react'
import { useKeyword } from '../../contexts/KeywordContext.jsx'
import PanelSettingsContainer from './PanelSettingsContainer'
import usePanelSettings from './usePanelSettings'

const Left = () => {
  const { keyword, isInputEnglish } = useKeyword()
  const {
    handleOpenSettingsOverlay,
    handleCloseSettingsOverlay,
    showSettingsOverlay,
  } = usePanelSettings()

  // Initial dictionaries with dynamically set keyword:
  const dictionaries = {
    merriamWebster: {
      baseURL: 'https://www.merriam-webster.com/dictionary/',
      title: 'Merriam-Webster Dictionary',
    },
    urbanDictionary: {
      baseURL: 'https://www.urbandictionary.com/define.php?term=',
      title: 'Urban Dictionary',
    },
    freeDictionary: {
      baseURL: 'https://www.thefreedictionary.com/',
      title: 'The Free Dictionary',
    },
  }

  const [currentDictionary, setCurrentDictionary] = useState(
    dictionaries.merriamWebster
  )
  // const [src, setSrc] = useState(`${currentDictionary.baseURL}${keyword}`)

  const [src, setSrc] = useState(`${currentDictionary.baseURL}wise`)

  useEffect(() => {
    // Only update the src if keywordLang is "en"
    // if (isInputEnglish) {
    setSrc(`${currentDictionary.baseURL}${keyword}`)
    // }

    // console.log('keyword ', keyword)
    // console.log('isInputEnglish ', isInputEnglish)
  }, [keyword, isInputEnglish, currentDictionary])

  const handleDictionaryChange = (dictionary) => {
    setCurrentDictionary(dictionary)
  }

  return (
    <div className="left">
      <div className="left__iframe-wrapper">
        <iframe
          src={src}
          width="100%"
          height="100%"
          id="cambridge"
          title={currentDictionary.title}
          loading="lazy"
          style={{
            transform: 'scale(0.9)',
            transformOrigin: '0 0',
            width: '111%', // compensating for the scaling
            height: '111%', // compensating for the scaling
            border: 'none',
          }}
          referrerPolicy="no-referrer"
        >
          <p>
            Váš prohlížeč bohužel nepodporuje zobrazení tohoto elementu, zkuste
            prosím jiný prohlížeč.{' '}
          </p>
        </iframe>

        <PanelSettingsContainer
          handleCloseSettingsOverlay={handleCloseSettingsOverlay}
          handleOpenSettingsOverlay={handleOpenSettingsOverlay}
          showSettingsOverlay={showSettingsOverlay}
        >
          {/* overlay content: */}
          <div className="panel-settings__overlay__buttons">
            {[
              dictionaries.merriamWebster,
              dictionaries.urbanDictionary,
              dictionaries.freeDictionary,
            ].map((dictionary_, i) => {
              if (dictionary_.title === currentDictionary.title) return null
              return (
                <button
                  key={i}
                  onClick={() => {
                    handleDictionaryChange(dictionary_)
                    handleCloseSettingsOverlay()
                  }}
                  className="button"
                >
                  {dictionary_.title}
                </button>
              )
            })}
          </div>
        </PanelSettingsContainer>
      </div>
    </div>
  )
}

export default Left
