import { axiosBearer } from './axiosInstances.js'

// cz > en
export async function fetchCzTranslation(keyword) {
  const response = await axiosBearer.get(`/pole/czpreklad?k=${keyword}`)
  return response.data.data.en
}

// images
export async function fetchImages(keyword) {
  const response = await axiosBearer.get(`/pole/obrazky?k=${keyword}`)
  return response.data.data.images
}

// get mnemonic
export async function getMnemonic(keyword) {
  const response = await axiosBearer.get(`/pole/pomucka?k=${keyword}`)
  return response.data.data
}

// Fetch Czech Translation
export async function fetchCzDict(keyword) {
  try {
    const response = await axiosBearer.get(`/pole/cz?k=${keyword}`)
    return response.data.data
  } catch (error) {
    throw new Error(`Error fetching czDict: ${error.message}`)
  }
}

// Fetch Czech definition & example
export async function fetchDefinitionAndExample(keyword) {
  const response = await axiosBearer.get(`/pole/definiceAVeta?k=${keyword}`)
  return response.data.data
}

// fetch deckNames
export async function fetchDeckNames(statusInfo, deckId = false) {
  // console.log('status info  fetchdecknames 1', statusInfo)
  try {
    // console.log('status info  fetchdecknames 2', statusInfo)
    const response = await axiosBearer.get(
      `/balicky?statusInfo=${statusInfo}${deckId ? `&deckId=${deckId}` : ''}`
    )
    return response.data.data
  } catch (error) {
    throw new Error(`Error fetching deck names: ${error.message}`)
  }
}
