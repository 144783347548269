import React from 'react'
import styled from 'styled-components'
// Components
import FullButton from '../Buttons/FullButton'
// Assets
// import HeaderImage from '/public/assets/img/header-img.png'
// import HeaderImage from '/public/assets/images/recommends.jpeg'
// import HeaderImage from '/public/assets/images/header_image.svg'
// import HeaderImage from '/public/assets/images/header_x.svg'
import HeaderImage from '/public/assets/images/header_x.svg'
// import QuotesIcon from '/public/assets/svg/Quotes'
import Dots from '/public/assets/svg/Dots'
import H1 from '/src/components/H1'
import Button from '/src/components/10_utils/Button'

export default function Header() {
  console.log(
    'header: import.meta.env.VITE_STAGING_API_KEY',
    import.meta.env.VITE_STAGING_API_KEY
  )

  console.log('hovno ? ')
  return (
    <Wrapper
      id="home"
      className="container flexSpaceCenter"
      style={{
        //   // minHeight: '100vh',
        // height: '95vh',
        overflow: 'hidden',
        //   maxHeight: '100vh',
        //   // position: 'relative',
        //   zIndex: 1,
      }}
    >
      <LeftSide className="flexCenter">
        <div>
          <ResponsiveH1 className="extraBold">
            Chytré a moderní učení angličtiny
          </ResponsiveH1>
          <HeaderP className="semiBold">
            Překlad, učení, organizace i opakování slovíček v jediném přehledném
            systému
          </HeaderP>
          <BtnWrapper>
            {/* <FullButton title="Zkusit zdarma" /> */}
            <Button
              text="Zkusit zdarma"
              inlineStyle={{
                // padding: '20px 40px',
                fontSize: 'var(--font-size-h2)',
                borderRadius: 'var(--border-radius-medium)',
              }}
            />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper style={{ paddingTop: '1rem' }}>
          <Img
            className="radius8"
            src={HeaderImage}
            alt="office"
            style={{ zIndex: 9 }}
          />
          {/* <QuoteWrapper className="flexCenter">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="whiteColor">
                Friends, such as we desire, are dreams and fables. Friendship
                demands the ability to do without it.
              </p>
              <p
                className="font13 orangeColor textRight"
                style={{ marginTop: '10px' }}
              >
                Ralph Waldo Emerson
              </p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper> */}
        </ImageWrapper>
        {/* <GreyDiv className="lightBg"></GreyDiv> */}
      </RightSide>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding-top: 43px;
  width: 100%;

  /* min-height: 840px; */
  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (min-width: 961px) {
    /* height: 95vh; */
  }
`
const LeftSide = styled.div`
  width: 50%;
  margin: 0 auto;
  /* height: 100%; */
  @media (max-width: 960px) {
    width: 85%;
    /* order: 2; */
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 0 0 10px 0;
  }
`
const RightSide = styled.div`
  width: 50%;
  /* height: 100%; */
  @media (max-width: 960px) {
    width: 90%;
    order: 1;
    margin-top: 30px;
    /* text-align: center; */
  }
`
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 15 50px 15;
  font-size: var(--font-size-medium);
  margin-top: 23px;
  margin-bottom: 23px;

  /* line-height: 1.5rem; */
  @media (max-width: 960px) {
    padding: 15px 0 33px 0;
    text-align: center;
    max-width: 100%;
  }
`
const BtnWrapper = styled.div`
  max-width: 300px;
  width: 80%;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 10px;
  background-color: var(--color-theme-1);
  z-index: 99;
  border-radius: var(--border-radius-medium);

  & p {
    font-size: var(--font-size-smaller);
  }

  @media (max-width: 960px) {
    left: 20px;
  }
  /* @media (max-width: 560px) {
    bottom: -50px;
  } */
`

const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`

const ResponsiveH1 = styled(H1)`
  line-height: 1.1;
  @media (min-width: 960px) {
    text-align: left;
  }
`
