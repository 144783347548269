import React, { useEffect, useState, useRef } from 'react'
import { useAuth } from '/src/contexts/AuthContext'
import { KeywordProvider } from '/src/contexts/KeywordContext.jsx'
import { useQueryClient } from '@tanstack/react-query'
import { resetDB } from '/src/services/fetchCards'
import Button from '/src/components/10_utils/Button'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useQueryDeckNames } from '/src/hooks/useQueryDecknames'
import ReviewChooseDeck from './ReviewChooseDeck'
import ReviewMode from './ReviewMode'
import { useGetDecks, useGetUser } from '/src/hooks/useVocabAPI'

const Review = () => {
  const [decks, setDecks] = useState([])
  const [user, setUser] = useState({})
  const [deckSelectedId, setDeckSelectedId] = useState(false)
  const [remainingLearnAheadCards, setRemainingLearnAheadCards] = useState([])
  const { currentUser } = useAuth()

  const {
    data: decksData,
    isError: isDecksError,
    isLoading: isDecksLoading,
    isSuccess: isDecksSuccess,
  } = useGetDecks(
    true, // statusInfo
    !deckSelectedId // enableCondition
  )

  const {
    data: userData,
    isError: isUserError,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetUser(currentUser?.uid, !!currentUser?.uid)

  useEffect(() => {
    if (isUserSuccess) {
      setUser(userData)
    }
  }, [isUserSuccess, userData])

  useEffect(() => {
    if (isDecksError) {
      toast.error('Nepodařilo se načíst balíčky')
    }
    // isLoading passed to ReviewChooseDeck

    if (isDecksSuccess) {
      // console.log('is success')
      const decksData_ = decksData.map((deck) => ({
        value: deck.deckName,
        _id: deck._id,
        statusInfo: deck.statusInfo,
      }))
      setDecks(decksData_)
    }
  }, [isDecksError, isDecksLoading, decksData, isDecksSuccess])
  // useQueryDeckNames(setDecks, true)

  // console.log('decks ', decks)
  const selectOptionsRef = useRef(null)

  // console.log(deckSelectedId)

  const handleDeckClick = (
    deckName,
    deckId,
    newCardsCount,
    toReviewCardsCount
  ) => {
    if (newCardsCount === 0 && toReviewCardsCount === 0) {
      toast.success('V balíčku teď nejsou žádné karty k opakování')
      return
    } else {
      setDeckSelectedId(deckId)
    }
  }

  const handleCardOptionClick = () => {
    console.log('selectoptionref  ', selectOptionsRef.current)
    selectOptionsRef.current.click()
  }

  const queryClient = useQueryClient()

  useEffect(() => {
    console.log('deckSelectedId ', deckSelectedId)
  }, [deckSelectedId])
  return (
    <KeywordProvider>
      {deckSelectedId ? (
        <ReviewMode
          decks={decks}
          deckSelectedId={deckSelectedId}
          setDeckSelectedId={setDeckSelectedId}
          userData={userData}
          remainingLearnAheadCards={remainingLearnAheadCards}
          setRemainingLearnAheadCards={setRemainingLearnAheadCards}
          user={user}
        ></ReviewMode>
      ) : (
        <ReviewChooseDeck
          decks={decks}
          isDecksLoading={isDecksLoading}
          handleDeckClick={handleDeckClick}
        />
      )}
    </KeywordProvider>
  )
}

export default Review

// review logic - SOMETHING LIKE THIS (GPT)
// let cards = [] // Holds the cards
// let reviewed = 0 // Number of reviewed cards

// const fetchMoreCards = async () => {
//   const skip = reviewed // Skip the cards that have already been reviewed
//   const limit = 20 // Number of cards to fetch

//   const response = await fetch(`/cards/DeckName?skip=${skip}&limit=${limit}`)
//   const newCards = await response.json()

//   // Shuffle newCards here if needed
//   // ...

//   cards.push(...newCards) // Add the new cards to the existing array
// }

// // Fetch initial batch
// fetchMoreCards()

// // Check if we need to fetch more cards
// const checkForMoreCards = () => {
//   if (reviewed >= cards.length / 2) {
//     fetchMoreCards()
//   }
// }

// // Call this function whenever a card is reviewed
// const onCardReviewed = () => {
//   reviewed++
//   checkForMoreCards()
// }
