import React from 'react'
import styled from 'styled-components'
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md'
import Button from '../../../components/10_utils/Button'

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`
const StyledButton = styled(Button)`
  margin: 0 0.5rem;
`

const VocabFooter = ({ currentPage, totalPages, setCurrentPage }) => {
  const moveToFirstPage = () => setCurrentPage(1)
  const moveToPrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1))

  const moveToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages)) // so currentPage doesn't exceed totalPages
  const moveToLastPage = () => setCurrentPage(totalPages)

  return (
    <FooterWrapper>
      <StyledButton
        disabled={currentPage === 1}
        onClick={moveToFirstPage}
        text={<MdOutlineKeyboardDoubleArrowLeft />}
      ></StyledButton>

      <StyledButton
        disabled={currentPage === 1}
        onClick={moveToPrevPage}
        text={<MdOutlineKeyboardArrowLeft />}
      ></StyledButton>

      <span>
        {currentPage} / {totalPages}
      </span>

      <StyledButton
        disabled={currentPage === totalPages}
        onClick={moveToNextPage}
        text={<MdOutlineKeyboardArrowRight />}
      ></StyledButton>

      <StyledButton
        disabled={currentPage === totalPages}
        onClick={moveToLastPage}
        text={<MdOutlineKeyboardDoubleArrowRight />}
      ></StyledButton>
    </FooterWrapper>
  )
}

export default VocabFooter
