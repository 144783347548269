import React, { useEffect } from 'react'
import TopNavbar from '/src/components/Nav/TopNavbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import './index.css'
import { Navbar } from './components'
import Profile from './pages/Profile/Profile'
import Home from './pages/Home/Home'
import './media_query.css'
import Review from './pages/Review/Review'
import Learn from './pages/Learn/Learn'
// import Learn2 from './pages/Learn/Learn2'
import Settings from './pages/Settings/Settings'
// import SignIn from './pages/SignIn/SignIn'
// import SignUp from './pages/SignUp/SignUp'
import { AuthProvider } from './contexts/AuthContext'
import { ModalProvider } from './contexts/ModalContext'
import ErrorMessage from './layouts/ErrorMessage'
import WithPrivateRoute from './utils/WithPrivateRoute'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import ChangePassword from './pages/ChangePassword.jsx'
import { ActivePanelsProvider } from './contexts/ActivePanels.jsx'
import { UserProvider } from './contexts/UserContext.jsx'
import { GlobalNotificationProvider } from './contexts/GlobalNotificationContext.jsx'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { CheckedImagesProvider } from './contexts/CheckedImagesContext'
// import { ReactQueryContainer } from './contexts/ReactQueryContainer'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from './hooks/useReactQueryClient'
import Vocab from './pages/Vocabulary/Vocab'
import AccessApp from './pages/AccessApp'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Blog from './pages/Blog/Blog'
import Contact from './pages/Contact/Contact'
import Other from './pages/Other/Other'
import Article from '/src/components/Sections/articles/article/Article'

const App = () => {
  return (
    <Router>
      <GlobalNotificationProvider>
        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
        />
        {/* <ReactQueryContainer> */}
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserProvider>
              <ModalProvider>
                <ErrorMessage />
                <div className="gradient__bg">
                  <div className="main">
                    <TopNavbar />

                    {/* Routes using React Router v6 */}
                    {/* 1. public routes */}
                    <Routes>
                      <Route path="/" element={<Home />} />
                      {/* <Route
                        exact
                        path="/profile"
                        // element={
                        //   <WithPrivateRoute>
                        //     <Profile />
                        //   </WithPrivateRoute>
                        // }
                      /> */}

                      <Route
                        path="/zapomenute-heslo"
                        element={<ForgotPassword />}
                      />
                      <Route path="/blog" element={<Blog />} />

                      <Route path="/blog/:postId" element={<Article />} />

                      <Route path="/kontakt" element={<Contact />} />
                      <Route path="/prihlasit" element={<AccessApp />} />
                      <Route
                        path="/email-overen"
                        element={<AccessApp emailVerified={true} />}
                      />

                      {/* 2. private routes */}
                      <Route
                        path="/uceni"
                        element={
                          <>
                            <WithPrivateRoute>
                              <ActivePanelsProvider>
                                <CheckedImagesProvider>
                                  {<Learn />}
                                </CheckedImagesProvider>
                              </ActivePanelsProvider>
                            </WithPrivateRoute>
                          </>
                        }
                      />

                      <Route
                        path="/opakovani"
                        element={
                          <WithPrivateRoute>
                            <Review />
                          </WithPrivateRoute>
                        }
                      />

                      <Route
                        path="/slovnicek"
                        element={
                          <WithPrivateRoute>
                            <Vocab />
                          </WithPrivateRoute>
                        }
                      />

                      <Route
                        path="/nastaveni"
                        element={
                          <WithPrivateRoute>
                            <Settings />
                          </WithPrivateRoute>
                        }
                      />

                      <Route
                        exact
                        path="/zmenit-heslo"
                        element={
                          <WithPrivateRoute>
                            <ChangePassword />
                          </WithPrivateRoute>
                        }
                      />

                      <Route
                        path="/other"
                        element={
                          <WithPrivateRoute>
                            <Other />
                          </WithPrivateRoute>
                        }
                      />
                    </Routes>
                  </div>
                </div>
              </ModalProvider>
            </UserProvider>
          </AuthProvider>
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
        {/* </ReactQueryContainer> */}
      </GlobalNotificationProvider>
    </Router>
  )
}

export default App
