import React, { useState, useRef, useEffect } from 'react'
import { GoSearch } from 'react-icons/go'
import '../Learn.css'
import { IoSettingsSharp } from 'react-icons/io5'
import { FaHistory } from 'react-icons/fa'
// import iconCzech from '..//public/assets/images/cz.svg'
import { useKeyword } from '../../../contexts/KeywordContext.jsx'
import { useActivePanels } from '../../../contexts/ActivePanels'
import { fetchCzTranslation } from '../../../services/fetchFields'
import InputSearchForm from '../../../components/InputSearchForm'
// import { ReactComponent as CzIcon } from '/public/assets/images/icon-czech-republic.svg'
// import { ReactComponent as EnIcon } from '/public/assets/images/icon-united-kingdom.svg'
import czIconSrc from '/public/assets/images/icon-czech-republic.svg?url'
import enIconSrc from '/public/assets/images/icon-united-kingdom.svg?url'

const KeywordForm = ({ showChat, ankiFormRef, resetAnkiForm }) => {
  // console.log('czIconSrc', czIconSrc)
  const {
    keyword,
    setKeyword,
    isInputEnglish,
    setIsInputEnglish,
    previousKeyword,
    setPreviousKeyword,
    originalCzInput,
    setOriginalCzInput,
  } = useKeyword()
  const [keyword_, setKeyword_] = useState('')
  const inputRef = useRef(null)
  const [showHistory, setShowHistory] = useState(false)
  const [lastSearchedWordsItems, setLastSearchedWordsItems] = useState([])
  const formRef = useRef(null)

  useEffect(() => {
    !showChat && inputRef.current?.focus()
  }, [showChat])

  useEffect(() => {
    const storedItems =
      JSON.parse(localStorage.getItem('lastSearchedWords')) || []
    setLastSearchedWordsItems(storedItems)
  }, [])

  const [isSearchLangEnglish, setIsSearchLangEnglish] = useState(true)
  const cz_icon_src = '/public/assets/images/icon-czech-republic.svg'
  const en_icon_src = '/public/assets/images/icon-united-kingdom.svg'

  const handleFlagClick = () => {
    if (isSearchLangEnglish) {
      setIsSearchLangEnglish(false)
      // setIsInputEnglish(false)
    } else {
      setIsSearchLangEnglish(true)
      // setIsInputEnglish(true)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    resetAnkiForm()

    // if(keyword_.trim === "") return
    // if (ankiFormRef.current) ankiFormRef.current.reset() // ankiForm se resetuje i bez toho:
    // if (isInputEnglish) setKeyword(keyword_)
    if (isSearchLangEnglish) {
      setOriginalCzInput('')
      setKeyword(keyword_)
    } else {
      // set as keyword czech translation
      setOriginalCzInput(keyword_)
      const en = await fetchCzTranslation(keyword_)
      if (en) {
        setIsInputEnglish(true)
      }
      console.log(en)
      setKeyword(en)
    }
    setPreviousKeyword(keyword_) // save to localStorage
    saveKeywordToLocalStorage()
    formRef.current.reset()
  }

  const saveKeywordToLocalStorage = () => {
    const newItems = [
      ...lastSearchedWordsItems,
      { keyword: keyword_, en: isInputEnglish },
    ]

    if (newItems.length > 10) {
      newItems.shift()
    }

    setLastSearchedWordsItems(newItems)

    localStorage.setItem('lastSearchedWords', JSON.stringify(newItems))
  }

  const handleHistoryClick = () => {
    setShowHistory(!showHistory)
  }

  // event listener for closing history on click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showHistory &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowHistory(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showHistory, inputRef])

  return (
    <div className="middle__keyword-form__container">
      <div className="middle__keyword-form__container__top">
        <InputSearchForm
          placeholder={'hledat...'}
          onChange={(e) => {
            setKeyword_(e.target.value)
          }}
          inputSearchRef={formRef}
          onSubmit={(e) => handleSubmit(e)}
          leftButtonContent={
            <img
              src={isSearchLangEnglish ? enIconSrc : czIconSrc}
              width="100%"
              alt="jazyk hledaného slovíčka"
            />
            // <img
            //   src={isSearchLangEnglish ? en_icon_src : cz_icon_src}
            //   width="100%"
            //   alt="jazyk hledaného slovíčka"
            // />
          }
          handleLeftButtonClick={handleFlagClick}
        />

        {/* <form
          className="middle__keyword-form"
          onSubmit={(e) => handleSubmit(e)}
          ref={formRef}
        >
          <input
            type="text"
            placeholder="wise"
            className="middle__keyword-form__input"
            value={keyword_}
            onChange={(e) => {
              setKeyword_(e.target.value)
            }}
            ref={inputRef}
          />
          

          <button type="submit" className="middle__keyword-form__submit">
            <GoSearch />
          </button>
        </form> */}
        <button type="button" className="middle__keyword-form__history-butto">
          <FaHistory
            style={{ color: 'rgba(30,30,30,0.8)' }}
            onClick={handleHistoryClick}
          />
        </button>
      </div>

      {showHistory && (
        <div className="middle__keyword-form__history-container">
          {lastSearchedWordsItems.map((item, index) => {
            return (
              <button
                key={index}
                type="button"
                className="middle__keyword-form__history-container__item"
                onClick={(e) => {
                  if (item.en) {
                    setIsSearchLangEnglish(en_icon_src)
                    setIsInputEnglish(true)
                  } else {
                    setIsSearchLangEnglish(cz_icon_src)
                    setIsInputEnglish(false)
                  }

                  setKeyword_(item.keyword)
                  handleSubmit(e)
                }}
              >
                <div className="middle__keyword-form__history-container__item__img-wrapper">
                  <img
                    src={item.en ? en_icon_src : cz_icon_src}
                    width="100%"
                    alt="jazyk hledaného slovíčka"
                  />
                </div>
                <span>{item.keyword}</span>
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default KeywordForm
