import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useKeyword } from '../../../contexts/KeywordContext'
import { fetchCzDict } from '../../../services/fetchFields'
import defaultCzDictContent from '/public/assets/data/defaultCzDictContent'
const CzDict = ({ setGalleryHeight, setCzShort }) => {
  const { keyword, previousKeyword, originalCzInput, setOriginalCzInput } =
    useKeyword()
  const defaultContent = { czMain: defaultCzDictContent, czShort: 'bla' }

  const [czMain, setCzMain] = useState(defaultCzDictContent)
  const czDictRef = useRef(null) //

  useEffect(() => {
    if (keyword === 'wise' && previousKeyword === null) {
      // first render
      return
    }
    // setLoading(true)
    let keywordToFetch = keyword
    if (originalCzInput) keywordToFetch = originalCzInput
    fetchCzDict(keywordToFetch)
      .then((data) => {
        setCzMain(data.czMain)
        // console.log(data)
        setCzShort(data.czShort)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        // setLoading(false)
      })
  }, [keyword])

  useLayoutEffect(() => {
    // useEffect(() => {
    if (czDictRef.current) {
      const czDictHeight = czDictRef.current.offsetHeight
      // console.log('czDictHeight: ', czDictHeight)
      // setGalleryHeight(window.innerHeight - czDictHeight)
    }
  }, [])

  return (
    <div className="right__cz-dict" ref={czDictRef}>
      {czMain.map((box, i) => (
        <div key={i} dangerouslySetInnerHTML={{ __html: box }}></div>
      ))}
    </div>
  )
}

export default CzDict
