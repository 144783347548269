import Footer from '/src/components/Sections/Footer'
// import Navbar from '/src/components/Nav/TopNavbar'
// import Hello from '/src/components/Sections/hello/Hello'
import ContactForm from '/src/components/Contact-form'

const Contact = () => {
  return (
    <>
      {/* <Navbar /> */}
      <ContactForm />
      {/* <Hello /> */}
      <Footer />
    </>
  )
}

export default Contact
