import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth, applyActionCode } from 'firebase/auth'
import H2 from '/src/components/H2'
import HomeModalContent from './Home/HomeModalContent'

const AccessApp = ({ emailVerified }) => {
  // const [emailVerified, setEmailVerified] = useState(false)
  const [login, setLogin] = useState(true)
  const [showEmailVerified, setShowEmailVerified] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    const auth = getAuth()
    const urlParams = new URLSearchParams(window.location.search)
    const oobCode = urlParams.get('oobCode')

    if (emailVerified) {
      if (oobCode) {
        applyActionCode(auth, oobCode)
          .then(async () => {
            await auth.currentUser.reload()
            if (auth.currentUser.emailVerified) {
              setShowEmailVerified(true)
              // navigate('/opakovani');
            } else {
              // Handle the case where email is still not verified
            }
            // setEmailVerified(true)
            // setShowEmailVerified(true)
            // navigate('/opakovani')

            // Redirect or update state as needed after successful verification
          })
          .catch((error) => {
            // Handle error, show message to user
          })
      }
    }
  }, [])

  const loginText = {
    header: 'Vítejte zpět',
    action: 'přihlášení',
    alternativeText: 'Ještě nemáte účet?',
    alternativeLink: 'Vytvořit nový',
    alternativeOptional: 'Obnovit zapomenuté heslo',
  }

  const registrationText = {
    header: 'Přidejte se',
    action: 'registrace',
    alternativeText: 'Už máte účet?',
    alternativeLink: 'Přihlásit se',
    alternativeOptional: '',
  }

  return (
    <div>
      {emailVerified && showEmailVerified && (
        <H2>Email ověřen, nyní se můžete přihlásit 👍</H2>
      )}
      <HomeModalContent
        login={login}
        setLogin={setLogin}
        text={login ? loginText : registrationText}
      />
    </div>
  )
}

export default AccessApp
