import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '/src/components/10_utils/Button'
import { FaCheckCircle } from 'react-icons/fa'
import { formatTimeFromMinutes } from '/src/utils/formatDate.js'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 3rem;
  margin-top: 3rem;
`

const InnerFlexContainer = styled(Container)`
  gap: 1rem;
`

const ReviewFinished = ({
  pendingCards,
  decks,
  handleGoToDecks,
  deckSelectedId,
  setDeckSelectedId,
}) => {
  console.log('decks ', decks)
  const [nextDeckWithDueCards, setNextDeckWithDueCards] = useState(false)
  useEffect(() => {
    const deckIndex = decks.findIndex((deck) => deck._id === deckSelectedId)
    const nextDeckWithDueCards_ = [
      // two new arrays: one with the decks after the selected deck, and one with the decks before the selected deck
      ...decks.slice(deckIndex + 1),
      ...decks.slice(0, deckIndex),
    ].find((deck) => {
      return (
        deck._id !== deckSelectedId &&
        (deck.statusInfo.toReview > 0 || deck.statusInfo.learning > 0)
      )
    })
    console.log('nextDeckWithDueCards_ ', nextDeckWithDueCards_)
    if (nextDeckWithDueCards_) {
      setNextDeckWithDueCards(nextDeckWithDueCards_._id)
    } else {
      setNextDeckWithDueCards(false)
    }
  }, [])

  useEffect(() => {
    console.log('nextDeckWithDueCards ', nextDeckWithDueCards)
  }, [nextDeckWithDueCards])
  return (
    <Container>
      <h2>
        Balíček: {decks.find((deck) => deck._id === deckSelectedId).value}{' '}
      </h2>
      <FaCheckCircle color="rgb(26, 137, 23)" fontSize={'6rem'} />

      {/* {pendingCards?.length > 0 ? (
        <InnerFlexContainer>
          <p>
            Nejbližší opakování za:{' '}
            {formatTimeFromMinutes(
              (new Date(pendingCards[0]?.SRS.dueDate) - new Date()) / 1000 / 60
            )}
            .
          </p>

          <p>Počet zbývajících karet: {pendingCards.length}</p>
        </InnerFlexContainer>
      ) : (
        <p>Pro dnešek je balíček hotový!</p>
      )} */}

      <p>Prozatím hotovo!</p>

      <InnerFlexContainer>
        {nextDeckWithDueCards && (
          <Button
            text="opakovat jiný"
            onClick={() => {
              setDeckSelectedId(nextDeckWithDueCards)
            }}
          />
        )}
        <Button text="zpět" onClick={handleGoToDecks} />
      </InnerFlexContainer>
    </Container>
  )
}

export default ReviewFinished
