import axios from 'axios'
import { handleRequestError } from '/src/utils/handleRequestError.js'
import { getAuth } from 'firebase/auth'
const auth = getAuth()
const baseURL = import.meta.env.VITE_APP_SERVER_BASE_URL

// console.log(
//   'import.meta.env.VITE_STAGING_API_KEY',
//   import.meta.env.VITE_STAGING_API_KEY
// )

const stagingApiKey = import.meta.env.VITE_APP_STAGING_API_KEY || ''
// const stagingApiKey = 'hovno'

// Instance with Bearer token
const axiosBearer = axios.create({
  baseURL: baseURL,
  // headers: {
  //   'x-api-key': stagingApiKey,
  // },
})

axiosBearer.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken()
    // console.log('token', token)

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

// Instance without Bearer token
const axiosWithoutBearer = axios.create({
  baseURL: baseURL,
  headers: {
    'x-api-key': stagingApiKey,
  },
})

axiosBearer.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  (error) => {
    handleRequestError(error)
    // if (error.response?.status === 429) {
    //   // Any status codes that falls outside the range of 2xx cause this function to trigger

    //   // Handle rate limit exceeded globally
    //   console.log(`Rate limit exceeded: ${error.response}`)
    // } else {
    //   // Handle other errors globally
    //   console.log(error)
    //   console.log(`An error occurred: ${error.response}`)
    // }
    return Promise.reject(error)
  }
)

axiosWithoutBearer.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    handleRequestError(error)
    return Promise.reject(error)
  }
)

export { axiosWithoutBearer, axiosBearer }
