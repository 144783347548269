import { createContext, useContext, useState } from 'react'

const KeywordContext = createContext()

export function useKeyword() {
  return useContext(KeywordContext)
}

export function KeywordProvider({ children }) {
  const [keyword, setKeyword] = useState('wise')
  const [isInputEnglish, setIsInputEnglish] = useState(true)
  const [previousKeyword, setPreviousKeyword] = useState(null)
  const [originalCzInput, setOriginalCzInput] = useState('')

  const value = {
    keyword,
    setKeyword,
    isInputEnglish,
    setIsInputEnglish,
    previousKeyword,
    setPreviousKeyword,
    originalCzInput,
    setOriginalCzInput,
  }

  return (
    <KeywordContext.Provider value={value}>{children}</KeywordContext.Provider>
  )
}
