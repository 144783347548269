import React from 'react'
import styled from 'styled-components'
import SettingsTabRow from './SettingsTabRow'

const SettingsTabContent = ({ settings }) => {
  return (
    <>
      {Object.values(settings).map((setting, i) => (
        <SettingsTabRow
          key={setting.key}
          key_={setting.key}
          leftSide={setting.label}
          rightSide={setting.content}
        />
      ))}
    </>
  )
}

export default SettingsTabContent
