import { useRef } from 'react'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import app from '../../../services/firebase'
import { sendEmailVerification } from 'firebase/auth'
import { useAuth } from '../../../contexts/AuthContext'
import { signUp, sendVerificationEmail } from '/src/services/fetchUser'
import ReCAPTCHA from 'react-google-recaptcha'
import Loading from '/src/components/Loading'

const SignUp = ({ setVerificationEmailSent }) => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const captchaRef = useRef(null)

  const { currentUser, register, setError } = useAuth()

  const [errorMessage, setErrorMessage] = useState(false)

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate('/')
  //   }
  // }, [currentUser, navigate])

  //Declaring the onSubmit function
  async function handleFormSubmit(e) {
    e.preventDefault()
    const token = captchaRef.current.getValue()
    if (!token) {
      setError('Please complete the CAPTCHA verification')
      return // Exit the function if CAPTCHA is not verified
    }
    captchaRef.current.reset()

    if (password !== confirmPassword) {
      return setError('Passwords do not match')
    }

    setError('')
    setErrorMessage(false)
    setLoading(true)

    try {
      const auth = getAuth(app)
      const userCredential = await register(email, password)
      const user = userCredential.user

      await signUp({ id: user.uid, email: user.email }, 'email', token)
      await sendVerificationEmail(user.email)
      setVerificationEmailSent(true)
      console.log('User is registered:', user)

      // navigate('/profile') // Uncomment this if navigation is needed after registration
    } catch (error) {
      const errorCode = error?.code
      const errorMessage = error?.message
      console.error('Error code:', errorCode)
      console.error('Error message:', errorMessage)
      console.log('error ', error)
      handleSignUpError(errorCode) // Handle the error appropriately
    } finally {
      setLoading(false)
    }
  }

  function handleSignUpError(errorCode) {
    if (errorCode === 'auth/email-already-in-use') {
      setErrorMessage('❌ Účet s tímto emailem už existuje.')
    } else if (errorCode === 'auth/invalid-email') {
      setErrorMessage('❌ Zadejte prosím platný email.')
    } else {
      setErrorMessage(
        '❌ Bohužel se něco nepovedlo 😔 Zkuste to prosím znovu, případně mě kontaktujte.'
      )
    }
  }

  useEffect(() => {
    console.log('loading ', loading)
  }, [loading])

  return (
    <form className="portal__modal__form" onSubmit={handleFormSubmit}>
      <div className="portal__modal__form__inputs">
        <div>
          <label htmlFor="email-address">Email:</label>
          <input
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            required
            value={email}
            placeholder="petr@seznam.cz"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Heslo:</label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            value={password}
            placeholder="alespoň 7 znaků"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Potvrďte heslo:</label>
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            autoComplete="current-password"
            required
            value={confirmPassword}
            placeholder="potvrďte heslo"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className="portal__modal__form__submit">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
          />
          <button
            type="submit"
            className="portal__modal__form__submit__button"
            disabled={loading}
          >
            {loading ? <Loading color="black" size="40" /> : 'Registrovat'}
            {/* Registrovat */}
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    </form>
  )
}

export default SignUp
