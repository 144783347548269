import React from 'react'

const Image = ({ src, alt, width, height, className, style }) => {
  // You can add additional logic here if needed
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      style={style}
    />
  )
}

export default Image
