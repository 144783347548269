import { RiMenu3Line } from 'react-icons/ri'
import { IoSettingsSharp } from 'react-icons/io5'

const PanelSettingsButtonOpeningModal = ({
  setShowModal,
  setModalContent,
  modalContent,
}) => {
  const handleClick = () => {
    console.log('modal před ', modalContent)
    setModalContent('quickLinks')
    console.log('modal po ', modalContent)
  }
  return (
    <button className="panel-settings__button button" onClick={handleClick}>
      <IoSettingsSharp />
    </button>
  )
}

export default PanelSettingsButtonOpeningModal
