import { RiMenu3Line } from 'react-icons/ri'
import { IoSettingsSharp } from 'react-icons/io5'

const PanelSettingsButton = ({
  handleOpenSettingsOverlay,
  settingsButtonRef,
}) => {
  return (
    <button
      className="panel-settings__button button"
      onClick={handleOpenSettingsOverlay}
      ref={settingsButtonRef}
    >
      <IoSettingsSharp />
    </button>
  )
}

export default PanelSettingsButton
