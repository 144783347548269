import { GoSearch } from 'react-icons/go'
import styled from 'styled-components'

const InputSearchForm = ({
  label,
  id,
  placeholder,
  value,
  onChange,
  onSubmit,
  leftButtonContent,
  handleLeftButtonClick,
  inputSearchRef,
  customSubmitButton,
  submitButtonType = 'button',
}) => {
  // console.log('customsubmit button ', customSubmitButton)
  return (
    <Form
      $customSubmitButton={customSubmitButton}
      ref={inputSearchRef}
      //   onSubmit={onSubmit}
    >
      <Label htmlFor={id}>{label}</Label>
      <Input
        type="text"
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        $leftButtonContent={leftButtonContent}
      />

      {/* left button */}
      {leftButtonContent && (
        <Button
          type="button"
          style={{ left: '10px', right: 'auto', width: '30px', height: '30px' }}
          onClick={handleLeftButtonClick}
        >
          {leftButtonContent}
        </Button>
      )}

      {/* right button */}
      {customSubmitButton ? (
        customSubmitButton
      ) : (
        <Button type={submitButtonType} onClick={onSubmit}>
          <GoSearch />
        </Button>
      )}
    </Form>
  )
}

export default InputSearchForm

const Form = styled.form`
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => (props.$customSubmitButton ? 'column' : 'row')};

  gap: ${(props) => (props.$customSubmitButton ? '0.6rem' : '0')};
  justify-content: center;
  align-items: center;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
`

const Input = styled.input`
  width: 100%;
  padding: 10px 0px 10px
    ${(props) => (props.$leftButtonContent ? '40px' : '10px')}; /* Extra padding on the right for the icon */
  border: 1px solid #dcdcdc;
  border: 1px solid #999999;

  border-radius: 25px;
  font-size: 16px;
  outline: transparent;
  transition: border 0.3s ease;
  &:focus {
    border-color: #aaa;
  }
`

export const Label = styled.label`
  position: absolute;
  font-size: var(--font-size-smaller-2);
  left: 0;
  top: 0;
  transform: translateY(-50%);
  color: var(--color-gray-light);
  color: var(--color-black);
  background-color: white; // Match the background of the container
  padding: 0 0.3rem;
  margin: 0 0.5rem;

  transition: 0.1s ease-out;
  z-index: 10; // Ensures the label is above the border
`
