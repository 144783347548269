import { dotWave } from 'ldrs'

dotWave.register()

// import 'ldrs/ring'

export default function Loading({ color = 'black', size = '47' }) {
  return (
    // <div
    //   aria-live="polite"
    //   //   aria-busy={isLoading}
    //   className="gl_loading-container"
    // >
    // <DotWave color={color} />
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '2000',
        transform: 'translate(-50%,-50%)',
      }}
    >
      {/* <Loading /> */}
      <l-dot-wave size={size} speed="1" color={color}></l-dot-wave>
    </div>
    // <div>ahoj</div>
    // </div>
  )
}

// Default values shown
