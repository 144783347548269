import React, { useState, useRef, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { IoSettingsSharp } from 'react-icons/io5'
import { IoHelpCircleOutline } from 'react-icons/io5'
import { IoLogOutOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { useAuth } from '/src/contexts/AuthContext'
import { getAuth } from 'firebase/auth' // This seems unused, so commented
// import userProfileIcon from '/public/assets/images/user_1.jpg'
import userProfileImgPlaceholder from '/public/assets/images/profile_image_placeholder.svg'

const ProfileButton = () => {
  const navigate = useNavigate()
  const { currentUser, logout } = useAuth()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const wrapperRef = useRef(null)

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  async function handleLogout() {
    try {
      console.log('user will be logged out: ', currentUser)
      await logout()
      navigate('/prihlasit')
      console.log('user was logged out: ', currentUser)
      // No need to navigate here; the useEffect will handle it once currentUser is undefined or null
    } catch (error) {
      console.log(error)
    }
  }

  const profileImage = currentUser?.account?.profileImage
    ? currentUser?.account?.profileImage
    : '/public/assets/images/profile_image_placeholder.svg'

  return (
    <ProfileButtonWrapper ref={wrapperRef}>
      <ProfileImage
        onClick={() => setDropdownOpen(!dropdownOpen)}
        src={
          currentUser?.account?.profileImage
            ? currentUser?.account?.profileImage
            : userProfileImgPlaceholder
        }
        alt="Profile"
      />
      {currentUser?.emailVerified && (
        <>
          {dropdownOpen && (
            <DropdownMenu>
              <Link to="/nastaveni">
                <MenuItem>
                  <IoSettingsSharp />
                  Nastavení
                </MenuItem>
              </Link>

              <Link to="/napoveda">
                <MenuItem>
                  <IoHelpCircleOutline />
                  Nápověda
                </MenuItem>
              </Link>

              <MenuItem onClick={handleLogout}>
                <IoLogOutOutline />
                Odhlásit se
              </MenuItem>
            </DropdownMenu>
          )}
        </>
      )}
    </ProfileButtonWrapper>
  )
}

// Styled Components
const ProfileButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

const ProfileImage = styled.img`
  width: 40px; // Adjust as needed
  height: 40px; // Adjust as needed
  border-radius: 50%;
`

const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  min-width: 150px;
  z-index: 1000;
`

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black);
  padding: 10px 15px;
  &:hover {
    background-color: #f5f5f5;
  }
  &:first-child(2) {
    font-size: 1.25rem;
  }
`

export default ProfileButton
