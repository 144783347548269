import React from 'react'
// Sections
import TopNavbar from '/src/components/Nav/TopNavbar'
import Header from '/src/components/Sections/Header'
import Services from '/src/components/Sections/Services'
import Projects from '/src/components/Sections/Projects'
import Blog from '/src/components/Sections/Blog'
// import Pricing from '/src/components/Sections/Pricing'
import Pricing from '/src/components/Sections/pricing/Pricing'
import Contact from '/src/components/Sections/Contact'
import Footer from '/src/components/Sections/Footer'
import Zigzag from '/src/components/Sections/Zigzag'
import Testimonials from '/src/components/Sections/Testimonials'
import Newsletter from '/src/components/Sections/Newsletter'
import Faq from '/src/components/Sections/faq/Faq'
import { useState } from 'react'
import Article from '/src/components/Sections/articles/article/Article'

export default function Landing() {
  return (
    <>
      <TopNavbar />
      {/* {displayPost !== null ? (
        <Article displayPost={displayPost} setDisplayPost={setDisplayPost} />
      ) : (
        <> */}
      <Header />
      {/* <Testimonials /> */}
      <Zigzag />
      {/* <Services />
      <Projects /> */}
      {/* <Blog />  */}
      <Pricing />
      <Faq />
      <Newsletter />
      {/* </>
      )} */}
      {/* <Contact /> */}
      <Footer />
    </>
  )
}
