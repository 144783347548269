//  my custom hook to fetch decknames - used in 2 places: AnkiForm, slovicka options
import { fetchDeckNames } from '../services/fetchFields'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'

export const useQueryDeckNames = (
  onSuccess,
  statusInfo = false,
  enableCondition
) => {
  const { data } = useQuery(
    ['getAllDecks', statusInfo], // queryKey, including variable
    () => fetchDeckNames(statusInfo), // queryFn
    {
      enabled: !!enableCondition,
      retry: 0,
      // staleTime: Infinity,
      onSuccess: (data) => {
        // console.log('Data received in useQueryDeckNames:', data)

        const mappedData = data.map((deck) => ({
          value: deck.deckName,
          _id: deck._id,
          statusInfo: statusInfo ? deck.statusInfo : null,
        }))
        // console.log('mapped data ', mappedData)
        onSuccess(mappedData)
      },
      onError: (error) => {
        console.error('Fetching failed', error)
        toast.error('Nepodařilo se načíst balíčky')
      },
    }
  )
}
