import React from 'react'
import styled from 'styled-components'

const StyledH2 = styled.h2`
  text-align: center;
  margin-bottom: 0.4rem;
  margin-top: 0.8rem;
  font-size: var(--font-size-h2);
`

const H2 = ({ children, ...props }) => {
  return <StyledH2 {...props}>{children}</StyledH2>
}

export default H2
