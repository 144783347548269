import queryClient from '/src/hooks/useReactQueryClient'
import { axiosBearer } from '../../../services/axiosInstances'
import SelectAction from '/src/components/SelectAction'
import { useEffect, useRef } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import InputSearchForm from '../../../components/InputSearchForm'
import { FaGoogle } from 'react-icons/fa'
import { FaFlag } from 'react-icons/fa'
import { useQueryDeckNames } from '../../../hooks/useQueryDecknames'
import Button from '../../../components/10_utils/Button'
import {
  useDeleteXCards,
  useChangeDeck,
  useModifyXCards,
  useModifyOneCard,
  useSearchCards,
} from '/src/hooks/useVocabAPI'
import Modal from '/src/components/10_utils/Modal'
import { useModal } from '/src/contexts/ModalContext'
import AnkiFormForm from '/src/pages/Learn/Middle/AnkiFormForm'
// import Select from '/src/components/Select'
import {
  useFormData,
  initialStateFormData,
} from '/src/pages/Learn/Middle/AnkiForm_helpers'
import { toast } from 'react-toastify'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'

export default function VocabOptions({
  onAdd,
  onFilter,
  areAllDataCheckboxesChecked,
  setAreAllDataCheckboxesChecked,
  onSearch,
  selectedCards,
  setSelectedCards,
  searchedKeyword,
  setSearchedKeyword,
  sortQuery,
  setSortQuery,
  filterDeck,
  setFilterDeck,
  filterFlag,
  setFilterFlag,

  setSearchResult,
  data,
  totalCards,
  currentPage,
  totalPages,
}) {
  const [selectedDeck, setSelectedDeck] = useState('all')
  const [selectedAction, setSelectedAction] = useState('')
  const [selectedSort, setSelectedSort] = useState('new_old')
  const [selectedFilter, setSelectedFilter] = useState('')
  const [triggerSearch, setTriggerSearch] = useState(false)

  const { showModal, setShowModal, modalContent, setModalContent } = useModal()
  const [decks, setDecks] = useState([{ value: '', _id: 1 }])
  const [ankiFormData, setAnkiFormData] = useState({}) // pro editing a card
  const [formData, setFormData] = useFormData()

  const [editedCardId, setEditedCardId] = useState('')
  const [searchedKeywordValue, setSearchedKeywordValue] = useState('')

  // const {
  //   data: searchResults,
  //   isLoading,
  //   isError,
  //   error,
  // } = useSearchCards(
  //   searchedKeyword,
  //   !!(triggerSearch && !!searchedKeyword),
  //   () => {
  //     setTriggerSearch(false)
  //   } // Run the query only if triggerSearch is true and searchedKeyword is not empty
  // )

  const [onlySuspendedCardsAreSelected, setOnlySuspendedCardsAreSelected] =
    useState(true)

  // všechny selectedCards jsou suspened => onlySuspendedCardsAreSelected = true
  useEffect(() => {
    const onlySuspendedCardsIds = data?.cards
      ?.filter((card) => card.suspended === true)
      .map((card) => card._id)

    if (onlySuspendedCardsIds?.length > 0) {
      const selectedCardsArray = Array.from(selectedCards)

      let areAllSelectedCardsSuspended = true
      for (let i = 0; i < selectedCardsArray.length; i++) {
        if (!onlySuspendedCardsIds.includes(selectedCardsArray[i])) {
          areAllSelectedCardsSuspended = false
          break
        }
      }

      setOnlySuspendedCardsAreSelected(areAllSelectedCardsSuspended)
    } else {
      setOnlySuspendedCardsAreSelected(false)
    }
  }, [selectedCards, data])

  const handleSearchSubmit = (e) => {
    e.preventDefault()
    // setTriggerSearch(true) // Set to true to trigger the query
    if (
      /[a-z]{2,}/i.test(searchedKeywordValue) ||
      searchedKeywordValue.length === 0
    )
      setSearchedKeyword(searchedKeywordValue)
    else {
      toast.warning('Zadejte alespoň 2 písmena', {
        autoClose: 2000,
      })
    }
  }

  const createCardMutation = useMutation({
    mutationFn: (formData) => {
      return axiosBearer.post('/karty', formData)
    },

    onSuccess: () => {
      toast.success('Uloženo', {
        autoClose: 600,
      })

      setFormData(initialStateFormData)
      queryClient.invalidateQueries({ queryKey: ['getAllDecks'] })
    },
    onError: () => {
      toast.error('Neuloženo', {
        autoClose: 800,
      })
    },
  })

  const handleSelectedDeckChange = (newValue, id) => {
    // const [deckName, deckId] = value.split(':deckid')
    setSelectedDeck(newValue)
  }

  const handleInputChange = (value, field) => {
    console.log('jsem v handleinput change')
    if (field === 'deckName') {
      console.log('handleInputChange - COLON')
      console.log('field', field, 'value', value)
      // eslint-disable-next-line no-extra-semi
      const [deckName, deckId] = value.split(':deckid')
      console.log('deckName', deckName, 'deckId', deckId)
      setAnkiFormData((prevState) => ({ ...prevState, deckName, deckId }))
    } else {
      console.log('field ', field, ' value ', value)
      console.log('anki form Data ', ankiFormData)
      // setAnkiFormData((prevState) => ({ ...prevState, [field]: value }))
      // setFormData((prevState) => ({ ...prevState, [field]: value }))
      setAnkiFormData((prevState) => ({ ...prevState, [field]: value }))
    }
  }

  const handleInputChange_forNewCards = (value, field) => {
    if (field === 'deckName') {
      // eslint-disable-next-line no-extra-semi
      const [deckName, deckId] = value.split(':deckid')
      setFormData((prevState) => ({ ...prevState, deckName, deckId }))
    } else {
      setFormData((prevState) => ({ ...prevState, [field]: value }))
    }
  }

  const onSuccesfulMutation = () => {
    // onsuccess function
    // setSelectedAction('')
    setSelectedCards(new Set())
    if (areAllDataCheckboxesChecked) setAreAllDataCheckboxesChecked(false)
    // toast.success('Hotovo', {
    //   autoClose: 500,
    // })
    if (modalContent === 'vocabActions') setModalContent(null)
    setFormData({
      image: '',
      cz: '',
      en: '',
      definition: '',
      example: '',
      mnemonic: '',
      extra: '',
      deckName: '',
      deckId: '',
      noteType: '',
    })

    setAnkiFormData({})
  }

  // useQueryDeckNames(setDecks)
  useQueryDeckNames(
    (data) => {
      setDecks(data)
    },
    false,
    true
  )

  useEffect(() => {
    console.log('decks', decks)
  }, [decks])

  useEffect(() => {
    // console.log('areAllDataCheckboxesChecked', areAllDataCheckboxesChecked)
  })

  const deleteXCardsMutation = useDeleteXCards(() => {
    onSuccesfulMutation()
  })

  const modifyXCardsMutation = useModifyXCards(() => {
    onSuccesfulMutation()
  })

  const modifyOneCardMutation = useModifyOneCard(() => {
    onSuccesfulMutation()
  })

  const deckNames = decks.map((deck) => (
    <option
      key={deck._id}
      value={`${deck.value}:deckid${deck._id}`}
      data-action={'changeDeck'}
    >
      🃏 {deck.value}
    </option>
  ))

  const handleEditCardSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    modifyOneCardMutation.mutate([editedCardId, ankiFormData])
  }

  // console.log('formData', formData)
  // console.log('ankiFormData', ankiFormData)

  const handleCreateCardSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    createCardMutation.mutate({ ...formData, ...ankiFormData })
    setFormData(initialStateFormData)

    // modifyOneCardMutation.mutate([editedCardId, ankiFormData])
  }

  // to stejné je v reviewMode:
  const handleActionChange = (action, id) => {
    // console.log('AreAllDataCheckboxesChecked ', areAllDataCheckboxesChecked)
    console.log('id ', id)
    console.log('action', action)
    // let action = e.target.value
    let targetDeck, targetDeckId

    // Check if the value contains a colon, which would mean it's a changeDeck action
    if (action.includes(':')) {
      // eslint-disable-next-line no-extra-semi
      ;[targetDeck, targetDeckId] = action.split(':deckid')
      action = 'changeDeck'
    }

    console.log('action ', action)
    // setSelectedAction(action)
    if (action === 'delete') {
      console.log("action was 'delete'")
      deleteXCardsMutation.mutate([
        [...selectedCards],
        areAllDataCheckboxesChecked,
      ])
      // }
    } else if (action === 'changeDeck') {
      console.log("action was 'changeDeck'")
      console.log('move to deckkk', targetDeck, targetDeckId)

      modifyXCardsMutation.mutate([
        [...selectedCards],
        areAllDataCheckboxesChecked,
        action,
        { targetDeck, targetDeckId },
      ])
    } else if (action === 'suspend') {
      console.log("action was 'suspend'")
      modifyXCardsMutation.mutate([
        [...selectedCards].filter((cardId) => !cardId.suspended),
        areAllDataCheckboxesChecked,
        action,
      ])
    } else if (action === 'unsuspend') {
      console.log("action was 'unsuspend'")
      modifyXCardsMutation.mutate([
        [...selectedCards],
        areAllDataCheckboxesChecked,
        action,
      ])
    } else if (action === 'forget') {
      console.log("action was 'forget'")
      modifyXCardsMutation.mutate([
        [...selectedCards],
        areAllDataCheckboxesChecked,
        action,
      ])
    }

    // if "modify" - open modal where will be AnkiForm component.user can modify fields values there. on submit, mutation will be called
    else if (action === 'modify') {
      // setShowModal(true)
      setModalContent('vocabActions')
      console.log('modifyig mode')
      console.log('selectedCards.size === 1', selectedCards)

      const cardsFromServer = data.cards.filter(
        (card) => card._id === [...selectedCards][0]
      )[0]
      setEditedCardId(cardsFromServer._id)
      const cardData = {
        ...cardsFromServer,
        cz: cardsFromServer.cz,
        en: cardsFromServer.en,
        mnemonic: cardsFromServer.mnemonic,
      }
      // setSelectedAction('')

      setFormData(cardData)
    }
  }

  // const handlePlusNewCardClick = () => {
  //   setModalContent('createNewCard')
  // }
  return (
    <>
      <OptionsContainer
      // style={{ height: '500px', overflow: 'auto', position: 'relative' }}
      >
        <div style={{ display: 'flex', gap: '0.3rem' }}>
          <InputSearchForm
            type="text"
            placeholder="wise"
            value={searchedKeywordValue}
            onChange={(e) => setSearchedKeywordValue(e.target.value)}
            onSubmit={(e) => handleSearchSubmit(e)}
            // leftButtonContent={<FaGoogle />}
          />

          {/* <Button text={'+'} type="button" onClick={handlePlusNewCardClick} /> */}
        </div>
        <SelectContainer>
          <label htmlFor="deckName">Hledat v:</label>
          <Select
            id="deckName"
            value={filterDeck}
            // onChange={(e) => {
            //   setSelectedDeck(e.target.value)
            // }}
            onChange={(e) => {
              // console.log(' deckname e.target.value', e.target.value)
              // setFilterDeck(e.target.value.split(':')[1])
              setFilterDeck(e.target.value)
            }}
          >
            <option value="">Všechny balíčky</option>
            {deckNames}
            {/* placeholderOption={{
              value: 'all',
              content: 'Všechny balíčky',
              key: 'key-all',
            }}
            options={deckNames} */}
          </Select>
        </SelectContainer>

        {/* other select to filter: */}
        <SelectContainer>
          <label htmlFor="sort">Seřadit:</label>
          <Select
            id="sort"
            value={sortQuery}
            onChange={(e) => {
              setSortQuery(e.target.value)
            }}
          >
            <optgroup label="Datum vytvoření">
              <option value="crt-d">nové 👉 staré</option>
              <option value="crt-a">staré 👉 nové</option>
            </optgroup>

            <optgroup label="Abeceda">
              <option value="alp-a">A 👉 Z</option>
              <option value="alp-d">Z 👉 A</option>
            </optgroup>
          </Select>
        </SelectContainer>

        <SelectContainer>
          <label htmlFor="filter">Filtrovat:</label>
          <Select
            id="filter"
            value={filterFlag}
            onChange={(e) => {
              setFilterFlag(e.target.value)
            }}
          >
            {/* <option value="" disabled> */}
            <option value="">-</option>
            <optgroup label="Barva">
              <option value="1">🟥 červená</option>
              <option value={'2'}>🟦 modrá </option>
              <option value={'3'}>🟩 zelená </option>
              <option value={'4'}>🟧 oranžová</option>
            </optgroup>
          </Select>
        </SelectContainer>
      </OptionsContainer>

      <OptionsContainer
        style={{
          position: 'sticky',
          top: '0',
          width: '100%',
          paddingTop: '0.2rem',
          paddingBottom: '0.2rem',
          backgroundColor: 'var(--color-gray-light-3)',
          marginBottom: '0.3rem',
        }}
      >
        <SelectContainer>
          <label htmlFor="actions">Akce:</label>

          <SelectAction
            // id="actions"
            // value={selectedAction}
            // value=""
            onChange={handleActionChange}
            selectedCards={selectedCards}
            onlySuspendedCardsAreSelected={onlySuspendedCardsAreSelected}
            decks={decks}
            placeholderOption={{ value: '', content: '⚡ Vyberte:' }}
            containerStyling={{
              display: 'flex',
              maxWidth: '300px',
              width: '90%',
              // justifyContent: 'space-around',
              margin: 0,
            }}
          />
          {/* <option disabled value="">
              Vyberte:
            </option>
            {selectedCards.size <= 1 && (
              <option value="modify">✏️ Upravit</option>
            )} */}

          {/* <option value="delete">🗑️ Smazat</option>
            <option value="suspend">❌ Vyřadit z opakování</option>
            <option value="forget">🚽 Zapomenout</option>
            <optgroup label="📂 Přesunout do balíčku...">{deckNames}</optgroup>
            <optgroup label="🚩 Označit:">
              <option>🟥 červeně</option>
              <option>🟦 modře </option>
              <option>🟩 zeleně </option>
              <option>🟧 oranžově</option>
            </optgroup>
          </SelectAction> */}
        </SelectContainer>
      </OptionsContainer>
      {modalContent === 'vocabActions' && (
        <Modal padding="5%">
          <AnkiFormForm
            formData={formData}
            handleInputChange={handleInputChange} // v objectu budou jen změněná pole
            handleSubmit={handleEditCardSubmit}
            deckNames={decks}
          />
        </Modal>
      )}

      {/* {modalContent === 'createNewCard' && (
        <Modal padding="5%">
          <AnkiFormForm
            formData={formData}
            handleInputChange={handleInputChange}
            // handleInputChange={handleInputChange_forNewCards} // v objectu budou všechny fieldy, třebaže prázdné
            handleSubmit={handleCreateCardSubmit}
            deckNames={decks}
          />
        </Modal>
      )} */}
    </>
  )
}

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const SelectContainer = styled.div`
  /* display: flex;
  flex-direction: row; */
  /* gap: 0.5rem; */
  display: grid;
  grid-template-columns: minmax(50px, 1fr) minmax(50px, 3fr);
  align-items: center;
`

const Select = styled.select`
  padding: 0.5rem;
  border-radius: var(--border-radius);
  border: 1px solid #ccc;
`

const AddButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: green;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
`

const SearchInput = styled.input`
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
`
