import React, { useState, useEffect } from 'react'
import VerifyEmail from '/src/components/VerifyEmail'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth'
import app from '../../../services/firebase'
import Button from '/src/components/10_utils/Button'
import '../sign.css'

import Loading from '/src/components/Loading'

// https://roboslovicka.firebaseapp.com/__/auth/handler

const SignIn = ({
  showEmailVerificationReminder,
  setShowEmailVerificationReminder,
  setModalContent,
}) => {
  const navigate = useNavigate()
  // const [email, setEmail] = useState('email531@seznam.cz')
  // const [password, setPassword] = useState('123456')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  async function onSubmit(e) {
    e.preventDefault()

    try {
      const auth = getAuth(app)
      // setError('')
      setLoading(true)
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )

      const user = userCredential.user

      // Check if email is verified
      if (user.emailVerified) {
        // User email is verified, grant access
        const user = auth.currentUser
        const token = user && (await user.getIdToken())

        const payloadHeader = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
        console.log(
          'dmyil verified + payloadHeader.headers.Authorization ',
          payloadHeader.headers.Authorization
        )
        // setModalContent(null)
        navigate('/opakovani')
      } else {
        // Inform user that they need to verify their email
        setShowEmailVerificationReminder(true)
        console.error('Please verify your email before signing in.')
        setErrorMessage('❌ Nesprávný / neověřený email nebo špatné heslo.')
      }
    } catch (error) {
      const errorCode = error.code
      // const errorMessage = error.message

      if (
        errorCode === 'auth/wrong-password' ||
        errorCode === 'auth/invalid-email' ||
        errorCode === 'auth/user-not-found'
      ) {
        setErrorMessage('❌ Nesprávný / neověřený email nebo špatné heslo.')
      } else {
        setErrorMessage(
          '❌ Bohužel se něco nepovedlo 😔 Zkuste to prosím znovu, případně mě kontaktujte.'
        )
      }

      console.error('Error signing in:', error.message)
    }
    setLoading(false)
  }

  return (
    <>
      {showEmailVerificationReminder ? (
        <VerifyEmail />
      ) : (
        <>
          <form onSubmit={onSubmit} className="portal__modal__form">
            <div className="portal__modal__form__inputs">
              <div>
                <label htmlFor="email-address">Email:</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="password">Heslo:</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="portal__modal__form__submit">
              {/* <Button
          text="Přihlásit se"
          type="submit"
        /> */}
              <button
                type="submit"
                className="portal__modal__form__submit__button"
              >
                {loading ? <Loading color="white" size="40" /> : 'Přihlásit se'}
                {/* Přihlásit se */}
              </button>
              <p>{errorMessage}</p>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default SignIn
