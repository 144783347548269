import Image from '/src/components/Image.jsx'
import FeatImage02 from '/public/assets/images/landing-chytré opakování.png'
import FeatImage03 from '/public/assets/images/landing-slovíčka.png'
import FeatImage01 from '/public/assets/images/landing-učení.png'

export default function Zigzag() {
  return (
    <section id="o-aplikaci">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div
          className="border-t border-gray-800"
          // style={{ paddingTop: '2rem' }}
        >
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
            {/* <h1 className="h2 mb-4"></h1> */}
            <h2
              className="font-bold header-color"
              style={{
                marginBlock: 'var(--margin-block)',
              }}
            >
              O aplikaci
            </h2>

            <div
              className="inline-flex text-sm font-semibold px-3 text-green-600 bg-green-200 rounded-full"
              style={{
                fontSize: 'var(--font-size-medium)',
                padding: '0.6rem 1.2rem',
              }}
            >
              Angličtinomat zrychluje a zjednodušuje učení angličtiny
            </div>
            {/* <p className="text-xl text-gray-400">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit laborum — semper quis lectus nulla.
            </p> */}
          </div>

          {/* Items */}
          <div className="grid gap-2">
            {/* 1st item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div
                    className="font-architects-daughter text-xl text-purple-600 mb-2"
                    style={{
                      fontSize: 'var(--font-size-h2)',
                      color: 'var(--color-black)',
                    }}
                  >
                    Překlad a uložení rychlostí bleku
                  </div>
                  {/* <h3 className="h3 mb-3">Keep projects on schedule</h3> */}
                  <p className="text-xl text-black-700 mb-4">
                    K přeložení a pochopení slovíček už nebudeš muset otevírat
                    několik slovníků najednou. Během pár kliknutí si uložíš
                    překlad včetně výslovnosti, obrázku, definice a příkladové
                    věty.
                  </p>
                  <ul className="text-lg -mb-2">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>
                        česko-anglický i anglický výkladový slovník na jedné
                        stránce
                      </span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>ChatGPT bot</span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>uložení mnemotechniky</span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>rychlé odkazy na další slovníky</span>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0"
                data-aos="fade-up"
                style={{
                  boxShadow: 'var(--box-shadow)',
                  border: '1px solid transparent',
                  borderRadius: 'var(--border-radius-medium)',
                }}
              >
                <Image
                  className="max-w-full mx-auto md:max-w-none h-auto"
                  src={FeatImage01}
                  width={540}
                  height={405}
                  alt="Features 01"
                  style={{ marginTop: '1rem' }}
                />
              </div>
            </div>

            {/* 2nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:order-2"
                data-aos="fade-left"
              >
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  <div
                    className="font-architects-daughter text-xl text-purple-600 mb-2"
                    style={{
                      fontSize: 'var(--font-size-h2)',
                      color: 'var(--color-black)',
                    }}
                  >
                    Algoritmus pro lepší pamatování
                  </div>
                  {/* <h3 className="h3 mb-3">Keep projects on schedule</h3> */}
                  <p className="text-xl mb-4">
                    Pro uložení do dlouhodobé paměti je důležité opakování.
                    Algoritmus sleduje, jak dobře si každé slovíčko pamatuješ a
                    podle toho tě testuje. Díky tomu se zaměříš na těžká
                    slovíčka a nebudeš ztrácet čas těmi lehkými.
                  </p>
                  <ul className="text-lg  -mb-2">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>ověřená metoda</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>algoritmus je přizpůsobitelný</span>
                    </li>
                    {/* <li className="flex items-center">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Amet consectetur adipiscing elit</span>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 rtl"
                data-aos="fade-up"
                style={{
                  boxShadow: 'var(--box-shadow)',
                  border: '1px solid transparent',
                  borderRadius: 'var(--border-radius-medium)',
                }}
              >
                <Image
                  className="max-w-full mx-auto md:max-w-none h-auto"
                  src={FeatImage02}
                  width={540}
                  height={405}
                  alt="Features 02"
                  style={{ marginTop: '1rem' }}
                />
              </div>
            </div>

            {/* 3nd item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div
                    className="font-architects-daughter text-xl text-purple-600 mb-2"
                    style={{
                      fontSize: 'var(--font-size-h2)',
                      color: 'var(--color-black)',
                    }}
                  >
                    Vše v přehledném systému
                  </div>
                  {/* <h3 className="h3 mb-3">Keep projects on schedule</h3> */}
                  <p className="text-xl mb-4">
                    Nemusíš psát slovíčka ručně do sešitů. V aplikaci je můžeš
                    libovolně upravovat, třídit do balíčků nebo tagovat.
                  </p>
                  <ul className="text-lg -mb-2">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>dostupné z prohlížeče (jakékoli zařízení)</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>export do Anki či PDF</span>
                    </li>
                    {/* <li className="flex items-center">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Amet consectetur adipiscing elit</span>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0"
                data-aos="fade-up"
                style={{
                  boxShadow: 'var(--box-shadow)',
                  border: '1px solid transparent',
                  borderRadius: 'var(--border-radius-medium)',
                }}
              >
                <Image
                  className="max-w-full mx-auto md:max-w-none h-auto"
                  src={FeatImage03}
                  width={540}
                  height={405}
                  alt="Features 03"
                  style={{ marginTop: '1rem' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
