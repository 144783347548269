import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import React, { useRef } from 'react'
export default function MenuComponent({ button, items }) {
  const buttonRef = useRef(null)

  return (
    <Menu
      menuButton={React.cloneElement(button, { buttonRef: buttonRef })}
      anchorRef={buttonRef}
    >
      {/* <Menu menuButton={button}> */}
      {items.map((item) => (
        <MenuItem key={item.text} onClick={item.onClick}>
          {item.text}
        </MenuItem>
      ))}
    </Menu>
  )
}
