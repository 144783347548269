import { axiosWithoutBearer } from './axiosInstances.js'

export async function contactUs(message, metadata, token = null) {
  const response = await axiosWithoutBearer.post('/other/contactUs', {
    message,
    metadata,
    token,
  })

  return response.data
}

export async function subscribe(email = '') {
  const response = await axiosWithoutBearer.post('/other/subscribe', {
    email,
  })

  return response.data
}

// export async function getDeckNames_anki() {
//   const response = await axiosBearer.post('http://127.0.0.1:8765', {
//     action: 'deckNames',
//     version: 6,
//   })

//   return response.data
// }
