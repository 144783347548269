import Card from './card/Card'

const PricingSection = () => {
  const pricing_data = [
    // {
    //   title: 'latte',
    //   price: 25,
    //   total_price: 25 * 3,
    //   membership_for: '3 měsíce',
    // },
    {
      title: 'silver',
      price: 30,
      total_price: 30 * 6,
      membership_for: 'na 6 měsíců',
    },
    {
      title: 'gold',
      price: 20,
      total_price: 20 * 12,
      membership_for: 'na 1 rok',
    },
    {
      title: 'platinum',
      price: 990,
      total_price: 0,
      membership_for: 'DOŽIVOTNĚ',
    },
  ]
  return (
    <section id="cena">
      <div className="container mx-auto px-10 border-b border-gray-800">
        <div className="text-center">
          <div>
            {/* <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold header-color w-3/4 mx-auto my-5"> */}
            <h2
              className="font-bold header-color"
              style={{
                marginBlock: 'var(--margin-block)',
              }}
            >
              {/* Cena jednoho kafe měsíčně ☕ */}
              Za málo peněz hodně muziky 👍
            </h2>
            {/* <p className="text-color my-5 w-2/4 mx-auto text-sm lg:text-lg">
              Všechny plány obsahují veškeré funkce.
            </p> */}
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-10 mb-4">
          {pricing_data.map((item, index) => (
            <Card
              key={item.title}
              pricing_data={pricing_data[index]}
              theMostFavourite={index === 1 ? true : false}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default PricingSection
