import { createContext, useContext, useState } from 'react'

const CheckedImagesContext = createContext()

export function useCheckedImages() {
  return useContext(CheckedImagesContext)
}

export function CheckedImagesProvider({ children }) {
  const [checkedImages, setCheckedImages] = useState([])

  const value = {
    checkedImages,
    setCheckedImages,
  }

  return (
    <CheckedImagesContext.Provider value={value}>
      {children}
    </CheckedImagesContext.Provider>
  )
}
