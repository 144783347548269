import React from 'react'
import styled from 'styled-components'

const StyledBasicSelect = styled.select`
  padding: 0.5rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-grey);
  width: 100%;

  background-color: #fff;
  box-shadow: 0 0 0 2px #aaa;
  transition: box-shadow 0.2s ease-in-out;
  &:focus {
    box-shadow: 0 0 0 2px #007bff;
  }
`
const BasicSelect = ({ children, onChange, value, inlineStyle }) => {
  return (
    <StyledBasicSelect onChange={onChange} value={value} style={inlineStyle}>
      {children}
    </StyledBasicSelect>
  )
}

export default BasicSelect
