import styled from 'styled-components'
import Button from '/src/components/10_utils/Button'
import H2 from '/src/components/H2'

const ButtonContainer = styled.div`
  display: flex;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
`

const ConfirmButton = styled.button`
  background-color: var(--color-green);
  background-color: var(--color-success);
  color: var(--color-white);
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
`

const CancelButton = styled.button`
  background-color: var(--color-danger);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
`

const ModalContentConfirmationAlert = ({
  message,
  detail,
  onConfirm,
  onCancel,
}) => {
  return (
    <>
      <H2>{message}</H2>
      <ul>
        <li>{detail}</li>
      </ul>
      <ButtonContainer>
        <Button backgroundColor="var(--color-green)" onClick={onConfirm}>
          Potvrdit
        </Button>

        <Button backgroundColor="var(--color-red)" onClick={onCancel}>
          Zrušit
        </Button>
      </ButtonContainer>
    </>
  )
}

export default ModalContentConfirmationAlert
