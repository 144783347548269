import React from 'react'
import { FaWindowMinimize } from 'react-icons/fa6'
export default function Header({ setShowChat }) {
  return (
    <div className="chat__header">
      <h4>gpt-3.5-turbo</h4>
      <div className="chat__header__buttons">
        <button
          onClick={() => {
            setShowChat(false)
          }}
        >
          <FaWindowMinimize />
        </button>
      </div>
    </div>
  )
}
