import Footer from '/src/components/Sections/Footer'
import TopNavbar from '/src/components/Nav/TopNavbar'
import Articles from '/src/components/Sections/articles/Articles'
// import Hello from '/src/components/sections/hello/Hello'
// import WorkFlow from '/src/components/sections/work-flow/Work-flow'

const Blog = () => {
  return (
    <>
      <TopNavbar />
      {/* <WorkFlow /> */}
      <Articles />
      {/* <Hello /> */}
      <Footer />
    </>
  )
}

export default Blog
