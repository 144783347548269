import React, { useState, useRef, useEffect } from 'react'
import { usePostOther } from '/src/hooks/useVocabAPI'
import { Editor } from '@tinymce/tinymce-react'
// import { useKeyword } from '../../../contexts/KeywordContext'
import { useKeyword } from '/src/contexts/KeywordContext'
import { useModal } from '../../../contexts/ModalContext'
import Modal from '../../../components/10_utils/Modal'
import { TbListSearch } from 'react-icons/tb'
import SearchMnemonicsModalContent from './SearchMnemonicsModalContent'

const AnkiFormRow = ({
  id,
  label,
  value,
  handleInputChange,
  customMnemonics,
  setCustomMnemonics,
  mnemonics,
  setMnemonics,
  enableCustomMnemonics = true,
  clearAnkiInputs,
  setClearAnkiInputs,

  // ankiFormRowEnRef,
}) => {
  const { keyword, isInputEnglish } = useKeyword()
  const { showModal, setShowModal, modalContent, setModalContent } = useModal()

  useEffect(() => {
    // if (id === 'definition') {
    editorRef?.current?.editor?.setContent('')
    // }
  }, [clearAnkiInputs, id])

  const editorRef = useRef(null)

  // console.log('editorRefen ', editorRef)

  // const [mnemonics, setMnemonics] = useState([])

  // const [mnemonics, setMnemonics] = useState([
  //   {
  //     id: 1,
  //     text: `<p>  alk ja kjls lasfkal lf skf alfjalfa ůlk fai aj kl jsv jal <i> alk sl jvsk jal jai ůa </i> jvi sů ai kjsjla lsf <b> sj </b> </p>`,
  //     custom: false,
  //   },
  //   {
  //     id: 2,
  //     text: `<p>  alk ja kjls la lsf <b> sj </b> </p>`,
  //     custom: false,
  //   },
  //   {
  //     id: 3,
  //     text: `<p>  alk ja <mark>  kjls la </mark> lsf <b> sj </b> </p>`,
  //     custom: false,
  //   },
  //   {
  //     id: 4,
  //     text: `<p>  alk ja kjls la lsf <b> sj </b>  fa fakjafkf lafa af alfjafkjea <u> faůk efi</u> lkjfaů efa faiefůi f  ai fa isůiv a ai e lva i e slk a f flafaa jllajj</p>`,
  //     custom: false,
  //   },
  // ])

  // useEffect(() => {
  //   console.log('mnemonics ', mnemonics)
  // }, [mnemonics])

  useEffect(() => {
    // console.log(
    //   'změnil se [keyword, isInputEnglish, id, value]; value: ',
    //   value
    // )
    // console.log('anki for usffec')
    const editor = editorRef?.current?.editor

    // Check if the editor is ready
    // if (editor && editor.initialized) {
    if (editor) {
      let newContent = ''
      // if (isInputEnglish) {
      if (id === 'en') {
        // newContent = `<p>${keyword}</p>`
        newContent = `<p>${value}</p>`
      } else if (id === 'cz' || id === 'definition' || id === 'mnemonic') {
        newContent = `<p>${value}</p>`

        // if (id === 'definition') {
        //   console.log('input je ', id + ' a new value je ', value)
        // }
      } else if (id === 'example') {
        // const regex = new RegExp(`\\b${keyword}\\b`) // matchne jen celé slovo
        // console.log('value ', value.toLowerCase())
        // console.log('keyword ', keyword.toLowerCase())
        const keyword_whateverCase = new RegExp(`${keyword}`, 'i')
        if (keyword_whateverCase.test(value)) {
          // console.log('match', keyword)
          newContent = `<p>${value.replace(
            keyword_whateverCase,
            `<span style="background-color: rgb(241, 196, 15);" data-mce-style="background-color: rgb(241, 196, 15);">$&</span>`
          )}</p>`
        } else {
          newContent = `<p>${value}</p>`
        }
      }
      // } else if (id === 'cz') {
      //   newContent = `<p>${keyword}</p>`
      // }
      editor.setContent(newContent)
    }
  }, [keyword, isInputEnglish, id, value])

  const [isEditorFocused, setEditorFocused] = useState(false)

  const handleEditorChange = (content, editor) => {
    handleInputChange(content, id)
    // tady asi dát setDefinition etc
    // console.log('Content was updated: ID: ', id + 'content ', content)

    // console.log('Content was updated: ID: ', id + 'content ', content)
  }

  const handleShowMoreMnemonicsClick = () => {
    console.log('modal now ', showModal)
    // setShowModal(true)
    setModalContent('searchMnemonics')
  }

  const postOtherMutation = usePostOther(() => {
    console.log('other mutation triggered')
  })

  const handleVote = (mnemonicId, voteType, event) => {
    event.stopPropagation() // Prevents the vote click from selecting the mnemonic
    // Implement voting logic here
    postOtherMutation.mutate([mnemonicId, voteType])
  }

  const handleMnemonicClick = (mnemonicText, event) => {
    if (id === 'mnemonic') {
      const editor = editorRef.current?.editor
      editor.setContent(mnemonicText)
      setModalContent(null)
    }
  }

  const handleFocus = () => {
    // console.log('Editor is focused')
    if (enableCustomMnemonics && id === 'mnemonic') {
      console.log('mnemonics ', mnemonics)
      if (mnemonics.length >= 1) {
        setModalContent('searchMnemonics')
      }
    }
  }

  return (
    <div className="middle__anki-form__row">
      <label
        htmlFor={id}
        className={`${
          isEditorFocused ? 'focused' : ''
        } middle__anki-form__row__floating-label`}
      >
        <span>{label}</span>
      </label>

      <div className="middle__anki-form__row__input" id={id}>
        <Editor
          key="withoutPlaceholder99"
          ref={editorRef}
          initialValue={value} // kdyby problémy, můžu zkusit value={value}
          // value={value}
          init={{
            forced_root_block: 'p',
            inline: true,
            menubar: false,
            // plugins: ['paste'],
            toolbar:
              'undo bold italic backcolor forecolor casechange aligncenter bullist numlist indent',
            color_map: [
              '#c2e0f4',
              'Blue', // First color option
              '#f1c40f',
              'Yellow', // Second color option
            ],
          }}
          onFocus={handleFocus}
          onEditorChange={handleEditorChange}
          tinymceScriptSrc="/wysiwyg/js/tinymce/tinymce.min.js"
        />
      </div>

      {enableCustomMnemonics && id === 'mnemonic' && (
        <button
          type="button"
          className="middle__keyword-form__submit showMore"
          onClick={handleShowMoreMnemonicsClick}
        >
          <TbListSearch />
        </button>
      )}

      {modalContent === 'searchMnemonics' && id === 'mnemonic' && (
        <Modal>
          <SearchMnemonicsModalContent
            customMnemonics={customMnemonics}
            setCustomMnemonics={setCustomMnemonics}
            mnemonics={mnemonics}
            setMnemonics={setMnemonics}
            onVote={handleVote}
            onSelectMnemonic={handleMnemonicClick}
            setModalContent={setModalContent}
          />
        </Modal>
      )}
    </div>
  )
}

export default AnkiFormRow
