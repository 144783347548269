import { QueryClient, QueryCache } from '@tanstack/react-query'

import { toast } from 'react-toastify'

// const ReactQueryContainer = () => {
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.log(query.meta)
      if (query?.meta?.errorMessage) {
        toast.error(query?.meta?.errorMessage, {
          autoClose: 1300,
        })
      } else {
        console.log(error)
      }
    },
  }),
})

//     return (

//     <div></div>
//   )
// }

export default queryClient
