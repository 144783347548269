import { AiOutlineCheck } from 'react-icons/ai'
import Button from '/src/components/10_utils/Button'
import styled from 'styled-components'

import { motion } from 'framer-motion'

const StyledRibbon = styled.div`
  position: absolute;
  /* top: -10px; */
  /* right: -10px; */
  background-color: green;
  border-radius: var(--border-radius);
  color: white;
  padding: 4px 8px;
  transform: rotate(30deg);
  /* transform-origin: top right; */
  font-size: 1.2rem;
  z-index: 10;
  right: 5px;
  top: 35px;
`

// interface CardInterface {
//     title: string;
//     price: number;
// }

// const Card = ({ title, price }: CardInterface) => {
const Card = ({ pricing_data, theMostFavourite = false }) => {
  const cardVariants = {
    initial: {
      y: -100,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  }

  return (
    <motion.div
      className="box-bg p-5 my-1 rounded-sm bg-gray-800 text-lg text-gray-400 grow testimonial-box"
      variants={cardVariants}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      style={{
        border: 'transparent 1px solid',
        borderRadius: 'var(--border-radius-medium)',
        position: 'relative',
      }}
    >
      {theMostFavourite && <StyledRibbon>Nejoblíbenější</StyledRibbon>}
      <span
        style={{
          color: 'var(--color-white)',
        }}
        className="main-color font-bold text-3xl"
      >
        {pricing_data.title}
      </span>
      <h2
        className="text-color text-2xl my-3"
        style={{ color: 'var(--color-white)' }}
      >
        <span className="text-4xl font-bold header-color">
          {pricing_data.price} Kč
        </span>{' '}
        {pricing_data.total_price === 0 ? '' : '/ měsíc'}
      </h2>
      {/* <p className="text-color my-3">
        Better insights for growing businesses that want more customers
      </p> */}
      <div className="my-5 border-b border-gray-600"></div>
      <h3 className="header-color my-2 font-semibold text-lg">
        Plán zahrnuje:
      </h3>
      <ul>
        <li className="text-color my-3 flex items-center gap-2">
          <AiOutlineCheck className="main-color" />
          <span>
            premium <b> {pricing_data.membership_for} </b>
            {pricing_data.total_price ? (
              <span>({pricing_data.total_price}&nbsp;Kč)</span>
            ) : (
              ''
            )}
          </span>
        </li>
        <li className="text-color my-3 flex items-center gap-2">
          <AiOutlineCheck className="main-color" />
          neomezené množství překladů a slovíček
        </li>
        <li className="text-color my-3 flex items-center gap-2">
          <AiOutlineCheck className="main-color" />
          AI bot (gpt-3.5-turbo) každý měsíc zpracuje 80&nbsp;000 tokenů (asi
          60&nbsp;000 slov)
        </li>
        <li className="text-color my-3 flex items-center gap-2">
          <AiOutlineCheck className="main-color" />
          zákaznická podpora
        </li>
        <li className="text-color my-3 flex items-center gap-2">
          <AiOutlineCheck className="main-color" />
          přístup k aktualizacím a novým funkcím
        </li>
      </ul>
      <div className="mt-7 mb-5 text-center">
        {/* <button className="bg-main w-full py-2 hover:bg-main-focus duration-300"> */}

        <Button
          text="Zkusit zdarma"
          inlineStyle={{
            // backgroundColor: 'var(--color-white)',
            backgroundColor: 'transparent',
            border: '1px solid var(--color-white)',
            color: 'var(--color-white)',
            // color: 'var(--color-theme-1)',
            fontSize: 'var(--font-size-medium)',
            width: '70%',
          }}
        />
        {/* </button> */}
      </div>
    </motion.div>
  )
}

export default Card
