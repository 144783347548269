import React from 'react'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'

const ChangePassword = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('x@gmail.com')
  const [password, setPassword] = useState('123456')
  const [confirmPassword, setConfirmPassword] = useState('123456')
  const [loading, setLoading] = useState(false)

  const { currentUser, register, setError, changePassword } = useAuth()

  // useEffect(() => {
  //   if (currentUser) {
  //     navigate('/')
  //   }
  // }, [currentUser, navigate])

  //Declaring the onSubmit function
  async function handleFormSubmit(e) {
    e.preventDefault()

    if (password !== confirmPassword) {
      return setError('Passwords do not match')
    }
    try {
      setError('')
      setLoading(true)
      await changePassword(password)
    } catch (error) {
      setError('Error signing up:', error)
      console.log(error)
    }

    setLoading(false)
  }

  return (
    <main>
      <section>
        <div>
          <div>
            <form className="mt-8 space-y-6" onSubmit={handleFormSubmit}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    readOnly
                    value={email}
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={confirmPassword}
                    placeholder="Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <button type="submit" disabled={loading}>
                  Register
                </button>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link
                    to="/login"
                    className="text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Already have an account? Login
                  </Link>
                </div>
              </div>
            </form>

            <p>
              Already have an account? <Link to="/sign-in">Sign in</Link>
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ChangePassword
