import { PanelResizeHandle } from 'react-resizable-panels'
import { BsArrowsExpand } from 'react-icons/bs'

import { TfiArrowsVertical } from 'react-icons/tfi'
// import styles from './Learn.module.css'
import styles from './resizable-panels.module.css'

export default function ResizeHandle({ className = '', id, direction }) {
  return (
    <PanelResizeHandle
      className={[styles.ResizeHandleOuter, className].join(' ')}
      id={id}
    >
      <div className={styles.ResizeHandleInner}>
        <TfiArrowsVertical
          className={`${styles.Icon} ${
            direction === 'horizontal' ? styles.resize_handle_horizontal : ''
          }`}
        />
      </div>
    </PanelResizeHandle>
  )
}
