import { useRef, useState } from 'react'

const usePanelSettings = () => {
  const [showSettingsOverlay, setShowSettingsOverlay] = useState(false)

  const handleOpenSettingsOverlay = () => {
    setShowSettingsOverlay(true)
  }

  const handleCloseSettingsOverlay = () => {
    setShowSettingsOverlay(false)
  }
  return {
    handleOpenSettingsOverlay,
    handleCloseSettingsOverlay,
    showSettingsOverlay,
  }
}

export default usePanelSettings
