import { useRef, useState } from 'react'
import KeywordForm from './KeywordForm'
import AnkiForm from './AnkiForm'
import { Panel, PanelGroup } from 'react-resizable-panels'

import ResizeHandle from '../ResizeHandle.jsx'
import styles from '../resizable-panels.module.css'
import QuickLinks from './QuickLinks'
import { useEffect } from 'react'
import { useKeyword } from '/src/contexts/KeywordContext.jsx'

const Middle = ({ showChat, czShort, setCzShort }) => {
  // const { keyword } = useKeyword()
  const [definition, setDefinition] = useState(
    'showing the ability to make good judgments'
  )
  const [example, setExample] = useState(
    `<span>You made a <span style="background-color: rgb(241, 196, 15);">wise</span> choice.</span>`
    // `You made a wise choice.`
  )
  const [mnemonic, setMnemonic] = useState(
    // ''
    `<span style="background-color: rgb(194, 224, 244);">MOUDR&Yacute;</span> Dalajl&aacute;ma si děl&aacute; <span style="background-color: rgb(241, 196, 15);">VAJ</span>ečinu <span style="background-color: rgb(241, 196, 15);">S</span> mozky</span>`
  )

  const ankiFormRowImageRef = useRef()
  // const ankiFormRowEnRef = useRef()

  const [clearAnkiInputs, setClearAnkiInputs] = useState(false)

  // useEffect(() => {
  //   console.log('definition ', definition)
  // }, [definition])

  const resetAnkiForm = () => {
    setExample('')
    setDefinition('')
    setMnemonic('')
    setCzShort('')
    ankiFormRowImageRef.current.editor.setContent('')
    // ankiFormRowEnRef.current.editor.setContent('')
    // setClearAnkiInputs(true)
    setClearAnkiInputs(!clearAnkiInputs)
  }

  // useEffect(() => {
  //   setAnkiFormEnInputCleared(false)
  // }, [keyword])

  const ankiFormRef = useRef()

  return (
    <Panel
      className={styles.Panel}
      collapsible={false}
      order={2}
      defaultSize={32}
      id={'middle'}
    >
      <KeywordForm
        showChat={showChat}
        ankiFormRef={ankiFormRef}
        resetAnkiForm={resetAnkiForm}
      />

      <PanelGroup direction="vertical" autoSaveId="middleGroup">
        <Panel order={1} minSize={45} id={'ankiForm'}>
          <div className={styles.PanelContent_ankiForm}>
            <AnkiForm
              definition={definition}
              setDefinition={setDefinition}
              example={example}
              setExample={setExample}
              mnemonic={mnemonic}
              setMnemonic={setMnemonic}
              czShort={czShort}
              setCzShort={setCzShort}
              ankiFormRef={ankiFormRef}
              resetAnkiForm={resetAnkiForm}
              ankiFormRowImageRef={ankiFormRowImageRef}
              clearAnkiInputs={clearAnkiInputs}
              setClearAnkiInputs={setClearAnkiInputs}

              // ankiFormRowEnRef={ankiFormRowEnRef}
            />
          </div>
        </Panel>
        <ResizeHandle />
        <Panel order={2} collapsible={true} defaultSize={20} id={'quickLinks'}>
          <div className={styles.PanelContent}>
            <QuickLinks keyword={'key'} />
          </div>
        </Panel>
      </PanelGroup>
    </Panel>
  )
}

export default Middle
