import React, { useEffect } from 'react'
import { useKeyword } from '../../../contexts/KeywordContext.jsx'
import PanelSettingsButtonOpeningModal from '/src/components/PanelSettingsButtonOpeningModal.jsx'
import { useModal } from '/src/contexts/ModalContext'
import Modal from '/src/components/10_utils/Modal'
import QuickLinkSettingsModal from './QuickLinksSettingsModal.jsx'
import { useUser } from '/src/contexts/UserContext'

const QuickLinks = () => {
  const { user, updateUser } = useUser()

  const { showModal, setShowModal, modalContent, setModalContent } = useModal()

  // console.log('tady user ', user)
  const { keyword } = useKeyword()
  // Replace spaces with different characters for different search formats
  const slovoSearchFormat = keyword.replace(/\s/, '+')
  const slovoSearchFormatCambridge = keyword.replace(/\s/, '-')
  const slovoSearchFormatDeepl = keyword.replace(/\s/, '%20')

  useEffect(() => {
    // console.log('Updated modalContent:', modalContent)
  }, [modalContent])

  return (
    <>
      <div className="middle__quick-links">
        <h4>Rychlé odkazy</h4>
        <div className="middle__quick-links__container">
          <a
            href={`https://www.google.com/search?tbm=isch&q=${user?.settings?.app?.quickLinks?.googleImages.replace(
              '{{hledany_vyraz}}',
              slovoSearchFormat
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Obrázky
          </a>

          <a
            href={`https://www.google.com/search?q=${user?.settings?.app?.quickLinks?.google.replace(
              '{{hledany_vyraz}}',
              keyword
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google
          </a>

          <a
            title="Cambridge Dictionary"
            href={`https://dictionary.cambridge.org/dictionary/english/${slovoSearchFormatCambridge}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cambridge
          </a>

          <a
            title="bab.la slovník"
            href={`https://cs.bab.la/slovnik/anglicky-cesky/${slovoSearchFormatCambridge}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Bab.la
          </a>

          <a
            href={`https://www.deepl.com/translator#en/cs/${slovoSearchFormatDeepl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Deepl
          </a>

          <a
            href={`https://dictionary.reverso.net/english-definition/${slovoSearchFormat}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Reverso
          </a>
        </div>
        <PanelSettingsButtonOpeningModal
          setModalContent={setModalContent}
          setShowModal={setShowModal}
          modalContent={modalContent}
        />
      </div>

      {/* {showModal && (
        <Modal>
          <QuickLinkSettings user={user} />
        </Modal>
      )} */}

      {modalContent === 'quickLinks' && (
        <Modal>
          <QuickLinkSettingsModal user={user} updateUser={updateUser} />
        </Modal>
      )}
    </>
  )
}

export default QuickLinks
