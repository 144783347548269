import React, { useState, useRef, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { IoMdSend } from 'react-icons/io'

const InputUsingEditor = ({
  id,
  label,
  value,
  //   handleInputChange,
  onSubmit,
  setCustomMnemonic,
}) => {
  const [isEditorFocused, setEditorFocused] = useState(false)

  const handleEditorInit = (event, editor) => {
    editorRef.current = editor
  }

  const editorRef = useRef(null)
  //   useEffect(() => {
  //     if (editorRef.current) {
  //       editorRef.current.focus()
  //     }
  //   }, [])

  return (
    <div
      className="middle__anki-form__row"
      style={{
        margin: '0 15px',
      }}
    >
      <label
        htmlFor={id}
        className={`${
          isEditorFocused ? 'focused' : ''
        } middle__anki-form__row__floating-label`}
      >
        <span>{label}</span>
      </label>

      <div className="middle__anki-form__row__input" id={id}>
        <Editor
          key="withoutPlaceholder99"
          ref={editorRef}
          //   initialValue={value}
          //   onInit={handleEditorInit}
          value={value}
          init={{
            auto_focus: true,
            inline: true,
            menubar: false,
            // plugins: ['paste'],
            toolbar:
              'undo bold italic backcolor forecolor casechange aligncenter bullist numlist indent',
            // setup: (editor) => {
            //   editor.on('focus', handleFocus)
            // },
          }}
          onEditorChange={(newValue, editor) => setCustomMnemonic(newValue)}
          tinymceScriptSrc="/wysiwyg/js/tinymce/tinymce.min.js"
        />
      </div>

      <button
        type="button"
        className="middle__keyword-form__submit showMore"
        onClick={(e) => onSubmit(e)}
      >
        <IoMdSend />
      </button>
    </div>
  )
}

export default InputUsingEditor
