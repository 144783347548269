import { BsThreeDotsVertical } from 'react-icons/bs'

import { formatTimeFromMinutes } from '/src/utils/formatDate.js'
import ReviewFinished from './ReviewFinished'
import {
  getRidOfWrappingP,
  getUrlsArrayFromImgsString,
} from '/src/utils/formatString.js'
import AnkiFormForm from '/src/pages/Learn/Middle/AnkiFormForm'
import { useModal } from '/src/contexts/ModalContext'
import Modal from '/src/components/10_utils/Modal'
import { BsFlag } from 'react-icons/bs'
import { axiosBearer } from '../../services/axiosInstances'
import React, { useEffect, useState, useRef } from 'react'

import styled from 'styled-components'
import SelectAction from '../../components/SelectAction'
import Button from '/src/components/10_utils/Button'
import {
  useGetCardsToReview,
  useSubmitCardToReview,
  useDeleteXCards,
  useModifyOneCard,
  useModifyXCards,
  // useChangeDeck,
} from '/src/hooks/useVocabAPI'
import {
  useFormData,
  initialStateFormData,
} from '/src/pages/Learn/Middle/AnkiForm_helpers'
import Loading from '/src/components/Loading'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { MdOutlineStorage } from 'react-icons/md'
import {} from '/src/hooks/useVocabAPI'
import useKeyboardShortcut from '/src/hooks/useKeyboardShortcut'
import { toast } from 'react-toastify'

const ReviewMode = ({
  decks,
  deckSelectedId,
  setDeckSelectedId,
  userData,
  remainingLearnAheadCards,
  setRemainingLearnAheadCards,
  user,
}) => {
  const queryClient = useQueryClient()
  const { modalContent, setModalContent } = useModal()
  const [editedCardFieldsToUpdate, setEditedCardFieldsToUpdate] = useState([])
  const [formData, setFormData] = useFormData()
  const [ankiFormData, setAnkiFormData] = useState({})
  const [
    areOnServerProbablyMoreCardsToReview,
    setAreOnServerProbablyMoreCardsToReview,
  ] = useState(false)

  console.log(
    'user.settings.app.audioAutoplay',
    user.settings.app.audioAutoplay
  )
  // SRS
  const { data, isSuccess } = useGetCardsToReview(
    deckSelectedId,
    user.settings.SRS.preferences.learnAhead
  )
  const [cards, setCards] = useState([])
  const [pendingCards, setPendingCards] = useState([])
  const [currentCard, setCurrentCard] = useState(null)
  const [showNoCardsMessage, setShowNoCardsMessage] = useState(false)

  const handleReviewButtonClick = (ease) => {
    submitCardToReviewMutation.mutate([currentCard._id, ease])
  }

  // console.log('userData ', userData)
  const submitCardToReviewMutation = useSubmitCardToReview((data) => {
    const updatedCard = data.updatedCardWithReviewButtons

    const endOfToday = new Date().setHours(23, 59, 59, 999)

    let internalCards = JSON.parse(JSON.stringify(cards)) // v reviewMode se vždy zobrazí cards[0] (= currentCard)
    let internalPendingCards = JSON.parse(JSON.stringify(pendingCards)) // pokud se má zobrazit, musím přesunout do cards (+ bych měl odebrat z pendingCards)

    // check if card's due is today - yes -> update queue; no -> remove card from queue
    if (new Date(updatedCard.SRS.dueDate) <= endOfToday) {
      // kartu budu ještě dnes opakovat
      console.log('updated card budu reveiw ještě dnes')
      if (
        // je hned k opakování (bez learnAhead) -> dát do internalCards
        new Date(updatedCard.SRS.dueDate) <= new Date()
        // new Date(Date.now() + userData.settings.SRS.preferences.learnAhead)
      ) {
        console.log('dát updated do internal')
        internalCards.shift()
        internalCards.push(updatedCard)
      } else {
        // k opakování později -> dát do internalPendingCards
        console.log('dát updated do internalpending + smazat i internalcards')
        internalCards.shift()
        internalPendingCards.push(updatedCard)
      }
    } else {
      // karta již dnes nebude opakována - vyjmout z cards
      console.log('updated card už NEBUDU reveiw dnes - smazat i internal')
      internalCards.shift()
    }

    // determine nextCard to review
    /*
    priority: 
      1. due pendingCards (znamená, že třeba vypršela minuta) i bez learnAhead
      2. normal cards to review, co ještě dnes nebyly opakované a jsou due i bez learnAhead
      3. 1. a 2. co jsou due s learnAhead
    */

    //  1. due pendingCards (znamená, že třeba vypršela minuta) i bez learnAhead
    const { duePendingCards, notDuePendingCards } = getDuePendingCards(
      false, // without learnAhead
      internalPendingCards
    )
    console.log(
      'duePendingCards (nejbližší jde do internalcards):',
      duePendingCards
    )
    if (duePendingCards.length > 0) {
      sortCardsByEarliestDueDate(duePendingCards)
      internalCards.unshift(duePendingCards[0])
      console.log('updatované internalcards: ', internalCards)
      console.log('v ten samý momment internalpending: ', internalPendingCards) // musím asi smazat tu kartu, která jde do internalcards
    } else {
      // 2. normal cards to review, co ještě dnes nebyly opakované a jsou due i s learnAhead
      const { duePendingCards: duePendingCards_withLearnAhead } =
        getDuePendingCards(true, internalPendingCards)
      duePendingCards_withLearnAhead.forEach((card) => {
        internalCards.push(card)
        internalPendingCards.shift()
      })
      // s těmi, co nebyly opakované , nemusím nic dělat, měly by být už v internalcards

      sortCardsByEarliestDueDate(internalCards)

      moveDueNowCardsAtTheBeginning(internalCards)
      // else {
      //   // 3. : 1. a 2. co jsou due s learnAhead
      // }
    }

    // JEN KOPIE PŘEDEŠLÉHO
    // if (internalCards.length === 0) {
    //   console.log('internalCards.length === 0')
    //   // pokud je cards empty, zkusit v internalPendingCards najít kartu, která je k opakování hned
    //   console.log('internalPendingCards ', internalPendingCards)
    //   if (internalPendingCards.length > 0) {
    //     console.log('internalPendingCards.length > 0')
    //     const { duePendingCards, notDuePendingCards } = getDuePendingCards()
    //     console.log('duePendingCards ', duePendingCards)
    //     console.log('notDuePendingCards ', notDuePendingCards)
    //     if (duePendingCards.length > 0) {
    //       // move all duePendingCards to cards
    //       duePendingCards.forEach((card) => {
    //         internalCards.push(card)
    //       })
    //       internalPendingCards = notDuePendingCards
    //     }
    //   }
    // }

    // if (internalCards.length > 0) {
    //   // aspon 1 karta je k opakování hned
    //   // setCards(internalCards)
    // } else {
    //   // nic k opakování
    //   internalCards = []
    //   // handleGoToDecks()
    //   // toast.info('Všechny karty z balíčku jsou zopakovány')

    //   // if (internalPendingCards.length > 0) {
    //   //   setCards(internalPendingCards)
    //   //   setPendingCards([])

    //   //   // internalQueue = JSON.parse(JSON.stringify(internalPendingCards))
    //   //   // internalPendingCards = []
    //   //   // setQueue(internalPendingCards)
    //   //   // setPendingCards([])
    //   // } else {
    //   //   // internal pending cards & queue jsou empty
    //   //   // setQueue([])
    //   //   setDeckSelectedId(false)
    //   //   queryClient.invalidateQueries(['getDecks', true])
    //   //   // return
    //   // }
    // }

    console.log('internal cards ', internalCards)
    console.log('internal pending cards ', internalPendingCards)

    // pokud dueDate nejbližší karty není <= teď + learnAheadLimit -> zavřít reviewMode
    // console.log('card dueDate', new Date(internalQueue[0].SRS.dueDate))
    // console.log('ted + learnahead', new Date(Date.now() + 5 * 60 * 1000))
    // console.log(
    //   'vyjet z decku ',
    //   !(
    //     new Date(internalQueue[0].SRS.dueDate) <=
    //     new Date(Date.now() + 5 * 60 * 1000)
    //   ) && internalQueue[0].SRS.type !== 0
    // )

    // // beru [0] kartu - je to ta nejbližší, takže nemusím checkovat další
    // if (
    //   !(
    //     new Date(internalQueue[0].SRS.dueDate) <=
    //     new Date(Date.now() + 5 * 60 * 1000)
    //   ) &&
    //   internalQueue[0].SRS.type !== 0 // don't apply to new cards (mají due 2099)
    // ) {
    //   setDeckSelectedId(false)
    //   queryClient.invalidateQueries(['getDecks', true])
    // }

    // if (internalCards.length > 0) sortCardsByEarliestDueDate(internalCards)
    // if (internalPendingCards.length > 0)
    //   sortCardsByEarliestDueDate(internalPendingCards)

    setCards(internalCards)
    setPendingCards(internalPendingCards)

    // console.log('queue ', queue)
    // console.log('pending cards ', pendingCards)

    // console.log('těsně před internalque setting ', internalQueue)
    // setQueue(internalQueue)

    // let cards_ = [...queue]

    // const newDuePlusLearnAheadLimit = new Date(
    //   Date.now() + userData.settings.collection.learnAhead * 60 * 1000
    // )

    // console.log('learnAhead ', userData.settings.collection.learnAhead)
    // console.log('newDuePlusLearnAheadLimit ', newDuePlusLearnAheadLimit)
    // console.log('data.updatedCardSRS.dueDate ', data.updatedCardSRS.dueDate)
    // console.log(
    //   new Date(data.updatedCardSRS.dueDate) <= newDuePlusLearnAheadLimit
    // )

    // if (new Date(data.updatedCardSRS.dueDate) <= newDuePlusLearnAheadLimit) {
    //   cards_.push(card)
    // }

    // cards_.shift()
    // if (cards_.length === 0) {
    //   setQueue([])
    //   setDeckSelectedId(false)
    //   queryClient.invalidateQueries(['getDecks', true])
    //   return
    // }
  })

  function sortCardsByEarliestDueDate(cards) {
    return cards.sort((a, b) => {
      return new Date(a.SRS.dueDate) - new Date(b.SRS.dueDate)
    })
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  function moveDueNowCardsAtTheBeginning(cards) {
    let dueCardsWithoutLearnAhead = []
    let dueCardsWithLearnAhead = []
    let notDueCards = []
    cards.forEach((card) => {
      const now = new Date()
      const learnAheadTime = new Date(
        now.getTime() + userData.settings.SRS.preferences.learnAhead * 60 * 1000
      )
      const cardDueDate = new Date(card.SRS.dueDate)

      if (cardDueDate <= now) {
        dueCardsWithoutLearnAhead.push(card)
      } else if (cardDueDate <= learnAheadTime) {
        dueCardsWithLearnAhead.push(card)
      } else {
        notDueCards.push(card)
      }
    })

    let dueCardsWithoutLearnAhead_shuffled = []
    if (dueCardsWithoutLearnAhead.length > 0) {
      dueCardsWithoutLearnAhead_shuffled = shuffleArray([
        ...dueCardsWithoutLearnAhead,
      ])
      cards = [
        ...dueCardsWithoutLearnAhead_shuffled,
        ...dueCardsWithLearnAhead,
        ...notDueCards,
      ]
    }
  }

  const handleGoToDecks = () => {
    setDeckSelectedId(false)
    // setCards(null)
  }

  useEffect(() => {
    console.log('currentCard: ', currentCard)
  }, [currentCard])

  useEffect(() => {
    console.log(
      'areonserverprobablymorecardstoreview: ',
      areOnServerProbablyMoreCardsToReview
    )
  }, [areOnServerProbablyMoreCardsToReview])

  // Update cards state when data is successfully fetched
  useEffect(() => {
    if (isSuccess) {
      // dát prioritu kartám s dueDate <= now (bez learnAheadLimitu) a pak ty due s learnAheadLimitem
      moveDueNowCardsAtTheBeginning(data)
      setCards(data)
      if (data.length === 30) {
        // to je maximum, takže je šance, že na serveru jich je víc ještě
        console.log(
          'dostal jsem 5 karet - areon = ',
          areOnServerProbablyMoreCardsToReview
        )
        setAreOnServerProbablyMoreCardsToReview(true)
      } else {
        if (areOnServerProbablyMoreCardsToReview)
          setAreOnServerProbablyMoreCardsToReview(false)
      }
      setPendingCards([]) // když ze serveru dostanu aude karty, není třeba už mít pending, všechny jsou v cards + dělá jinak toto:
      // "dám kartu za 3 min. je v pending. objeví se za 3 minuty. dám za další 3 a zase se dá do pending
    }
  }, [isSuccess, data])

  // Update the current card when cards state changes
  useEffect(() => {
    if (cards && cards.length > 0) {
      setCurrentCard(cards[0])
    }
    console.log('cards (a tedy currentCard([0])) se změnily, cards: ', cards)
  }, [cards])

  useEffect(() => {
    console.log('pendingCards se změnily: ', pendingCards)
  }, [pendingCards])

  // react to deckSelectedId change (když vyjedu z reviewMode)
  useEffect(() => {
    // Return a cleanup function
    return () => {
      // This cleanup function will run right before the component unmounts
      // if (!deckSelectedId) {
      queryClient.invalidateQueries(['getDecks', true])
      setCards([])
      setPendingCards([])
      setCurrentCard(null)
      // }
    }
  }, [deckSelectedId, queryClient]) // Include dependencies here

  function getDuePendingCards(countLearnAhead, internalPendingCards) {
    let duePendingCards = []
    let notDuePendingCards = []

    internalPendingCards.forEach((card) => {
      const card_dueDate = new Date(card.SRS.dueDate)
      let now_withOrWithout_learnAhead = new Date() // pro false (without learnAhead)
      if (countLearnAhead) {
        // pro true (with learnAhead)
        now_withOrWithout_learnAhead = new Date(
          Date.now() + userData.settings.SRS.preferences.learnAhead * 60 * 1000
        )
      }
      console.log(
        `card_dueDate <= now_withOrWithout_learnAhead; ${card_dueDate} <= ${now_withOrWithout_learnAhead} = ${
          card_dueDate <= now_withOrWithout_learnAhead
        }`
      )
      if (card_dueDate <= now_withOrWithout_learnAhead) {
        duePendingCards.push(card)
        console.log('do due pending dávám kartu')
      } else {
        notDuePendingCards.push(card)
      }
    })
    return { duePendingCards, notDuePendingCards }
  }
  useEffect(() => {
    const areThereDuePendingCards = pendingCards.some((card) => {
      return (
        new Date(card.SRS.dueDate) <=
        new Date(
          Date.now() + userData.settings.SRS.preferences.learnAhead * 60 * 1000
        )
      )
    })
    if (data && cards.length === 0 && !areThereDuePendingCards) {
      if (areOnServerProbablyMoreCardsToReview) {
        console.log('znovu spustit getcardstoreveiw')
        // queryClient.invalidateQueries(['getCardsToReview', setDeckSelectedId])
        queryClient.invalidateQueries({
          queryKey: ['getCardsToReview', deckSelectedId],
        })
      } else setShowNoCardsMessage(true)
    } else {
      setShowNoCardsMessage(false)
    }
  }, [
    // data, // zakomentované, jinak tato useEffect vždy běžela dvakrát - když jsem dostal data (asi) a pak když jsem je dal do cards
    cards,
    pendingCards,
    userData.settings.SRS.preferences.learnAhead,
    // areOnServerProbablyMoreCardsToReview,
    // queryClient,
    // deckSelectedId,
  ])

  const formRef = useRef(null)

  useKeyboardShortcut([
    // review buttons 1-4
    {
      keys: ['q'],
      enabled: !modalContent,
      action: () => handleReviewButtonClick(1),
    },
    {
      keys: [' '],
      enabled: !modalContent,
      action: () => handleReviewButtonClick(3),
    },
    {
      keys: ['w'],
      enabled: !modalContent,
      action: () => handleReviewButtonClick(2),
    },
    {
      keys: ['e'],
      enabled: !modalContent,
      action: () => handleReviewButtonClick(3),
    },
    {
      keys: ['r'],
      enabled: !modalContent,
      action: () => handleReviewButtonClick(4),
    },
    // selectActions//
    //  - edit ("adjust")
    {
      keys: ['a'],
      enabled: modalContent !== 'reviewMode_edit',
      action: () => handleActionChange('modify', null),
    },
    //  - delete
    {
      keys: ['d'],
      enabled: modalContent !== 'reviewMode_edit',
      action: () => handleActionChange('delete', null),
    },
    //  - suspend
    {
      keys: ['s'],
      enabled: modalContent !== 'reviewMode_edit',
      action: () => handleActionChange('suspend', null),
    },
    //  - forget
    {
      keys: ['f'],
      enabled: modalContent !== 'reviewMode_edit',
      action: () => handleActionChange('forget', null),
    },
    // submit edited card modal
    {
      keys: ['Ctrl', 'Shift', 'l'],
      enabled: modalContent === 'reviewMode_edit',
      action: () => formRef.current && formRef.current.submit(),
    },
    // close edited card modal
    {
      keys: ['Escape'],
      enabled: modalContent === 'reviewMode_edit',
      action: () => {
        setModalContent(null)
        setAnkiFormData({})
      },
    },
    // close reviewMode
    {
      keys: ['Escape'],
      enabled: modalContent !== 'reviewMode_edit',
      action: handleGoToDecks,
    },
  ])

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [])

  const handleEditCardSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    const ankiFormData_formatted = {}

    for (const field in ankiFormData) {
      if (field === 'image') {
        ankiFormData_formatted[field] = getUrlsArrayFromImgsString(
          ankiFormData[field]
        )
      } else if (
        field === 'cz' ||
        field === 'en' ||
        field === 'mnemonic' ||
        field === 'definition' ||
        field === 'example' ||
        field === 'extra'
      ) {
        ankiFormData_formatted[field] = getRidOfWrappingP(ankiFormData[field])
      } else if (
        field === 'deckName' ||
        field === 'deckId' ||
        field === 'noteType'
      ) {
        ankiFormData_formatted[field] = ankiFormData[field]
      }
    }

    // ankiFormData.values.map((item) => {
    //   if (
    //     item === 'cz' ||
    //     item === 'en' ||
    //     item === 'mnemonic' ||
    //     item === 'definition' ||
    //     item === 'example' ||
    //     item === 'extra'
    //   ) {
    //     return { [item]: getRidOfWrappingP(ankiFormData[item]) }
    //   } else if (item === 'image') {
    //     return { [item]: getUrlsArrayFromImgsString(ankiFormData[item]) }
    //   }
    // })

    console.log('formatted ', ankiFormData_formatted)

    // formData_ = {
    //   // ...formData,
    //   cz: getRidOfWrappingP(ankiFormData.cz),
    //   en: getRidOfWrappingP(ankiFormData.en),
    //   definition: getRidOfWrappingP(ankiFormData.definition),
    //   example: getRidOfWrappingP(ankiFormData.example),
    //   mnemonic: getRidOfWrappingP(ankiFormData.mnemonic),
    //   extra: getRidOfWrappingP(ankiFormData.extra),
    //   // image: getRidOfWrappingP(ankiFormData.image),
    //   image: getUrlsArrayFromImgsString(ankiFormData.image),
    //   noteType: ankiFormData.noteType,
    //   deckName: ankiFormData.deckName,
    //   deckId: ankiFormData.deckId,
    //   // keyword,
    //   // isKeywordEnglish: originalCzInput ? false : true,
    // }

    modifyOneCardMutation.mutate([currentCard._id, ankiFormData_formatted])
    // change card
    // setCard({ ...card, ...ankiFormData })
  }

  // useEffect(() => {
  //   console.log('card ', card)
  // }, [card])

  const onSuccesfulMutation = () => {
    if (modalContent === 'reviewMode_edit') {
      setModalContent(null)
    }
  }

  const deleteXCardsMutation = useDeleteXCards(() => {
    onSuccesfulMutation()
  })

  const modifyOneCardMutation = useModifyOneCard(() => {
    // jen pro edit card (modal)
    // console.log('modifyOneCardMutation succesful')
    onSuccesfulMutation()
    setAnkiFormData({})
  }, [{ queryKey: ['getCardsToReview', deckSelectedId] }])

  const modifyXCardsMutation = useModifyXCards(() => {
    // for suspend and changedeck action
    onSuccesfulMutation()
  })
  // useEffect(() => {
  //   console.log('dckselectedid changed, now is ', deckSelectedId)
  //   if (!deckSelectedId) {
  //     setQueue([])
  //     setPendingCards([])
  //     setCard(null)
  //     queryClient.invalidateQueries(['getDecks', true])
  //   }
  // }, [deckSelectedId, queryClient])

  // Handle null or empty states
  if (!currentCard) {
    return <Loading />
  }

  // console.log('cards ', queue)
  // console.log('card ', card)
  // console.log('--------------')
  // fetch cards to review

  // scroll down on page load

  let front = []
  let back = []
  if (
    currentCard.noteType === 'englishToCzech'
    // || (!card.hasTwoSides && card.card === 1)
  ) {
    front = generateCardSideContent('englishToCzech', 'front')
    back = generateCardSideContent('englishToCzech', 'back')
  }
  // toto bude později vycházet z usersettings
  // front = [card.en, card.pronunciation]
  // userData.settings.noteTypes.englishToCzech.front.forEach((field) => {
  //   let value
  //   if (field === 'image') {
  //     value = (
  //       <div>
  //         {card.image.map((image, i) => (
  //           <img key={image.imageUrl} src={image.imageUrl} />
  //         ))}
  //       </div>
  //     )
  //   } else value = card[field]

  //   front.push({ field: field, value: value })
  // })

  // userData.settings.noteTypes.englishToCzech.back.forEach((field) => {
  //   // console.log('field ', field)
  //   // console.log('card.field ', card[field])

  //   let value
  //   if (field === 'image') {
  //     value = (
  //       <div
  //         key={'image'}
  //         style={{
  //           display: 'flex',
  //           flexWrap: 'wrap',
  //         }}
  //       >
  //         {card.image.map((image, i) => (
  //           <ImageContainer key={image.imageUrl}>
  //             <Img src={image.imageUrl} />
  //             {image.attribution && (
  //               <ImageCaption
  //                 dangerouslySetInnerHTML={{ __html: image.attribution }}
  //               />
  //             )}
  //           </ImageContainer>
  //         ))}
  //       </div>
  //     )
  //   } else value = card[field]

  //   back.push({ field: field, value: value })
  // })
  // console.log('front ', front)
  else if (currentCard.noteType === 'czechToEnglish') {
    front = generateCardSideContent('czechToEnglish', 'front')
    back = generateCardSideContent('czechToEnglish', 'back')
  }

  function generateCardSideContent(noteType, side) {
    let content = []
    userData.settings.noteTypes[noteType][side].forEach((field) => {
      let value
      if (field === 'image') {
        value = (
          <div
            key={'image'}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            {currentCard.image.map((image) => (
              <ImageContainer key={image.imageUrl}>
                <Img src={image.imageUrl} />
                {image.attribution && (
                  <ImageCaption
                    dangerouslySetInnerHTML={{ __html: image.attribution }}
                  />
                )}
              </ImageContainer>
            ))}
          </div>
        )
      } else {
        // console.log('card.field', card[field])
        // console.log('field', field)
        if (currentCard[field]) value = currentCard[field]
      }

      content.push({ field: field, value: value })
    })

    return content
  }

  // console.log('back ', back)
  const buttonsData = [
    {
      text: 'Znovu',
      backgroundColor: '#c61d15',
      time: '1d',
      ease: 1,
    },
    {
      text: 'Těžké',
      backgroundColor: '#494949',
      time: '1d',
      ease: 2,
    },
    {
      text: 'Dobré',
      backgroundColor: '#069416',
      time: '1d',
      ease: 3,
    },
    {
      text: 'Snadné',
      backgroundColor: '#035196',
      time: '1d',
      ease: 4,
    },
  ]

  const handleActionChange = (action, id) => {
    // console.log('AreAllDataCheckboxesChecked ', areAllDataCheckboxesChecked)
    // console.log('id ', id)
    console.log('action', action)
    // let action = e.target.value
    let targetDeck, targetDeckId

    // Check if the value contains a colon, which would mean it's a changeDeck action
    if (action.includes(':')) {
      // eslint-disable-next-line no-extra-semi
      ;[targetDeck, targetDeckId] = action.split(':')
      action = 'changeDeck'
    }

    // console.log('action ', action)
    // setSelectedAction(action)
    if (action === 'delete') {
      console.log("action was 'delete'")
      deleteXCardsMutation.mutate([[currentCard._id], false])
      // }
    } else if (action === 'changeDeck') {
      console.log("action was 'changeDeck'")
      console.log('move to deckkk', targetDeck, targetDeckId)

      modifyXCardsMutation.mutate([
        [currentCard._id],
        false,
        action,
        { targetDeck, targetDeckId },
      ])
    } else if (action === 'suspend') {
      console.log("action was 'suspend'")
      modifyXCardsMutation.mutate([[currentCard._id], false, action])
    } else if (action === 'forget') {
      console.log("action was 'forget'")
      modifyXCardsMutation.mutate([[currentCard._id], false, action])
    }

    // else if (action === 'unsuspend') {
    //   console.log("action was 'unsuspend'")
    //   modifyXCardsMutation.mutate([
    //     [...selectedCards],
    //     areAllDataCheckboxesChecked,
    //     action,
    //   ])
    // }
    // if "modify" - open modal where will be AnkiForm component.user can modify fields values there. on submit, mutation will be called
    else if (action === 'modify') {
      // setShowModal(true)
      // console.log('modifyig mode')
      setModalContent('reviewMode_edit')
      setFormData(initialStateFormData)
      // const cardsFromServer = data.cards.filter(
      //   (card) => card._id === [...selectedCards][0]
      // )[0]
      // setEditedCardId(cardsFromServer._id)
      // const cardData = {
      //   ...cardsFromServer,
      //   cz: cardsFromServer.cz,
      //   en: cardsFromServer.en,
      //   mnemonic: cardsFromServer.mnemonic,
      // }
      // // setSelectedAction('')
      let images = ''
      if (currentCard.image[0].imageUrl) {
        currentCard.image.forEach((item) => {
          images += `<img src="${item.imageUrl}" />`
        })
      }
      setFormData({
        cz: currentCard.cz,
        en: currentCard.en,
        mnemonic: currentCard.mnemonic,
        definition: currentCard.definition,
        example: currentCard.example,
        // image: card.image,
        image: images,
        noteType: currentCard.noteType,
        deckName: currentCard.deckName,
        deckId: currentCard.deckId,
      })
    }
  }

  const handleInputChange = (value, field) => {
    console.log('jsem v handleinput change')
    if (field === 'deckName') {
      console.log('handleInputChange - COLON')
      console.log('field', field, 'value', value)
      // eslint-disable-next-line no-extra-semi
      const [deckName, deckId] = value.split(':deckid')
      console.log('deckName', deckName, 'deckId', deckId)
      setAnkiFormData((prevState) => ({ ...prevState, deckName, deckId }))
      setFormData((prevState) => ({ ...prevState, deckName, deckId }))
    } else {
      console.log('field ', field, ' value ', value)
      // console.log('anki form Data ', ankiFormData)
      // setAnkiFormData((prevState) => ({ ...prevState, [field]: value }))
      // setFormData((prevState) => ({ ...prevState, [field]: value }))
      setAnkiFormData((prevState) => ({ ...prevState, [field]: value }))
    }
  }

  const handleCloseEditedCardModal = () => {
    setAnkiFormData({})
  }

  return (
    <>
      {showNoCardsMessage ? (
        <ReviewFinished
          handleGoToDecks={handleGoToDecks}
          pendingCards={pendingCards}
          decks={decks}
          deckSelectedId={deckSelectedId}
          setDeckSelectedId={setDeckSelectedId}
        />
      ) : (
        <>
          <Container>
            <TopBar>
              <span style={{ paddingInline: '0.5rem' }}>
                {currentCard.deckName}
              </span>

              {/* <Button text={<BsFlag />} padding="var(--padding-button-smaller)"
        onClick={handleCardFlagClick}
        /> */}

              <Button
                onClick={handleGoToDecks}
                backgroundColor="inherit"
                padding="0 0.5rem"
                inlineStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '1.25rem',
                }}
              >
                {/* <BsThreeDotsVertical /> */}
                <MdOutlineStorage />
              </Button>

              <SelectAction
                onChange={handleActionChange}
                // onChange={() => {
                //   console.log('jsem v action change')
                // }}
                decks={decks}
                // placeholderLabel={'⚡ akce:'} z nějakého důvodu nefunguje, když chci jako default value v option passed prop
                onlyForOneCard={true}
                containerStyling={{
                  display: 'block',
                  maxWidth: '130px',
                  width: '50%',
                  // justifyContent: 'space-around',
                  margin: 0,
                }}
                placeholderOption={{ value: '', content: '⚡ akce:' }}
                // tohle možná není třeba?
                selectedCards={[currentCard._id]}
                onlySuspendedCardsAreSelected={false}
              />
            </TopBar>

            <Card>
              <Front>
                <ul>
                  {front.map((item, index) => {
                    if (item.field === 'image') {
                      // Render value directly as it is a React component
                      return <li key={index}>{item.value}</li>
                    } else if (item.field === 'pronunciation') {
                      return (
                        <audio
                          key={index}
                          src="https://pub-e8ca240a2a38422c83c460d329d0f573.r2.dev/pronunciation_example.mp3"
                          controls
                          autoPlay={user.settings.app.audioAutoplay}
                        >
                          Váš prohlížeč nepodporuje audio element.
                        </audio>
                      )
                    } else {
                      // For other fields, use dangerouslySetInnerHTML for raw HTML
                      return (
                        <li key={item.field}>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.value }}
                          ></span>
                        </li>
                      )
                    }
                  })}
                </ul>
              </Front>

              <Hr />

              <Back>
                <ul>
                  {back.map((item, index) => {
                    if (item.field === 'image') {
                      // Render value directly as it is a React component
                      return (
                        <li style={{ listStyleType: 'none' }} key={index}>
                          {item.value}
                        </li>
                      )
                    } else if (item.field === 'pronunciation') {
                      return (
                        <audio
                          key={index}
                          src="https://pub-e8ca240a2a38422c83c460d329d0f573.r2.dev/pronunciation_example.mp3"
                          controls
                          autoPlay={user.settings.app.audioAutoplay}
                        >
                          Váš prohlížeč nepodporuje audio element.
                        </audio>
                      )
                    } else {
                      // For other fields, use dangerouslySetInnerHTML for raw HTML
                      return (
                        <li
                          style={{
                            listStyleType:
                              item.field === 'pronunciation' ||
                              !currentCard[item.field].trim()
                                ? 'none'
                                : 'disc',
                          }}
                          key={item.field}
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: item.value }}
                          ></span>
                        </li>
                      )
                    }
                  })}
                </ul>
                {/* {front.includes(card.en) ? (
              <li dangerouslySetInnerHTML={{ __html: card.cz }}></li>
            ) : (
              <li dangerouslySetInnerHTML={{ __html: card.en }}></li>
            )} */}
                {/* {['example', 'mnemonic', 'image', 'definition'].map((field) =>
              card[field] && field !== 'image' ? (
                <li
                  key={field}
                  dangerouslySetInnerHTML={{ __html: card[field] }}
                ></li>
              ) : null
            )} */}
              </Back>
            </Card>

            <ReviewButtons>
              {buttonsData.map((button, index) => (
                <Button
                  key={button.text}
                  backgroundColor={button.backgroundColor}
                  // padding="var(--padding-button-smaller)"
                  inlineStyle={{ padding: '0.6rem 0 0.6rem 0', gap: '0.23rem' }}
                  onClick={() => {
                    handleReviewButtonClick(
                      button.ease
                      // card.buttonsFutureInterval.date[index + 1]
                    )
                  }}
                >
                  <time>
                    {currentCard.answerButtons[index].dueLabel}
                    {/* {card.buttonsFutureInterval.readable[index + 1]} */}
                  </time>
                  <span>
                    {' '}
                    <strong>{button.text}</strong>
                  </span>
                </Button>
              ))}
            </ReviewButtons>
          </Container>

          {modalContent === 'reviewMode_edit' && (
            <Modal padding="5%" onClose={handleCloseEditedCardModal}>
              <AnkiFormForm
                formData={formData}
                handleInputChange={handleInputChange} // v objectu budou jen změněná pole
                handleSubmit={handleEditCardSubmit}
                deckNames={decks}
                submitFormButton={formRef}
              />
            </Modal>
          )}
        </>
      )}
    </>
  )
}

export default ReviewMode

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  width: 100%;
  border-top: 1px solid black;
  border-radius: var(--border-radius);
  margin-top: 1rem;
  margin: 0 auto;
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
  align-items: center;
  background-color: var(--color-black);
  color: var(--color-white);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border: 1px solid black;
`

const Card = styled.div`
  flex-grow: 1;
  padding: 0.7rem 1rem;
  overflow-y: auto;
  width: 100%;
  border-left: 1px solid black;
  border-right: 1px solid black;
`

const Front = styled.div`
  text-align: center;
`

const Hr = styled.hr`
  margin: 0.7rem auto;
  width: 75%;
  color: var(--color-black);
`

const Back = styled.div`
  // Your styles
  padding: 0.5rem 1rem;

  & li {
    list-style-type: disc;
  }
`

const ImageContainer = styled.div`
  position: relative;
  max-height: 20vh;
  /* max-width: 470px; */

  /* display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; */
`

const ImageCaption = styled.div`
  position: absolute;
  bottom: 0;
  /* width: 100%; */
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--color-white);
  /* padding: 0.5rem; */
  font-size: var(--font-size-super-small-2);
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  text-align: left;
  border-radius: var(--border-radius);
  max-width: 100%;

  /* & a {
    font-size: var(--font-size-super-small);
  } */
`

const Img = styled.img`
  /* max-width: 100%; */
  width: auto;
  height: 100%;
`

const ReviewButtons = styled.div`
  display: flex;
  width: 100%;

  & > button {
    flex-grow: 1;
    flex-basis: 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & time {
    font-size: var(--font-size-small);
  }
  /* position: fixed;
  bottom: 0; */
`
