export function getRidOfWrappingP(value) {
  // tinymce adds na začátek
  if (!value) return value
  const trimmedValue = value.trim()
  const pTagRegex = /^<p>(.*)<\/p>$/

  if (pTagRegex.test(trimmedValue)) {
    return trimmedValue.replace(pTagRegex, '$1')
  }

  return trimmedValue
}

export function getUrlsArrayFromImgsString(value) {
  const regex = /<img.*?src=["'](.*?)["']/g
  const matches = []
  let match

  while ((match = regex.exec(value)) !== null) {
    matches.push(match[1]) // Push the URL part of the match to the array
  }

  return matches
}

export function transliterate(word) {
  const letters = {
    š: 's',
    č: 'c',
    ť: 't',
    ž: 'z',
    ý: 'y',
    á: 'a',
    í: 'i',
    é: 'e',
    ě: 'e',
    ú: 'u',
    ů: 'u',
    ó: 'o',
    ň: 'n',
  }
  return word
    .split('')
    .map((letter) => letters[letter] || letter)
    .join('')
}

export function sanitizeFilename(keyword) {
  const alphanumeric = keyword.replace(/[^a-zA-Z0-9]/g, '')
  return transliterate(alphanumeric)
}

// Usage
const keyword = 'šomekëyword'
const filename = `${sanitizeFilename(transliterate(keyword))}.jpg`
