import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useModal } from '../../contexts/ModalContext'
import './modal.css'
import { RiCloseLine } from 'react-icons/ri'
import styled from 'styled-components'

const Modal = ({
  children,
  padding,
  onlyCloseButtonClosesIt = false,
  nested = false,
  onClose = () => {},
}) => {
  const { showModal, setShowModal, modalContent, setModalContent } = useModal()

  const modalRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('Clicked element: ', event.target)
      if (!nested) {
        const portal = document.getElementById('portal')

        // if (tinymceEditor && tinymceEditor.contains(event.target)) {
        //   console.log('Click inside TinyMCE editor')
        //   return // Do nothing if the click is inside the TinyMCE editor
        // }

        if (!onlyCloseButtonClosesIt) {
          let condition =
            modalRef.current &&
            !modalRef.current.contains(event.target) &&
            portal.contains(event.target)

          if (condition) {
            console.log('Closing modal because clicked outside')
            // setShowModal(false)
            setModalContent(null)
            onClose()
          }
        }
      }

      // toto nefunguje! If you want to check if a click event happened outside a specific DOM element, you'll need a reference to that DOM element. This is what useRef and modalRef.current are for - children ne
      // if (!children.contains(event.target)) {
      //   console.log('Clicked outside modal')
      //   setShowModal(false)
      // }
    }

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setModalContent])

  if (!modalContent) return null
  return ReactDOM.createPortal(
    <PortalOverlay>
      <PortalModal ref={modalRef} $padding={padding}>
        <RiCloseLine
          style={{
            color: 'black',
            fontSize: '2em',
            cursor: 'pointer',
            position: 'absolute',
            top: '0.3rem',
            right: '0.3rem ',
          }}
          onClick={() => {
            setModalContent(null)
            onClose()
          }}
        />
        {children}
      </PortalModal>
    </PortalOverlay>,
    document.getElementById('portal')
  )
}

const PortalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1000;
`

const PortalModal = styled.div`
  position: fixed;
  top: 50%;

  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: ${(props) => (props.$padding ? `${props.$padding}` : '5%')};
  z-index: 1000;
  width: 700px;
  height: 800px;
  max-width: 85%;
  max-height: 95%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 10px;
  border-radius: 5px;
  color: var(--color-black);
  overflow-y: auto;
`

export default Modal
