import { axiosBearer } from './axiosInstances.js'

// get all decks with info about new / to review cards
// export async function getDecksWithStatusInfo() {
//   try {
//     const response = await axiosBearer.get(`/opakovani/decks-status-info`)
//     return response.data
//   } catch (error) {
//     throw new Error(`Error fetching deck names: ${error.message}`)
//   }
// }

// create One deck
export async function createDeck(deckName) {
  const response = await axiosBearer.post(`/balicky`, { deckName })
  return response.message
}

// rename One deck
export async function renameDeck(deckName, newDeckName) {
  const response = await axiosBearer.patch(`/balicky`, {
    deckName,
    newDeckName,
  })
  return response.message
}

// delete One deck
export async function deleteDeck(deckName) {
  console.log('deckname v fetchdecks ', deckName)
  const response = await axiosBearer.delete(`/balicky`, {
    data: { deckName },
  })
  return response.message
}

// delete one
export async function deleteXCards(ids, allSelected) {
  // const response = await axiosBearer.delete(`/karty/${id}`)

  console.log('from fetchCards.js (deleteXCards)', ids, allSelected)
  const response = await axiosBearer.delete('/karty', {
    data: { ids, allSelected },
  })

  return response.data.message
}

// move to dec
export async function changeDeck(
  cardsId,
  targetDeck,
  targetDeckId,
  allSelected = false
) {
  console.log(
    'from fetchCards.js (changeDeck)',
    cardsId,
    targetDeck,
    targetDeckId,
    allSelected
  )
  const response = await axiosBearer.patch('/karty', {
    data: { cardsId, targetDeck, targetDeckId, allSelected },
  })

  return response.data.message
}

// get number of cards in deck (based on deckId)
export async function getNumberOfCardsInDeck(deckName) {
  try {
    const response = await axiosBearer.post(`/balicky/number-of-cards`, {
      deckName,
    })
    return response.data.cardsCount
  } catch (error) {
    throw new Error(`Error fetching deck names: ${error.message}`)
  }
}
