import React, { useEffect, useState, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { useKeyword } from '../../../contexts/KeywordContext'
import { useCheckedImages } from '../../../contexts/CheckedImagesContext'
import { fetchImages } from '../../../services/fetchFields'
import { useActivePanels } from '../../../contexts/ActivePanels'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import Loading from '/src/components/Loading'
import { toast } from 'react-toastify'

const MyGallery = ({ imageHeight }) => {
  // Access the client
  const queryClient = useQueryClient()
  const [autoPlay, setAutoPlay] = useState(true)
  const toastShown = useRef(false)
  const { checkedImages, setCheckedImages } = useCheckedImages()
  const [isImageChecked, setIsImageChecked] = useState(false)

  var settings = {
    dots: true,
    dotsClass: 'right__gallery__dots',
    infinite: true,
    speed: 500,
    // slidesToShow: 2,
    slidesToScroll: 1,
    className: 'right__gallery__slider',
    autoplaySpeed: 3000,
    autoplay: true,
    centerPadding: '0px',
    easing: 'ease-in-out',
    lazyLoad: 'ondemand',
    pauseOnDotsHover: true,
    pauseOnFocus: true,
  }
  const { keyword, previousKeyword } = useKeyword()

  const defaultImages = [
    {
      // url: '/public/assets/images/default-image-wise-1.jpg',
      url: 'https://images.unsplash.com/photo-1500770974080-d3bdfbae9994?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0OTkwMzN8MHwxfHNlYXJjaHwzfHx3aXNlfGVufDB8MHx8fDE3MDE2MTE0ODJ8MA&ixlib=rb-4.0.3&q=80&w=400',
      attribution:
        "Photo by <a href='https://unsplash.com/@priscilladupreez?utm_source=ENHANCED&utm_medium=referral'>Priscilla Du Preez 🇨🇦</a> on <a href='https://unsplash.com/?utm_source=ENHANCED&utm_medium=referral'>Unsplash</a>",
      additional: {
        onUserSaves:
          'https://api.unsplash.com/photos/hYEW-2wsduE/download?ixid=M3w0OTkwMzN8MHwxfHNlYXJjaHwzfHx3aXNlfGVufDB8MHx8fDE2OTYzNDMzMzd8MA',
      },
    },
    {
      // url: '/public/assets/images/default-image-wise-2.jpg',
      url: 'https://images.unsplash.com/photo-1532007195987-bb4ddeaf052d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0OTkwMzN8MHwxfHNlYXJjaHwxfHx3aXNlfGVufDB8MHx8fDE3MDE2MTE0ODJ8MA&ixlib=rb-4.0.3&q=80&w=400',
      attribution:
        "Photo by <a href='https://unsplash.com/@picsbyjameslee?utm_source=ENHANCED&utm_medium=referral'>James Lee</a> on <a href='https://unsplash.com/?utm_source=ENHANCED&utm_medium=referral'>Unsplash</a>",
      additional: {
        onUserSaves:
          'https://api.unsplash.com/photos/BMO1SzQHWRs/download?ixid=M3w0OTkwMzN8MHwxfHNlYXJjaHwxfHx3aXNlfGVufDB8MHx8fDE2OTYzNDMzMzd8MA',
      },
    },
    {
      // url: '/public/assets/images/default-image-wise-3.jpg',
      url: 'https://images.unsplash.com/photo-1594312915251-48db9280c8f1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0OTkwMzN8MHwxfHNlYXJjaHwyfHx3aXNlfGVufDB8MHx8fDE3MDE2MTE0ODJ8MA&ixlib=rb-4.0.3&q=80&w=400',
      attribution:
        "Photo by <a href='https://unsplash.com/@elisa_cb?utm_source=ENHANCED&utm_medium=referral'>Elisa Calvet B.</a> on <a href='https://unsplash.com/?utm_source=ENHANCED&utm_medium=referral'>Unsplash</a>",
      additional: {
        onUserSaves:
          'https://api.unsplash.com/photos/S3nUOqDmUvc/download?ixid=M3w0OTkwMzN8MHwxfHNlYXJjaHwyfHx3aXNlfGVufDB8MHx8fDE2OTYzNDMzMzd8MA',
      },
    },
  ]

  const [previousImages, setPreviousImages] = useState(defaultImages)

  const { data, isLoading, isError, error } = useQuery(
    ['images', keyword],
    () => fetchImages(keyword),
    {
      onSuccess: (newData) => {
        setPreviousImages(newData)
      },
      // Stop retrying after the first failure
      retry: 0,
      // so query does not automatically run for the initial data
      enabled: keyword !== 'wise' || previousKeyword !== null,
    }
  )

  useEffect(() => {
    console.log('data images ', data)
  }, [data])

  useEffect(() => {
    console.log('checked images ', checkedImages)
  }, [checkedImages])
  // Mutations

  useEffect(() => {
    toastShown.current = false
  }, [keyword])

  if (isError) {
    console.error(error)
    if (!toastShown.current) {
      toast.error('Obrázek není k dispozici', {
        autoClose: 1500,
      })
    }
    toastShown.current = true
    return null

    // return <ErrorComponent />
  }

  const renderImages = () => {
    if (!previousKeyword) {
      return defaultImages
    } else {
      return isLoading ? previousImages : data
    }
  }

  const handleCheckedImage = (e, imageWithProperties) => {
    if (e.target.checked) {
      setCheckedImages((prevState) => [
        ...prevState,
        {
          url: imageWithProperties.url,
          attribution: imageWithProperties.attribution,
        },
      ])
    } else {
      setCheckedImages((prevState) =>
        prevState.filter((img) => img.url !== imageWithProperties.url)
      )
    }
  }

  return (
    <div className="right__gallery">
      {isLoading && previousKeyword && (
        // <div
        //   style={{
        //     position: 'absolute',
        //     top: '50%',
        //     left: '50%',
        //     zIndex: '2000',
        //     transform: 'translate(-50%,-50%)',
        //   }}
        // >
        <Loading />
        // </div>
      )}
      <Slider {...settings}>
        {renderImages().map((image, i) => (
          <div className="right__galery__img-container" key={i}>
            <img src={image.url} height={imageHeight} />
            <p dangerouslySetInnerHTML={{ __html: image.attribution }}></p>
            <input
              type="checkbox"
              // onChange={(e) => handleCheckedImage(e, image.url)}
              onChange={(e) => handleCheckedImage(e, image)}
              // checked={checkedImages.includes(image.url)}
              checked={checkedImages.some((img) => img.url === image.url)}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default MyGallery
