import React, { useState } from 'react'
import { MdOutlineCleaningServices } from 'react-icons/md'

export default function Input({
  onSend,
  onCleanChat,
  promptSubmitted,
  setPromptSubmitted,
}) {
  const [text, setText] = useState('')

  const handleInputChange = (e) => {
    setText(e.target.value)
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  const handleSend = (e) => {
    e.preventDefault()
    console.log('input submitted')
    onSend(text)
    setPromptSubmitted(true)
    setText('')
  }

  return (
    <div className="chat__input">
      <form onSubmit={handleSend}>
        <button type="button" onClick={onCleanChat}>
          <MdOutlineCleaningServices />
        </button>
        <textarea
          autoFocus
          type="text"
          onChange={handleInputChange}
          value={text}
          rows={1}
          placeholder="jak se v angličtině používá...?"
        />
        <button>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 500 500"
          >
            <g>
              <g>
                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
              </g>
            </g>
          </svg>
        </button>
      </form>
    </div>
  )
}
