import { useEffect, useRef } from 'react'

export default function Messages({ messages, AIchatContentRef }) {
  const el = useRef(null)
  // useEffect(() => {
  //   el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
  // })
  return (
    <div ref={AIchatContentRef} className="chat__messages">
      {messages}
      <div id={'el'} ref={el} />
    </div>
  )
}
