import { createContext, useContext, useState, useEffect } from 'react'

const ModalContext = createContext()

export function useModal() {
  return useContext(ModalContext)
}

export function ModalProvider({ children }) {
  const [showModal, setShowModal] = useState(null)

  const [modalContent, setModalContent] = useState(null)

  const value = {
    showModal,
    setShowModal,
    modalContent,
    setModalContent,
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}
