// import { StaticImageData } from "next/image";
import Image from '/src/components/Image'
import { motion } from 'framer-motion'
import { useGetBlogPost } from '/src/hooks/useVocabAPI'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// interface ArticleInterface {
//     articleImg: StaticImageData;
//     avatarImg: StaticImageData;
//     title: string;
//     author: string;
// }

const ArticleBox = ({
  id,
  articleImg,
  title,
  avatarImg,
  tags,
  crt,
  setDisplayPost,
}) => {
  useEffect(() => {
    console.log('id : ', id)
  }, [id])
  const navigate = useNavigate()
  const handlePostClick = () => {
    console.log('clicked id: ', id)
    navigate(`/blog/${id}`)
    // setDisplayPost(id)
  }

  const articleVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  }

  return (
    <motion.div
      className="my-2"
      variants={articleVariants}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      style={{
        border: '1px solid black',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      <div
        onClick={() => {
          handlePostClick()
        }}
        className="cursor-pointer"
      >
        <Image
          src={articleImg}
          // style={{ maxWidth: '704px', maxHeight: '396px' }}
        />
      </div>
      <div className="py-2">
        <div
          className="my-5"
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          {tags &&
            tags.map((tag) => (
              <span
                key={tag.id}
                className="py-1 px-2 mr-2 rounded-full text-white bg-blue-500 text-sm"
                style={{
                  backgroundColor: `${
                    tag.name === 'aplikace' ? '#3B82F6' : '#EF4444'
                  }`,
                }}
              >
                {tag.name}
              </span>
            ))}
        </div>
        <h3
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0 0.4rem',
            textAlign: 'center',
          }}
          className="header-color text-2xl font-bold my-2 cursor-pointer"
          onClick={() => {
            handlePostClick()
          }}
        >
          {title}
        </h3>
        {/* <p className="text-color my-2 text-sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p> */}
        {/* <div className="border-b border-gray-600 my-5" /> */}
        <div
          className="flex items-center gap-4"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Image
            src={avatarImg}
            width="36px"
            height="36px"
            className="rounded-full"
          />
          <div>
            <p className="text-color text-lg">
              {/* <span className="header-color font-semibold">Angličtinomat</span>{' '} */}
              {crt}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default ArticleBox
