import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import useKeyboardShortcut from '/src/hooks/useKeyboardShortcut'
import AnkiFormSelect from './AnkiFormSelect'
import AnkiFormRow from './AnkiFormRow'
import AnkiFormRowImage from './AnkiFormRowImage'
import Button from '/src/components/10_utils/Button'
import styled from 'styled-components'
import Select from '/src/components/Select'
import { noteTypeSelect_, initialStateFormData } from './AnkiForm_helpers'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
const AnkiFormForm = ({
  formData,

  handleInputChange,
  handleSubmit,
  deckNames,
  formRef,
}) => {
  const [deckName, setDeckName] = useState(formData.deckName)
  const [noteType, setNoteType] = useState(formData.noteType)
  // const [mnemonic, setMnemonic] = useState(
  //   ''
  // )
  //   const [mnemonics, setMnemonics] = useState([])
  //   const [customMnemonics, setCustomMnemonics] = useState([])
  const noteTypeSelect = noteTypeSelect_

  useKeyboardShortcut([
    {
      keys: ['Ctrl', 'Shift', 'l'],
      enabled: true,
      action: handleSubmit,
    },
  ])

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <AnkiFormRow
        id="cz"
        label="Česky"
        value={formData.cz}
        handleInputChange={handleInputChange}
      />
      <AnkiFormRow
        id="en"
        label="Anglicky"
        value={formData.en}
        handleInputChange={handleInputChange}
      />
      <AnkiFormRow
        id="definition"
        label="Definice"
        value={formData.definition}
        handleInputChange={handleInputChange}
      />

      <AnkiFormRow
        id="example"
        label="Příklad"
        value={formData.example}
        handleInputChange={handleInputChange}
      />

      <AnkiFormRow
        id="mnemonic"
        label="Pomůcka"
        value={formData.mnemonic}
        handleInputChange={handleInputChange}
        enableCustomMnemonics={false}
      />

      <AnkiFormRowImage
        id="image"
        label="Obrázek"
        placeholder=""
        value={formData.image}
        handleInputChange={handleInputChange}
      />
      {/* ... other fields ... */}
      <div className="middle__anki-form__metadata">
        <Select
          id="deckName"
          label="Balíček"
          // value={deckName}
          value={`${formData.deckName}:deckid${formData.deckId}`}
          selectData={deckNames}
          onChange={handleInputChange}
        />
        <Select
          id="noteType"
          label="Typ karty"
          value={formData.noteType}
          // value={noteTypeSelect[0].value}
          selectData={noteTypeSelect}
          onChange={handleInputChange}
        />
      </div>
      <Button
        text="Uložit"
        type="submit"
        className="__btn-orange middle__anki-form__submit"
      />
    </Form>
  )
}

export default AnkiFormForm

const Form = styled.form`
  position: relative;
  margin-top: 1rem;
  width: 100%;
  /* border: 1px solid var(--color-gray-light); */
  border-radius: var(--border-radius);
  margin: 0 auto;
  align-self: flex-start;
`
