import React, { forwardRef } from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  color: ${(props) => (props.$color ? props.$color : 'var(--color-white)')};

  border: 1px solid
    ${(props) => (props.$color ? props.$color : 'var(--color-white)')};

  /* background-color: ${(props) =>
    props.$backgroundColor
      ? `${props.$backgroundColor}`
      : 'var(--color-theme-1)'}; */
  /* : 'var(--color-black)'}; */

  border: ${(props) => (props.$border ? `${props.$border}` : 'none')};
  transition: var(--transition);
  font-weight: 500;
  font-size: var(--font-size-small);
  padding: ${(props) => (props.$padding ? `${props.$padding}` : '0.5rem 1rem')};
  /* padding: 0.5rem 1rem; */

  background-color: ${(props) =>
    props.disabled
      ? '#aaa'
      : props.$backgroundColor
      ? `${props.$backgroundColor}`
      : 'var(--color-theme-1)'};

  /* pokud není default (černé pozadí, bílé písmo), reversnout je při hoveru */
  &:hover {
    background-color: ${(props) =>
      props.$backgroundColor && props.$color && `${props.$color}`};

    color: ${(props) =>
      props.$backgroundColor && props.$color && `${props.$backgroundColor}`};

    border: 1px solid
      ${(props) =>
        props.$backgroundColor && props.$color && `${props.$backgroundColor}`};
  }
`
const Button = forwardRef(
  // forwardRef is needed to pass ref to the button
  (
    {
      text,
      title,
      type = 'button',
      padding = '',
      inlineStyle,
      className = '',
      disabled,
      onClick,
      backgroundColor = 'rgb(31, 41, 55)',
      color = 'var(--color-white)',
      children,
      border,
    },
    buttonRef
  ) => {
    return (
      <StyledButton
        type={type}
        className={className}
        disabled={disabled}
        onClick={onClick}
        $padding={padding}
        $backgroundColor={backgroundColor}
        $color={color}
        $border={border}
        style={inlineStyle}
        title={title}
        ref={buttonRef}
      >
        {text}
        {children}
      </StyledButton>
    )
  }
)

Button.displayName = 'Button'

export default Button
