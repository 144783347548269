import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--border-radius);
  padding: 0.2rem 0.8rem;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
  color: inherit;
  background-color: #fff;
  box-shadow: 0 0 0 2px #aaa;
  transition: box-shadow 0.2s ease-in-out;
  &:focus {
    box-shadow: 0 0 0 2px #007bff;
  }
  &::placeholder {
    color: #aaa;
  }
`
const InputBasic = ({
  type = 'text',
  value,
  placeholder = '',
  onChange,
  ...rest
}) => {
  return (
    <Input
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    />
  )
}

export default InputBasic
