import React, { useState } from 'react'
import formatDate from '/src/utils/formatDate'

const VoabTableRow = ({
  card,
  selectedCards,
  setSelectedCards,
  areAllDataCheckboxesChecked,
  setAreAllDataCheckboxesChecked,
}) => {
  const toggleCheckbox = () => {
    if (areAllDataCheckboxesChecked) {
      setAreAllDataCheckboxesChecked(false)

      setSelectedCards(new Set(card._id))
    }

    if (selectedCards.has(card._id)) {
      // -> user wants to uncheck

      const newSet = new Set(selectedCards)
      newSet.delete(card._id)
      setSelectedCards(newSet)
    } else {
      // -> user wants to check
      const newSet = new Set(selectedCards)
      newSet.add(card._id)
      setSelectedCards(newSet)
    }
  }
  // console.log('areAllDataCheckboxesChecked ', areAllDataCheckboxesChecked)
  // console.log('SELECTED CARDS ', selectedCards)

  let noteType_formatted
  if (card.noteType === 'czechToEnglish') noteType_formatted = 'CZ-EN'
  else if (card.noteType === 'englishToCzech') noteType_formatted = 'EN-CZ'
  else if (card.noteType === 'both') noteType_formatted = 'oboustranná'

  return (
    <tr style={{ opacity: `${card.suspended ? '0.5' : '1'}` }}>
      <td>
        {' '}
        <input
          type="checkbox"
          checked={selectedCards.has(card._id) || areAllDataCheckboxesChecked}
          onChange={toggleCheckbox}
          style={{ textAlign: 'center' }}
        />{' '}
      </td>
      <td dangerouslySetInnerHTML={{ __html: card.en }}></td>

      <td dangerouslySetInnerHTML={{ __html: card.cz }}></td>

      <td dangerouslySetInnerHTML={{ __html: card.deckName }}></td>

      {/* <td dangerouslySetInnerHTML={{ __html: card.image }}></td>

      <td dangerouslySetInnerHTML={{ __html: card.pronunciation }}></td>

      <td dangerouslySetInnerHTML={{ __html: card.example }}></td>

      <td dangerouslySetInnerHTML={{ __html: card.mnemonic }}></td> */}

      {/* <td dangerouslySetInnerHTML={{ __html: card.reps }}></td>

      <td dangerouslySetInnerHTML={{ __html: card.lapses }}></td> */}

      {/* transform to formt 20.03.2023 */}

      <td
        dangerouslySetInnerHTML={{
          __html: formatDate(card.createdAt, 'dd.mm.yyyy'),
        }}
      ></td>

      <td
        dangerouslySetInnerHTML={{
          __html:
            card.SRS.type === 0
              ? '-'
              : formatDate(card.SRS.dueDate, 'dd.mm.yyyy'),
        }}
      ></td>

      <td dangerouslySetInnerHTML={{ __html: noteType_formatted }}></td>
      <td dangerouslySetInnerHTML={{ __html: card.card }}></td>
    </tr>
  )
}

export default VoabTableRow
