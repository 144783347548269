import React, { useState, useRef, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { useKeyword } from '../../../contexts/KeywordContext'
import { axiosBearer } from '../../../services/axiosInstances'
import { LuImagePlus } from 'react-icons/lu'
import { toast } from 'react-toastify'
// import { useModal } from '../../../contexts/ModalContext'

// import Modal from '../../../components/10_utils/Modal'
// import SearchMnemonicsModalContent from './SearchMnemonicsModalContent'

const AnkiFormRow = ({
  id,
  label,
  placeholder = '',
  value,
  handleInputChange,
  ankiFormRowImageRef,
}) => {
  const { keyword, isInputEnglish, previousKeyword } = useKeyword()
  //   const { showModal, setShowModal } = useModal()

  const addContentToEditor = (contentToAdd) => {
    if (editorRef.current) {
      const editor = editorRef.current.editor
      const currentContent = editor.getContent()
      editor.setContent(currentContent + contentToAdd)
    }
  }
  // const editorRef = useRef(null)
  const editorRef = ankiFormRowImageRef
  const [isEditorFocused, setEditorFocused] = useState(false)

  const pastedImagesRef = useRef([])

  const handleNodeChange = async (editor) => {
    // get all images's src in the editor
    const currentImageElements = editor.dom.select('img').map((img) => img.src)

    // rozdíl mezi pasted a current imgs = images to remove
    const removedImages = pastedImagesRef.current.filter(
      (imgSrc) => !currentImageElements.includes(imgSrc)
    )

    for (const imageSrc of removedImages) {
      // delete also from server
      try {
        const response = await axiosBearer.delete('/uploads/images', {
          data: { imageSrc },
        })
        console.log(response.data)

        console.log(`Image ${imageSrc} successfully deleted from the server.`)
        pastedImagesRef.current = pastedImagesRef.current.filter(
          (el) => el !== imageSrc
        )
      } catch (error) {
        console.error(`Error deleting image ${imageSrc}:`, error)
      }
    }
  }

  const handleFilePickerClick = () => {
    document.getElementById('imageUpload').click()
  }

  const handleFileChange = (e) => {
    var file = e.target.files[0]
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg',
      'image/svg+xml',
      'image/webp',
    ]

    if (allowedTypes.includes(file.type)) {
      // if (file && file.type.startsWith('image/')) {

      var reader = new FileReader()
      reader.onload = function () {
        var id = 'blobid' + new Date().getTime()
        var blobCache = editorRef.current.editor.editorUpload.blobCache
        var base64 = reader.result.split(',')[1]
        var blobInfo = blobCache.create(id, file, base64)
        blobCache.add(blobInfo)

        editorRef.current.editor.insertContent(
          `<img src="${blobInfo.blobUri()}" />`
        )
      }
      reader.readAsDataURL(file)
    } else {
      toast.error('Lze vložit pouze obrázek (jpg, png, gif, svg, webp')
    }
  }
  return (
    <div className="middle__anki-form__row">
      <label
        htmlFor={id}
        className={`${
          isEditorFocused ? 'focused' : ''
        } middle__anki-form__row__floating-label`}
      >
        <span>{label}</span>
      </label>

      <div className="middle__anki-form__row__input" id={id}>
        <Editor
          key="withoutPlaceholder"
          ref={editorRef}
          initialValue={value}
          init={{
            inline: true,
            toolbar: '',
            // toolbar: 'undo redo',
            menubar: false,

            paste_data_images: true,
            images_upload_handler: function (blobInfo) {
              return new Promise(async (success, failure) => {
                const formData = new FormData()
                formData.append('file', blobInfo.blob(), blobInfo.filename())

                formData.append('k', keyword)

                console.log('JSEM V IMAGES UPLOAD HANDLERU')
                try {
                  formData.forEach((value, key) => {
                    console.log('FORM DATA KEY ' + key + ': ' + value)
                  })
                  const { data } = await axiosBearer.post(
                    '/uploads/images',
                    formData
                  )

                  if (data.location) {
                    pastedImagesRef.current = [
                      ...pastedImagesRef.current,
                      data.location,
                    ]

                    success(data.location)
                    // success(data.location) // Return the URL where the image is stored
                    // handleInputChange(id, editor.getContent())
                    //                   addContentToEditor('<p>&nbsp;</p>')
                    //                   addContentToEditor(
                    //                     `
                    // <img src=${data.location} data-mce-src=${data.location}>
                    // `
                    //                   )

                    // editor.insertContent(
                    //           `<img src="${data.location}" />`
                    //         )
                  } else {
                    failure('Image upload failed')
                  }
                } catch (error) {
                  failure('Image upload failed')
                }
              })
            },

            setup: (editor) => {
              setTimeout(() => {
                editor.on('keyup', (event) => {
                  if (event.key === 'Backspace' || event.key === 'Delete') {
                    handleNodeChange(editor)
                  }
                })

                // povolené jsou jen images - zamezit text inputu
                // editor.on('keydown', (event) => {
                //   // Allow only Ctrl+V / Cmd+V for pasting
                //   if (
                //     !(event.key === 'v' && (event.ctrlKey || event.metaKey))
                //   ) {
                //     event.preventDefault()
                //   }
                // })

                // editor.on('keydown', (event) => {
                //   // Allow only Ctrl+V / Cmd+V for pasting, Backspace, and Delete
                //   if (
                //     !(event.key === 'v' && (event.ctrlKey || event.metaKey)) &&
                //     event.key !== 'Backspace' &&
                //     event.key !== 'Delete'
                //   ) {
                //     event.preventDefault()
                //   }
                // })

                // editor.on('keydown', (event) => {
                //   // Allow only Ctrl+V / Cmd+V for pasting, Backspace, Delete, Space, and Enter
                //   if (
                //     !(event.key === 'v' && (event.ctrlKey || event.metaKey)) &&
                //     event.key !== 'Backspace' &&
                //     event.key !== 'Delete' &&
                //     event.key !== 'Enter' &&
                //     event.key !== ' '
                //   ) {
                //     event.preventDefault()
                //   }
                // })

                editor.on('keydown', (event) => {
                  // allowed non-character keys
                  const allowedKeys = [
                    'ArrowUp',
                    'ArrowDown',
                    'ArrowLeft',
                    'ArrowRight',
                    'Backspace',
                    'Delete',
                    'Enter',
                    'Tab',
                    'PageUp',
                    'PageDown',
                    'End',
                    'Home',
                    'Shift',
                    'Control',
                    'Alt',
                    'Escape',
                    'CapsLock',
                    'Insert',
                  ]
                  const isPasteShortcut =
                    event.key === 'v' && (event.ctrlKey || event.metaKey)

                  // Check if the pressed key is a character key
                  const isCharacterKey =
                    event.key.length === 1 && event.key.match(/[\w\s]/)

                  // Prevent default action if it's a character key and not an allowed action
                  if (
                    isCharacterKey &&
                    !isPasteShortcut &&
                    !allowedKeys.includes(event.key)
                  ) {
                    event.preventDefault()
                  }
                })

                editor.on('input', (event) => {
                  handleInputChange(editor.getContent(), id)
                })

                editor.on('change', (event) => {
                  handleInputChange(editor.getContent(), id)
                })

                editor.on('paste', (event) => {
                  handleInputChange(editor.getContent(), id)
                })

                // Handle image drop event
                editor.on('drop', async (event) => {
                  const { dataTransfer } = event
                  if (
                    dataTransfer &&
                    dataTransfer.files &&
                    dataTransfer.files.length
                  ) {
                    event.preventDefault() // Prevent the default drop behavior

                    // Upload each dropped file
                    for (let i = 0; i < dataTransfer.files.length; i++) {
                      const file = dataTransfer.files[i]

                      // Ensure we're handling only images
                      if (file.type.startsWith('image/')) {
                        const formData = new FormData()
                        formData.append('file', file)
                        formData.append('k', keyword)

                        try {
                          formData.forEach((value, key) => {
                            console.log(key + ': ' + value)
                          })

                          const { data } = await axiosBearer.post(
                            '/uploads/images',
                            formData
                          )

                          if (data.location) {
                            // Insert the image at the location where it was dropped
                            editor.insertContent(
                              `<img src="${data.location}" />`
                            )
                            pastedImagesRef.current = [
                              ...pastedImagesRef.current,
                              data.location,
                            ]
                          } else {
                            console.error('Image upload failed')
                          }
                        } catch (error) {
                          console.error('Image upload failed')
                        }
                      }
                    }
                  }
                })
              }, 0)
            },
          }}
          //   onEditorChange={handleEditorChange}
          tinymceScriptSrc="/wysiwyg/js/tinymce/tinymce.min.js"
        />

        {/* file picker */}
        <button
          type="button"
          className="middle__keyword-form__submit showMore"
          onClick={(e) => handleFilePickerClick(e)}
        >
          <input
            type="file"
            id="imageUpload"
            // accept="image/*"
            accept="image/jpeg, image/png, image/gif, image/svg, image/svg+xml, image/webp"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />

          <LuImagePlus />
        </button>
      </div>
    </div>
  )
}

export default AnkiFormRow
