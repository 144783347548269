import { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
} from 'firebase/auth'
import '/src/services/firebase'
const auth = getAuth()

// import auth from '../services/firebase'

// const auth = firebase.auth()

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  // useEffect(() => {
  //   console.log('currentUser ', currentUser)
  // }, [currentUser])

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password) // returns a promise
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function changePassword(newPassword) {
    return updatePassword(currentUser, newPassword)
  }

  useEffect(() => {
    //  we want it to run only on mount
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // onAuthStateChanged returns an unsubscribe method that can be used to stop listening for auth state changees when called

      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe // when component unmounts, we want to unsubscribe from the listener
  }, [])

  const value = {
    currentUser,
    error,
    setError,
    login,
    register,
    logout,
    resetPassword,
    changePassword,
    // updateUserProfile,
  }

  return (
    <AuthContext.Provider value={value}>
      {loading ? <div></div> : children}
      {/* {children} */}
    </AuthContext.Provider>
  )
}
