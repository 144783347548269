import { axiosBearer } from './axiosInstances.js'

// getAll
export async function getAllCards(
  page,
  query = '',
  sort = '',
  filterDeck = '',
  filterFlag = ''
) {
  const response = await axiosBearer.get(
    `/karty?page=${page}${query ? `&q=${query}` : ''}${
      sort ? `&s=${sort}` : ''
    }${filterDeck ? `&deck=${filterDeck}` : ''}${
      filterFlag ? `&flag=${filterFlag}` : ''
    }
    `
  )
  return response.data
}

// search
export async function searchCards(q) {
  const response = await axiosBearer.get(`/karty/search?q=${q}`)
  return response.data
}

// delete one
export async function deleteXCards(ids, allSelected) {
  // const response = await axiosBearer.delete(`/karty/${id}`)

  console.log('from fetchCards.js (deleteXCards)', ids, allSelected)
  const response = await axiosBearer.delete('/karty', {
    data: { ids, allSelected },
  })

  return response.data.message
}

// move to dec
export async function changeDeck(
  cardsId,
  targetDeck,
  targetDeckId,
  allSelected = false
) {
  console.log(
    'from fetchCards.js (changeDeck)',
    cardsId,
    targetDeck,
    targetDeckId,
    allSelected
  )
  const response = await axiosBearer.patch('/karty', {
    data: { cardsId, targetDeck, targetDeckId, allSelected },
  })

  return response.data.message
}

export async function modifyXCards(
  cardsId,
  allSelected,
  action,
  additionalData = null
) {
  console.log('from fetchCards.js (modifyXCards)', cardsId, allSelected)

  const response = await axiosBearer.patch(`/karty/${action}`, {
    data: { cardsId, allSelected, additionalData },
  })

  return response.data.message
}

// modify one
export async function modifyOneCard(cardId, fieldsToUpdate) {
  console.log('from fetchCards.js (modifyOneCard)', cardId, fieldsToUpdate)

  const response = await axiosBearer.patch(`/karty/edit/${cardId}`, {
    data: { fieldsToUpdate },
  })

  return response.data.message
}

export async function getOneCard(cardId) {
  console.log('from fetchCards.js (getOneCard)', cardId)
  const response = await axiosBearer.patch(`/karty/${cardId}`)

  return response.data.card
}

// get (20) cards to review
export async function getCardsToReview(deckId, learnAhead) {
  const response = await axiosBearer.get(
    `/opakovani?la=${learnAhead}${deckId ? `&d=${deckId}` : ''}`
  )
  return response.data.cards
}

// submit 1 card to review
export async function submitCardToReview(cardId, ease, nextReviewLabel) {
  const response = await axiosBearer.patch(`/opakovani`, {
    data: { cardId, ease, nextReviewLabel },
  })

  // console.log('sutmitcard response ', response.data)
  return response.data
}

// reset DB helper function
export async function resetDB(sumOfCards, inEachDeck) {
  const response = await axiosBearer.get(
    `/resetDB?sumOfCards=${sumOfCards}&inEachDeck=${inEachDeck}`
  )

  return response.data
}
