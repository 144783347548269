import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

const Select = ({
  id,
  value,
  onChange,
  selectData,
  placeholderOption = null,
  label,
  children,
  containerStyling,
}) => {
  // console.log('jsem v selectu, selectData ', selectData)
  const optionValueWithId = (value, separator, id) => {
    return `${value}${separator}${id}`
  }
  return (
    <SelectContainer style={containerStyling}>
      {/* <SelectContainer > */}
      {label && <label htmlFor={id}>{label}</label>}
      <StyledSelect
        id={id}
        // value={value}
        // onChange={(e) => {
        //   onChange(id, e.target.value)
        // }}
        onChange={(e) => {
          e.target.id = id // Add this line if id is not already set on e.target
          onChange(id, e.target.value) // tohle je původní line, která asi funguje pro určitý komponent ale ještě jsem neidentifikoval pro jaký.  sledovat. pro /slovícka funguje toto: onChange(e)
          // onChange(e)
          onChange(e.target.value, id)
        }}
        // value="" // bez toho se mi nezobrazuje placeholder
        value={value}
      >
        {placeholderOption && (
          <option value={placeholderOption.value} disabled key={uuidv4()}>
            {placeholderOption.content}
          </option>
        )}

        {selectData &&
          selectData.map((optionItem) => (
            <option
              value={
                id === 'deckName'
                  ? optionValueWithId(
                      optionItem.value,
                      ':deckid',
                      optionItem._id
                    )
                  : optionItem.value
              }
              key={optionItem._id}
            >
              {optionItem.content || optionItem.label || optionItem.value}
            </option>
          ))}

        {children && children}
      </StyledSelect>
    </SelectContainer>
  )
}

export default Select

const StyledSelect = styled.select`
  padding: 0.5rem;
  border-radius: var(--border-radius);
  border: 1px solid #ccc;
  color: var(--color-black);
`
const SelectContainer = styled.div`
  margin: 1.3rem 0;
  display: grid;
  grid-template-columns: minmax(50px, 1fr) minmax(50px, 3fr);
  align-items: center;

  & > select {
    padding: 0.2rem;
    margin: 0 auto;
    width: 250px;
    max-width: 90%;
    border-radius: var(--border-radius-input);
  }
`
