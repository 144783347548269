import { axiosBearer } from './axiosInstances.js'

export async function getUser(userId) {
  const response = await axiosBearer.get(`/user/${userId}`)
  return response.data.user
}

export async function signUp(userData, action = null, token = null) {
  const response = await axiosBearer.post(`/user/signUp`, {
    userData,
    action,
    token,
  })
  return response.data
}

export async function sendVerificationEmail(userEmail) {
  const redirectUrl =
    // import.meta.env.VITE_REDIRECT_URL_AFTER_EMAIL_CONFIRMATION_LINK_CLICKED ||
    // 'http://localhost:5173/opakovani'
    import.meta.env.VITE_REDIRECT_URL_AFTER_EMAIL_CONFIRMATION_LINK_CLICKED // nebylo testováno zda funguje bez fallbacku
  const response = await axiosBearer.post(
    `/user/send-custom-verification-email`,
    {
      userEmail,
      redirectUrl,
    }
  )
  return response.data
}

export async function updateUser(userId, settingsToUpdate) {
  const response = await axiosBearer.patch(`/user`, {
    data: { userId, settingsToUpdate },
  })

  return response.data
}

export async function postOther(x = 'x') {
  const response = await axiosBearer.post(`/user/other`, { x })

  return response.data
}
