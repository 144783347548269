import Image from '/src/components/Image'
import ArticleImg1 from '/public/assets/images/articles/1.jpg'
import ArticleImg2 from '/public/assets/images/articles/2.jpg'

import AvatarImg1 from '/public/assets/images/user_1.jpg'
import AvatarImg2 from '/public/assets/images/user_2.jpg'
import { useState, useEffect } from 'react'
import ArticleBox from './article/ArticleBox'
import { useGetBlogPost } from '/src/hooks/useVocabAPI'
import Article from './article/Article'

const Articles = () => {
  const [articles, setArticles] = useState([])

  const { data, isSuccess } = useGetBlogPost(true)
  const [postsBox, setPostsBox] = useState(null)
  useEffect(() => {
    if (isSuccess) {
      setPostsBox(data)
    }
  }, [isSuccess, data])

  const [displayPost, setDisplayPost] = useState(null)

  return (
    // <>
    //   {displayPost !== null ? (
    //     <Article displayPost={displayPost} setDisplayPost={setDisplayPost} />
    //   ) : (
    <section className="py-5">
      <div className="container mx-auto px-10">
        <div className=" my-10">
          {/* <h3 className="header-color my-5 text-2xl font-bold">
            Nejnovější články
          </h3> */}
          <div className="border-b border-gray-600" />
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 gap-10">
          {/* <ArticleBox
                articleImg={ArticleImg1}
                avatarImg={AvatarImg1}
                title="The quick brown fox jumped over the lazy dog."
                author="Anastasia Dan"
              />
              <ArticleBox
                articleImg={ArticleImg2}
                avatarImg={AvatarImg2}
                title="The quick brown fox jumped over the lazy dog."
                author="Anastasia Dan"
              /> */}
          {postsBox &&
            postsBox.map((post) => (
              <ArticleBox
                key={post.id}
                id={post.id}
                articleImg={post.postImage}
                tags={post.tags}
                avatarImg="/public/assets/images/user_1.jpg"
                title={post.title}
                author="Angličtinomat"
                crt={post.crt}
                // setDisplayPost={setDisplayPost}
              />
            ))}
        </div>
      </div>
    </section>
  )
}

export default Articles
