import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

function ForgotPassword() {
  const { resetPassword } = useAuth()
  const [email, setEmail] = useState('email531@seznam.cz')

  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage('')
      setError('')
      setLoading(true)
      await resetPassword(email)
      setMessage('Check your inbox for further instructions')
    } catch {
      setError('Failed to reset password')
    }

    setLoading(false)
  }
  return (
    <div>
      <h2>ForgotPassword</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email-address">Email address</label>
          <input
            id="email"
            name="email"
            type="email"
            required
            value={email}
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div>
          <button type="submit">Resetovat heslo</button>
        </div>
      </form>

      <p className="text-sm text-white text-center">
        No account yet? <Link to="/prihlasit">Přihlásit se</Link>
      </p>
    </div>
  )
}

export default ForgotPassword
