import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import InputFloatingLabel from '/src/components/InputFloatingLabel'
import Button from '/src/components/10_utils/Button'
import H2 from '/src/components/H2'

// QuickLinkSettingsModal component
const QuickLinkSettingsModal = ({ user, updateUser }) => {
  const [settings, setSettings] = useState({
    quickLink_google: '',
    quickLink_googleImages: '',
  })

  useEffect(() => {
    setSettings({
      quickLink_google: user.settings.app.quickLinks.google || '',
      quickLink_googleImages: user.settings.app.quickLinks.googleImages || '',
    })
  }, [user])

  const handleChangeQuickLinkText = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value })
  }

  const handleSave = () => {
    console.log('saving settings ', settings)
    updateUser(user._id, {
      'settings.app.quickLinks.google': settings.quickLink_google.trim(),
      'settings.app.quickLinks.googleImages':
        settings.quickLink_googleImages.trim(),
    })
  }

  return (
    <Container>
      <H2>Upravit text pro rychlý odkaz:</H2>
      <InputGroup>
        <InputFloatingLabel
          label="Google"
          id="quickLink_google"
          value={settings.quickLink_google}
          onChange={(e) => handleChangeQuickLinkText(e)}
        />
      </InputGroup>
      <InputGroup>
        <InputGroup>
          <InputFloatingLabel
            label="Google Obrázky"
            id="quickLink_googleImages"
            value={settings.quickLink_googleImages}
            onChange={(e) => handleChangeQuickLinkText(e)}
          />
        </InputGroup>
      </InputGroup>
      {/* <SaveButton onClick={handleSave}>Uložit</SaveButton> */}
      <Button
        text="Uložit"
        onClick={handleSave}
        inlineStyle={{
          margin: '0 auto',
          width: '90%',
          display: 'block',
        }}
      />
    </Container>
  )
}

export default QuickLinkSettingsModal

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
`

const InputGroup = styled.div`
  margin-bottom: 10px;
`
