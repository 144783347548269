import React, { useState, useEffect } from 'react'
import { KeywordProvider } from '../../contexts/KeywordContext.jsx'
import styled from 'styled-components'
import VocabOptions from './VocabOptions/VocabOptions'
import VocabTable from './VocabTable/VocabTable'
import VocabFooter from './VocabFooter/VocabFooter'
import '../Learn/Learn.css'
import { useQuery } from '@tanstack/react-query'
import { getAllCards } from '../../services/fetchCards'
// Import your other components and libraries

const Container = styled.div`
  // Your styles
`

const Card = styled.div`
  // Your styles
`

const Filter = styled.div`
  // Your styles
`

export default function Vocab() {
  const [cards, setCards] = useState([])
  // const [searchResult, setSearchResult] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [areAllDataCheckboxesChecked, setAreAllDataCheckboxesChecked] =
    useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCards, setSelectedCards] = useState(new Set())
  const [searchedKeyword, setSearchedKeyword] = useState('')
  const [sortQuery, setSortQuery] = useState('crt-d')
  const [filterDeck, setFilterDeck] = useState('')
  const [filterFlag, setFilterFlag] = useState('')

  const { data, isLoading, isError, error } = useQuery({
    // queryKey: [
    //   `getAllCards ${currentPage} ${searchedKeyword} ${sortQuery} ${filterDeck} ${filterFlag}`,
    // ],
    queryKey: [
      'getAllCards',
      currentPage,
      searchedKeyword,
      sortQuery,
      // filterDeck,
      filterDeck.split(':deckid')[1],
      filterFlag,
    ],
    queryFn: () =>
      getAllCards(
        currentPage,
        searchedKeyword,
        sortQuery,
        // filterDeck,
        filterDeck.split(':deckid')[1],
        filterFlag
      ),
    keepPreviousData: true,
    meta: {
      errorMessage: 'Nepodařilo se načíst slovíčka',
    },
    retry: 1,
    // staleTime: Infinity,
    staleTime: 0,
  })

  const handleCardSelection = (cardId) => {
    // Logic for selecting a card
  }

  const handleCardAction = (actionType) => {
    // Logic for card actions like delete, flag, etc.
  }

  return (
    <KeywordProvider>
      <Container>
        <VocabOptions
          searchedKeyword={searchedKeyword}
          setSearchedKeyword={setSearchedKeyword}
          sortQuery={sortQuery}
          setSortQuery={setSortQuery}
          filterDeck={filterDeck}
          setFilterDeck={setFilterDeck}
          filterFlag={filterFlag}
          setFilterFlag={setFilterFlag}
          setSelectedCards={setSelectedCards}
          selectedCards={selectedCards}
          currentPage={currentPage}
          areAllDataCheckboxesChecked={areAllDataCheckboxesChecked}
          setAreAllDataCheckboxesChecked={setAreAllDataCheckboxesChecked}
          data={data}
        />
        <VocabTable
          // searchResult={searchResult}
          data={data}
          isLoading={isLoading}
          isError={isError}
          error={error}
          currentPage={currentPage}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          setAreAllDataCheckboxesChecked={setAreAllDataCheckboxesChecked}
          areAllDataCheckboxesChecked={areAllDataCheckboxesChecked}
        />
        <VocabFooter
          currentPage={currentPage}
          totalPages={data?.totalPages || 1}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </KeywordProvider>
  )
}
