import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
// import { signUp, sendVerificationEmail } from '/src/services/fetchUser'

import { FcGoogle } from 'react-icons/fc'
// import { SiFacebook } from 'react-icons/si'
import { MdEmail } from 'react-icons/md'
import './HomeModalContent.css'
import SignIn from './SignIn/SignIn'
import SignUp from './SignUp/SignUp'
import './sign.css'
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  // FacebookAuthProvider,
  browserPopupRedirectResolver,
} from 'firebase/auth'
import app from '../../services/firebase'
import { signUp } from '../../services/fetchUser'
import { useNavigate } from 'react-router-dom'
import { useModal } from '/src/contexts/ModalContext'

const auth = getAuth(app)
// auth.languageCode = 'cs'

const HomeModalContent = ({ text, setLogin, login }) => {
  const { modalContent, setModalContent } = useModal()
  const [showEmailVerificationReminder, setShowEmailVerificationReminder] =
    useState(false)
  const [emailAction, setEmailAction] = useState(false)
  const [verificationEmailSent, setVerificationEmailSent] = useState(false)
  const navigate = useNavigate()
  console.log('setmodalcontent  ', setModalContent)

  // console.log(import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN)

  const queryClient = useQueryClient()

  function googleSignInPopup(event) {
    event.preventDefault()
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider, browserPopupRedirectResolver)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        console.log('result ', result)
        const credential = GoogleAuthProvider.credentialFromResult(result)
        console.log('credentialFromResult ', credential)
        const token = credential.accessToken
        // The signed-in user info.

        const user = result.user
        // await signUp({ id: user.uid, email: user.email }, 'google')

        // IdP data available using getAdditionalUserInfo(result)
        // ...
        return user
      })
      .then((user) => {
        signUp({ id: user.uid, email: user.email }, 'google')
      })
      .then(() => {
        setModalContent(null)

        navigate('/opakovani')
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['getDecks', true] })
      })
      .catch((error) => {
        console.log(error)
        // // Handle Errors here.
        // const errorCode = error.code
        // const errorMessage = error.message
        // // The email of the user's account used.
        // const email = error.customData.email
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error)
        // // ...
      })
  }

  // function facebookSignIn(event) {
  //   event.preventDefault()
  //   const provider = new FacebookAuthProvider()

  //   signInWithPopup(auth, provider, browserPopupRedirectResolver)
  //     .then((result) => {
  //       // The signed-in user info.
  //       const user = result.user

  //       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //       const credential = FacebookAuthProvider.credentialFromResult(result)
  //       const accessToken = credential.accessToken

  //       // IdP data available using getAdditionalUserInfo(result)
  //       // ...
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       // Handle Errors here.
  //       // const errorCode = error.code
  //       // const errorMessage = error.message
  //       // // The email of the user's account used.
  //       // const email = error.customData.email
  //       // // The AuthCredential type that was used.
  //       // const credential = FacebookAuthProvider.credentialFromError(error)

  //       // ...
  //     })
  // }

  useEffect(() => {
    console.log('login v homemodal ', login)
  }, [login])

  useEffect(() => {
    console.log('text v homemodal ', text)
  }, [text])

  return verificationEmailSent ? (
    <div className="portal__modal__container">
      <h2>Zbývá poslední krok! 👍 </h2>
      <p>Na Váš email byl zaslán ověřovací odkaz.</p>
      <p>Po kliknutí na něj se budete moct přihlásit.</p>
    </div>
  ) : (
    <div className="portal__modal__container">
      {!showEmailVerificationReminder && (
        <h2 className="portal__modal__header">{text.header}</h2>
      )}

      {emailAction ? (
        login ? (
          <SignIn
            showEmailVerificationReminder={showEmailVerificationReminder}
            setShowEmailVerificationReminder={setShowEmailVerificationReminder}
            setModalContent={setModalContent}
          />
        ) : (
          <SignUp setVerificationEmailSent={setVerificationEmailSent} />
        )
      ) : (
        <div className="portal__modal__auth-options">
          <div
            className="portal__modal__auth-option"
            onClick={(event) => googleSignInPopup(event)}
          >
            <a className="" href="" rel="">
              <div className="portal__modal__auth-option__container">
                <FcGoogle />
                <span>Google {text.action}</span>
              </div>
            </a>
          </div>
          {/* <div
            className="portal__modal__auth-option"
            onClick={(event) => facebookSignIn(event)}
          >
            <a className="" href="">
              <div className="portal__modal__auth-option__container">
                <SiFacebook />
                <span>Facebook {text.action}</span>
              </div>
            </a>
          </div> */}
          <div
            className="portal__modal__auth-option"
            onClick={() => {
              setEmailAction(true)
            }}
          >
            <button className="">
              <div className="portal__modal__auth-option__container">
                <MdEmail />
                <span>Email {text.action}</span>
              </div>
            </button>
          </div>
        </div>
      )}

      <div className="portal__modal__auth-option__alternative">
        <p className="">
          {text.alternativeText}{' '}
          <button className="">
            <b
              onClick={() => {
                setLogin(!login)
              }}
            >
              {text.alternativeLink}
            </b>
          </button>
        </p>
        <p className="portal__modal__auth-option__optional-text">
          {text.alternativeOptional}
        </p>
      </div>
    </div>
  )
}

export default HomeModalContent
