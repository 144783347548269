import { useState, useEffect } from 'react'
import { FaLevelUpAlt } from 'react-icons/fa'

import { useGetBlogPost } from '/src/hooks/useVocabAPI'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import Loading from '/src/components/Loading'

// Article Component
const Article = ({ displayPost, setDisplayPost }) => {
  const { postId } = useParams()
  const navigate = useNavigate()

  // const { data, isSuccess } = useGetBlogPost(false, displayPost)
  const { data, isSuccess } = useGetBlogPost(false, postId)

  const [post, setPost] = useState(null)
  useEffect(() => {
    if (isSuccess) {
      setPost(data)
    }
  }, [isSuccess, data])

  if (!post) return <Loading />

  return (
    <ArticleContainer>
      {post.postImage && <ArticleImage src={post.postImage} alt={post.title} />}
      <p
        onClick={() => {
          // setDisplayPost(null)
          navigate('/blog')
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
          <FaLevelUpAlt />
          všechny články
        </span>
      </p>
      <ArticleTitle>{post.title}</ArticleTitle>
      <ArticleContent dangerouslySetInnerHTML={{ __html: post.body }} />
    </ArticleContainer>
  )
}

export default Article

// Styled components with responsive design
const ArticleContainer = styled.article`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin: 20px;
    padding: 15px;
  }
`

const ArticleImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 20px;
`

const ArticleTitle = styled.h1`
  font-size: 2em;
  color: #333;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`

const ArticleContent = styled.div`
  margin-top: 20px;
  font-size: 1.1em;
  line-height: 1.6;
  color: #444;

  // Add bottom margin to all paragraphs
  p {
    margin-bottom: 8px; // Adjust as needed
    margin-top: 8px; // Adjust as needed
  }

  // Add space after headers if they are directly above paragraphs
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px; // Adjust as needed
    margin-top: 10px; // Adjust as needed
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }

  & img {
    max-height: 50vh;
    width: auto;
    margin: 0 auto;
  }

  & ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  & ul {
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 20px;
  }
`
