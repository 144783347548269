import React, { useState } from 'react'
import Button from '/src/components/10_utils/Button'
import { useQueryClient } from '@tanstack/react-query'
import { resetDB } from '/src/services/fetchCards'

const Other = () => {
  const [resetDB_settings, setResetDB_settings] = useState({
    sumOfCards: 40,
    inEachDeck: 2,
  })

  const queryClient = useQueryClient()
  return (
    <div>
      <div style={{ maxWidth: '60%' }}>
        <label htmlFor="sumOfCards">sumOfCards</label>
        <input
          type="number"
          value={resetDB_settings.sumOfCards}
          onChange={(e) =>
            setResetDB_settings({
              ...resetDB_settings,
              sumOfCards: e.target.value,
            })
          }
        />
        {/* do the same for .inEachDeck: */}
        <label htmlFor="inEachDeck">inEachDeck</label>
        <input
          type="number"
          value={resetDB_settings.inEachDeck}
          onChange={(e) =>
            setResetDB_settings({
              ...resetDB_settings,
              inEachDeck: e.target.value,
            })
          }
        />
      </div>
      <Button
        text="reset db"
        onClick={() => {
          resetDB(resetDB_settings.sumOfCards, resetDB_settings.inEachDeck)
          setTimeout(() => {
            queryClient.invalidateQueries(['getDecks', true])
          }, 800)
        }}
      />
    </div>
  )
}

export default Other
