import React, { useState } from 'react'
import DraggableListItems from '/src/components/DraggableListItems'
import SettingsTabContent from './SettingsTabContent'
import styled from 'styled-components'

const CardSideGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  /* align-items: center; */
  justify-content: center;
  justify-items: center;
  /* margin-bottom: 20px; */

  /* & > :nth-child(2) {
    justify-self: center; 
  } */
`

const SettingsContentCards = ({
  englishToCzech_front,
  setEnglishToCzech_front,
  englishToCzech_back,
  setEnglishToCzech_back,

  czechToEnglish_front,
  setCzechToEnglish_front,
  czechToEnglish_back,
  setCzechToEnglish_back,
}) => {
  // console.log(' en to cz front ', englishToCzech_front)
  console.log(' en to cz back ', englishToCzech_back)
  // console.log(' cz to en front ', czechToEnglish_front)
  // console.log(' cz to en back ', czechToEnglish_back)

  return (
    <>
      <h3>Struktura karet</h3>
      <h4
        style={{
          textIndent: '1rem',
        }}
      >
        1. anglicko-české
      </h4>

      <CardSideGrid>
        <div>
          <h5>přední strana</h5>
          <DraggableListItems
            fields={englishToCzech_front}
            setFields={setEnglishToCzech_front}
          />
        </div>

        <div>
          <h5>zadní strana</h5>
          <DraggableListItems
            fields={englishToCzech_back}
            setFields={setEnglishToCzech_back}
          />
        </div>
      </CardSideGrid>

      <h4
        style={{
          textIndent: '1rem',
        }}
      >
        2. česko-anglické
      </h4>
      <CardSideGrid>
        <div>
          <h5>přední strana</h5>
          <DraggableListItems
            fields={czechToEnglish_front}
            setFields={setCzechToEnglish_front}
          />
        </div>

        <div>
          <h5>zadní strana</h5>
          <DraggableListItems
            fields={czechToEnglish_back}
            setFields={setCzechToEnglish_back}
          />
        </div>
      </CardSideGrid>
    </>
  )
}

export default SettingsContentCards
