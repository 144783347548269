import React, { useState, useEffect, useRef } from 'react'
import Loading from '/src/components/Loading'

export default function BotMessage({
  newUserPrompt,
  messagesHistory,
  setMessagesHistory,
  sessionUsedTokens,
  setSessionUsedTokens,
  messages,
  promptSubmitted,
  setPromptSubmitted,
  isLoading,
  setIsLoading,
  botMessage,
  setBotMessage,
}) {
  // useEffect(() => {
  //   // if (isInitialMount.current) {
  //   //   isInitialMount.current = false
  //   // } else
  //   console.log('PROMPT SUBMITTED ', promptSubmitted)
  //   if (promptSubmitted) {
  //     loadMessage()
  //   }
  // }, [promptSubmitted])

  // async function loadMessage() {
  //   console.log('JDU FETCHOVAT')
  //   try {
  //     // 		- [ ] if previousMessages_usedTokens > 3500, před odesláním zmenšit previousMessages, aby se vešlo do 3500
  //     let previousMessages_copy = messagesHistory

  //     if (sessionUsedTokens > 3500) {
  //       previousMessages_copy = JSON.parse(JSON.stringify(messagesHistory)) // Deep copy
  //       let currentTokenCount = sessionUsedTokens

  //       while (currentTokenCount > 3500 && messagesHistory.length > 0) {
  //         const removedMessage = previousMessages_copy.shift() // Remove the first element
  //         currentTokenCount -= removedMessage.tokens // Subtract its token count
  //       }
  //     }

  //     // const response = await fetchMessage()
  //     const response = await getAIchatAnswer(
  //       newUserPrompt,
  //       messagesHistory,
  //       sessionUsedTokens
  //     )
  //     console.log('response ze serveru ', response)
  //     setMessage(response.completion)
  //     // setMessagesHistory((messagesHistory) => [
  //     setMessagesHistory([
  //       ...previousMessages_copy,
  //       {
  //         role: 'user',
  //         content: newUserPrompt,
  //         tokens: response.usedTokens.prompt_tokens,
  //       },
  //       {
  //         role: 'assistant',
  //         content: response.completion,
  //         tokens: response.usedTokens.completion_tokens,
  //       },
  //     ])
  //     setSessionUsedTokens(response.usedTokens.total_tokens)
  //     setPromptSubmitted(false)
  //   } catch (error) {
  //     if (error.response?.message === 'no tokens')
  //       toast.error(
  //         'Vaše tokeny pro chatbota jsou vyčerpány, obnoví se zase první den nového měsíce.'
  //       )
  //     else console.log(error.message)
  //   }
  //   setLoading(false)
  // }
  return (
    <div className="message-container">
      <div className="bot-message">
        {isLoading ? (
          <Loading color="var(--color-theme-1)" size="33" />
        ) : (
          // {
          botMessage
        )}
      </div>
    </div>
  )
}
