import { useEffect } from 'react'

// function useKeyboardShortcut(keysCombination, callback) {
//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       console.log('event.key ', event.key)
//       const keys = keysCombination.map((key) => key.toLowerCase())

//       if (
//         (keys.includes('ctrl') && event.ctrlKey) ||
//         (keys.includes('cmd') && event.metaKey) // mac support
//       ) {
//         if (keys.includes('shift') && !event.shiftKey) {
//           return
//         }

//         if (keys.includes(event.key.toLowerCase())) {
//           event.preventDefault()
//           console.log('shoda')
//           callback()
//         }
//       }

//       if (keys[0] === event.key.toLowerCase()) {
//         event.preventDefault()
//         console.log('shoda')
//         callback()
//       }
//     }

//     document.addEventListener('keyup', handleKeyDown)

//     return () => {
//       document.removeEventListener('keyup', handleKeyDown)
//     }
//   }, [keysCombination, callback])
// }

function useKeyboardShortcut(shortcutActions) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // console.log('key: ', event.key)
      // Iterate over each shortcut and its associated action
      shortcutActions.forEach(({ keys, enabled, action }) => {
        if (!enabled) return
        const lowerCaseKeys = keys.map((key) => key.toLowerCase())
        const isMatch = lowerCaseKeys.every(
          // např. pro ctrl + shift + a => event.key is 'A', event.ctrlKey is true, event.shiftKey is true
          (key) =>
            key === event.key.toLowerCase() ||
            (key === 'ctrl' && event.ctrlKey) ||
            (key === 'cmd' && event.metaKey) ||
            (key === 'shift' && event.shiftKey)
        )

        if (isMatch) {
          event.preventDefault()
          action()
        }
      })
    }

    document.addEventListener('keyup', handleKeyDown)

    return () => {
      document.removeEventListener('keyup', handleKeyDown)
    }
  }, [shortcutActions])
}

export default useKeyboardShortcut
