// import { useState } from 'react'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list)
//   const [removed] = result.splice(startIndex, 1)
//   result.splice(endIndex, 0, removed)
//   return result
// }

// const MyComponent = () => {
//   const initialFields = [
//     { id: 'field1', content: 'Field 1', isChecked: true },
//     { id: 'field2', content: 'Field 2', isChecked: false },
//     { id: 'field3', content: 'Field 3', isChecked: true },
//   ]

//   const [fields, setFields] = useState(initialFields)

//   const onDragEnd = (result) => {
//     if (!result.destination) {
//       return
//     }
//     const newFields = reorder(
//       fields,
//       result.source.index,
//       result.destination.index
//     )
//     setFields(newFields)
//   }

//   const handleCheckboxChange = (id) => {
//     setFields(
//       fields.map((field) => {
//         if (field.id === id) {
//           return { ...field, isChecked: !field.isChecked }
//         }
//         return field
//       })
//     )
//   }

//   return (
//     <DragDropContext onDragEnd={onDragEnd}>
//       <Droppable droppableId="droppable">
//         {(provided) => (
//           <div ref={provided.innerRef} {...provided.droppableProps}>
//             {fields.map((item, index) => (
//               <Draggable key={item.id} draggableId={item.id} index={index}>
//                 {(provided) => (
//                   <div
//                     ref={provided.innerRef}
//                     {...provided.draggableProps}
//                     {...provided.dragHandleProps}
//                     style={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       marginBottom: '10px',
//                     }}
//                   >
//                     <input
//                       type="checkbox"
//                       checked={item.isChecked}
//                       onChange={() => handleCheckboxChange(item.id)}
//                       style={{ marginRight: '10px' }}
//                     />
//                     {item.content}
//                   </div>
//                 )}
//               </Draggable>
//             ))}
//             {provided.placeholder}
//           </div>
//         )}
//       </Droppable>
//     </DragDropContext>
//   )
// }

// export default MyComponent

import React, { useState } from 'react'
import { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const DraggableListItems = ({ fields, setFields }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const newFields = reorder(
      fields,
      result.source.index,
      result.destination.index
    )
    setFields(newFields)
  }

  const handleCheckboxChange = (newValue, index) => {
    setFields([...fields], (fields[index].checked = newValue))
  }

  useEffect(() => {
    console.log('fields ', fields)
  }, [fields])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map((item, index) => (
              <Draggable
                key={item.value}
                draggableId={item.value}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <input
                      type="checkbox"
                      checked={item.checked}
                      name={item.value}
                      onChange={(e) => {
                        handleCheckboxChange(e.target.checked, index)
                      }}
                    />{' '}
                    <label htmlFor={item.value}>{item.label}</label>
                    {/* {item} */}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
export default DraggableListItems
