import React from 'react'

import styled from 'styled-components'

const InputFloatingLabel = ({ label, id, value, onChange }) => {
  return (
    <InputContainer>
      <Label htmlFor={id}>{label}</Label>
      <StyledInput type="text" name={id} value={value} onChange={onChange} />
    </InputContainer>
  )
}

export default InputFloatingLabel

export const InputContainer = styled.div`
  position: relative;
  border: 1px solid var(--color-gray-light);
  border-radius: var(--border-radius-input);
  margin: 1.3rem 0;
  /* overflow: hidden;  */
`

export const Label = styled.label`
  position: absolute;
  font-size: var(--font-size-small);
  left: 0;
  top: 0;
  transform: translateY(-50%);
  color: var(--color-gray-light);
  background-color: white; // Match the background of the container
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  z-index: 10; // Ensures the label is above the border
`

export const StyledInput = styled.input`
  padding: 0.4rem 0.8rem 0.15rem 1.35rem;
  border: 0.5px solid transparent; // Transparent border
  &:focus {
    outline: none;
    border-color: var(--highlight-color); // Highlight color on focus
  }
  border-radius: 35px;
  width: 100%;
  border: 0;
`
