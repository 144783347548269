// my custom hook to fetch decknames - used in 2 places: AnkiForm, slovicka options
import { useEffect } from 'react'
import {
  deleteXCards,
  changeDeck,
  modifyXCards,
  modifyOneCard,
  getOneCard,
  getCardsToReview,
  submitCardToReview,
  searchCards,
} from '../services/fetchCards'
import {
  createDeck,
  renameDeck,
  deleteDeck,
  // getDecksWithStatusInfo,
} from '../services/fetchDecks'
import { getUser, updateUser, postOther } from '../services/fetchUser'
import { getMnemonic } from '../services/fetchFields'
import { getAnkiDecksAndNoteTypes, addNote } from '../services/fetchAnki'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchDeckNames } from '../services/fetchFields'
import { getBlogPost } from '../services/fetchBlog'

export const useDeleteXCards = (onSuccess) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([ids, allSelected]) => deleteXCards(ids, allSelected),
    onSuccess: () => {
      // toast.success('Karta smazána')
      queryClient.invalidateQueries({ queryKey: ['getAllCards'] })
      queryClient.invalidateQueries({ queryKey: ['getCardsToReview'] })
      queryClient.invalidateQueries({ queryKey: ['getDecks', true] })
      // queryClient.invalidateQueries('getAllCards')
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error('Kartu se nepodařilo smazat')
    },
  })

  return mutation
}

export const useChangeDeck = (onSuccess) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([cardsId, targetDeck, targetDeckId, allSelected]) =>
      changeDeck(cardsId, targetDeck, targetDeckId, allSelected),
    // mutationFn: (cardsId, targetDeck, targetDeckId, allSelected) =>
    //   changeDeck(cardsId, targetDeck, targetDeckId, allSelected),
    onSuccess: () => {
      toast.success('Balíček změněn')
      queryClient.invalidateQueries({ queryKey: ['getAllCards'] })
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Karty se nepodařilo přesunout`)
    },
  })

  return mutation
}

export const useModifyXCards = (onSuccess) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([cardsId, allSelected, action, additionalData]) =>
      modifyXCards(cardsId, allSelected, action, additionalData),
    // mutationFn: (cardsId, targetDeck, targetDeckId, allSelected) =>
    //   changeDeck(cardsId, targetDeck, targetDeckId, allSelected),
    onSuccess: () => {
      // toast.success('Kart(y) vyřazeny z opakování')
      queryClient.invalidateQueries({ queryKey: ['getAllCards'] })
      queryClient.invalidateQueries({ queryKey: ['getCardsToReview'] })
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

export const useModifyOneCard = (onSuccess, queriesToInvalidate = []) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([cardId, fieldsToUpdate]) =>
      modifyOneCard(cardId, fieldsToUpdate),
    onSuccess: () => {
      // toast.success('Kart(y) vyřazeny z opakování')
      console.log('query ', queriesToInvalidate)
      if (queriesToInvalidate.length > 0) {
        queriesToInvalidate.forEach((query) => {
          queryClient.invalidateQueries(query)
        })
      }
      // queryClient.invalidateQueries({ queryKey: ['getAllCards'] })
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

export const useGetOneCard = (onSuccess) => {
  // const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([cardsId]) => getOneCard(cardsId),
    onSuccess: () => {
      // toast.success('Kart(y) vyřazeny z opakování')
      // queryClient.invalidateQueries('getAllCards')
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

export const useCreateDeck = (onSuccess) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([deckName]) => createDeck(deckName),
    onSuccess: () => {
      queryClient.invalidateQueries('getAllDecks')
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

// rename deck
export const useRenameDeck = (onSuccess) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([deckName, newDeckName]) => renameDeck(deckName, newDeckName),
    onSuccess: () => {
      queryClient.invalidateQueries('getAllDecks')
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

// delete deck
// rename
export const useDeleteDeck = (onSuccess) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([deckName]) => deleteDeck(deckName),
    onSuccess: () => {
      queryClient.invalidateQueries('getAllDecks')
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

// export const useGetCardsToReview = (onSuccess, deckId = false) => {
//   const { data, isError, isSuccess } = useQuery({
//     queryFn: () => getCardsToReview(deckId),
//     queryKey: ['getCardsToReview', deckId],
//   })

//   useEffect(() => {
//     if (isError) {
//       toast.error('Něco se nepovedlo, zkuste obnovit stránku', {
//         autoClose: 3000,
//       })
//     }
//     if (isSuccess) {
//       onSuccess(data)
//     }
//   }, [isError, isSuccess])
// }

export const useGetCardsToReview = (deckId = false, learnAhead) => {
  const { data, isError, isSuccess } = useQuery({
    queryFn: () => getCardsToReview(deckId, learnAhead),
    queryKey: ['getCardsToReview', deckId],
    // staleTime: Infinity,
  })

  useEffect(() => {
    if (isError) {
      toast.error('Něco se nepovedlo, zkuste obnovit stránku', {
        autoClose: 3000,
      })
    }
  }, [isError])

  return { data, isSuccess }
}

export const useGetDecks = (statusInfo = false, enableCondition = true) => {
  console.log('status info in usevocab ', statusInfo)
  console.log('enable condition in usevocab ', enableCondition)
  const { data, isSuccess, isError, isLoading } = useQuery({
    queryFn: () => fetchDeckNames(statusInfo),
    queryKey: ['getDecks', statusInfo],
    enabled: enableCondition,
    // staleTime: 10 * 60 * 1000 // 5 min
    staleTime: Infinity, // whenever user mutates underlying data, i have to invalidate this query
  })

  useEffect(() => {
    if (isError) {
      toast.error('Nepodařilo se načíst balíčky')
    }
  }, [isError])

  return { data, isSuccess, isError, isLoading }
}

export const useSubmitCardToReview = (onSuccess) => {
  const mutation = useMutation({
    mutationFn: ([cardId, ease, nextReviewLabel]) =>
      submitCardToReview(cardId, ease, nextReviewLabel),
    onSuccess: (data) => {
      // toast.success('Kart(y) vyřazeny z opakování')
      // queryClient.invalidateQueries('getAllCards')
      onSuccess(data)
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

export const useGetUser = (userId, enableCondition = true) => {
  const { data, isSuccess, isError, isLoading } = useQuery({
    queryFn: () => getUser(userId),
    queryKey: ['getUser', userId],
    enabled: enableCondition,
    staleTime: Infinity,
  })

  return { data, isSuccess, isError, isLoading }
}

export const useUpdateUser = (onSuccess) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: ([userId, settingsToUpdate]) =>
      updateUser(userId, settingsToUpdate),
    onSuccess: (data) => {
      // toast.success('Kart(y) vyřazeny z opakování')
      queryClient.invalidateQueries('getUser')
      onSuccess(data)
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

// get notetypes (anki)
export const useGetAnkiDecksAndNoteTypes = (onSuccess) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: () => getAnkiDecksAndNoteTypes(),
    onSuccess: (data) => {
      // queryClient.invalidateQueries('getUser')
      onSuccess(data)
    },
    onError: (error) => {
      console.log(error)
      toast.error(
        `Nepodařilo se spojit s Vašim Anki účtem, ujistěte se, že Anki běží nebo zkuste obnovit stránku.`,
        {
          autoClose: 5000,
        }
      )
    },
  })

  return mutation
}

// add note (anki)
export const useAddNote = (onSuccess) => {
  // const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (note) => addNote(note),
    onSuccess: (data) => {
      // queryClient.invalidateQueries('getUser')
      onSuccess(data)
    },
    onError: (error) => {
      console.log(error)
      toast.error(
        `Něco se pokazilo. Ujistěte se, že program Anki běží (viz <a href="https://github.com/nathandev33/Dictionari">zde</a>.)`,
        {
          autoClose: 5000,
        }
      )
    },
  })

  return mutation
}

export const usePostOther = (onSuccess) => {
  // const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: ([x]) => postOther(x),
    onSuccess: () => {
      // queryClient.invalidateQueries('getAllDecks')
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(`Akce se nezdařila`)
    },
  })

  return mutation
}

// get Mnemonic

export const useGetMnemonic = (keyword, enableCondition) => {
  const { data, isSuccess, isError, isLoading } = useQuery({
    queryFn: () => getMnemonic(keyword),
    queryKey: ['getMnemonic', keyword],
    enabled: enableCondition,
    staleTime: Infinity,
  })

  return { data, isSuccess, isError, isLoading }
}

// search cards
export const useSearchCards = (q, enableCondition, onSettled) => {
  const { data, isSuccess, isError, isLoading } = useQuery({
    queryFn: () => searchCards(q),
    queryKey: ['searchCards', q],
    enabled: enableCondition,
    onSettled: (data) => {
      onSettled(data)
    },
    staleTime: Infinity,
  })

  return { data, isSuccess, isError, isLoading }
}

export const useGetBlogPost = (boxes, postId) => {
  const { data, isSuccess, isError, isLoading } = useQuery({
    queryFn: () => getBlogPost(boxes, postId),
    queryKey: ['getBlogPost', boxes, postId],
    // enabled: enableCondition,
    staleTime: Infinity,
  })

  return { data, isSuccess, isError, isLoading }
}
