import React, { useState, useRef, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import { RiThumbUpLine, RiThumbDownLine } from 'react-icons/ri'
import InputSearchForm from '/src/components/InputSearchForm'
import InputFormFloatingLabel from '/src/components/InputFormFloatingLabel'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { FaRegEdit } from 'react-icons/fa'

import InputUsingEditor from '/src/components/InputUsingEditor'

const SearchMnemonicsModalContent = ({
  customMnemonics,
  setCustomMnemonics,
  mnemonics,
  setMnemonics,
  onVote,
  onSelectMnemonic,
  setModalContent,
}) => {
  const handleMnemonicClick = (mnemonicText, event) => {
    event.stopPropagation() // Prevents the voting click from selecting the mnemonic
    onSelectMnemonic(mnemonicText)
  }

  const [customMnemonic, setCustomMnemonic] = useState('')

  const inputRef = useRef(null)

  // const handleCustomMnemonicChange = (e) => {
  //   setCustomMnemonic(e.target.value)
  // }

  // const handleCustomMnemonicChange = (newValue) => {
  //   setCustomMnemonic(newValue)
  // }

  const handleSubmit = (e) => {
    console.log('submitted')
    // e.preventDefault()
    e.stopPropagation()

    // onSelectMnemonic(customMnemonic)
    // setModalContent(null)
    const customMnemonicObject = {
      _id: uuidv4(),
      text: customMnemonic,
      custom: true,
    }
    const updatedMnemonics = [customMnemonicObject, ...mnemonics]
    // setCustomMnemonics([...customMnemonics, customMnemonic])
    console.log('custom Mnemonics ', customMnemonics) // Check what customMnemonics contains at this point

    setCustomMnemonics((currentCustomMnemonics) => [
      ...currentCustomMnemonics,
      customMnemonicObject,
    ])

    console.log('updatedMnemonics ', updatedMnemonics)
    setMnemonics(updatedMnemonics)
    setCustomMnemonic('')
  }

  const handleDeleteCustomMnemonic = (mnemonicId, event) => {
    event.stopPropagation() // Prevents the voting click from selecting the mnemonic
    console.log('jsem v handledeletecustom')
    console.log('mnemonicid ', mnemonicId)
    console.log('customMnemonics ', customMnemonics)
    setCustomMnemonics(
      [...customMnemonics].filter((mnemonic) => mnemonic._id !== mnemonicId)
    )

    setMnemonics((currentMnemonics) =>
      currentMnemonics.filter((mnemonic) => mnemonic._id !== mnemonicId)
    )
  }

  const handleEditCustomMnemonic = (mnemonicId, event) => {
    event.stopPropagation() // Prevents the voting click from selecting the mnemonic
    console.log('mnemonicid ', mnemonicId)
    console.log('customMnemonics ', customMnemonics)
    const mnemonicToEdit = customMnemonics.find(
      (mnemonic) => mnemonic._id === mnemonicId
    )
    console.log('mnemonicToEdit ', mnemonicToEdit)
    setCustomMnemonic(mnemonicToEdit.text)
    // setMnemonics((currentMnemonics) =>
    //   currentMnemonics.filter((mnemonic) => mnemonic._id !== mnemonicId)
    // )

    // smazat z mnemonics so it's treated as new mnemonic
    handleDeleteCustomMnemonic(mnemonicId, event)
    // inputRef.current.focus()
  }

  return (
    <MnemonicsContainer>
      {/* <InputFormFloatingLabel
        label="přidat vlastní"
        id="addCustomMnemonicInput"
        value={customMnemonic}
        onChange={handleCustomMnemonicChange}
        onSubmit={handleSubmit}
        inputRef={inputRef}
        submitButtonType="button"
      /> */}

      <InputUsingEditor
        id="addCustomMnemonicInput"
        label="přidat vlastní"
        value={customMnemonic}
        // handleInputChange={handleCustomMnemonicChange}
        onSubmit={handleSubmit}
        setCustomMnemonic={setCustomMnemonic}
        setModalContent={setModalContent}
        // inputRef={inputRef}
        // submitButtonType="button"
      />
      <br />

      {mnemonics.map((mnemonic, index) => (
        <MnemonicItem
          key={mnemonic._id}
          $isEven={index % 2 === 0}
          onClick={(event) => handleMnemonicClick(mnemonic.text, event)}
          // tabIndex={0} // Make it focusable
        >
          <MnemonicText dangerouslySetInnerHTML={{ __html: mnemonic.text }} />
          <VoteButtons>
            {mnemonic.custom ? (
              <>
                <DeleteButton
                  onClick={(event) =>
                    handleEditCustomMnemonic(mnemonic._id, event)
                  }
                >
                  <FaRegEdit />
                </DeleteButton>

                <DeleteButton
                  onClick={(event) =>
                    handleDeleteCustomMnemonic(mnemonic._id, event)
                  }
                >
                  <RiDeleteBin6Line />
                </DeleteButton>
              </>
            ) : (
              <>
                <VoteButton
                  onClick={(event) => onVote(mnemonic.id, 'up', event)}
                >
                  <RiThumbUpLine />
                </VoteButton>
                <VoteButton
                  onClick={(event) => onVote(mnemonic.id, 'down', event)}
                >
                  <RiThumbDownLine />
                </VoteButton>
              </>
            )}
          </VoteButtons>
        </MnemonicItem>
      ))}
    </MnemonicsContainer>
  )
}

// Styled components
const MnemonicsContainer = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 500px;
  padding-top: 1rem;
  overflow-y: auto;
`

const MnemonicItem = styled.li`
  background-color: ${(props) => (props.$isEven ? '#fff' : '#f7f7f7')};
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: #e0e0e0;
    outline: none;
  }
`

const MnemonicText = styled.div`
  flex-grow: 1;
`

const VoteButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

const VoteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 1.2em;
  margin-left: 10px;

  &:hover {
    color: #007bff;
  }
`

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 1.2em;
  margin-left: 10px;

  &:hover {
    color: var(--color-red);
  }
`

export default SearchMnemonicsModalContent
