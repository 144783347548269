import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const WithPrivateRoute = ({ children }) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  useEffect(() => {
    console.log(currentUser)
    if (!currentUser?.emailVerified) {
      console.log('redirect na /prihlasit')
      navigate('/prihlasit')
    }
  }, [currentUser, navigate])

  return currentUser?.emailVerified ? children : null
}

export default WithPrivateRoute
