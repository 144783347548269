import PanelSettingsButton from '/src/components/PanelSettingsButton'
import { RiCloseLine } from 'react-icons/ri'

const PanelSettingsContainer = ({
  children,
  handleCloseSettingsOverlay,
  handleOpenSettingsOverlay,
  showSettingsOverlay,
}) => {
  return (
    <div>
      {!showSettingsOverlay && (
        <PanelSettingsButton
          handleOpenSettingsOverlay={handleOpenSettingsOverlay}
        />
      )}
      {showSettingsOverlay && (
        <div className="panel-settings__overlay">
          {children && children}

          <button onClick={handleCloseSettingsOverlay}>
            <RiCloseLine />
          </button>
        </div>
      )}
    </div>
  )
}

export default PanelSettingsContainer
