import { useState } from 'react'

export const noteTypeSelect_ = [
  { label: 'EN-CZ (1 karta)', value: 'englishToCzech', _id: 1 },
  { label: 'CZ-EN (1 karta)', value: 'czechToEnglish', _id: 2 },
  { label: 'oboustranná (2 karty)', value: 'both', _id: 3 },
]

export const useFormData = () => {
  const [formData, setFormData] = useState({
    image: '',
    cz: '',
    en: '',
    definition: '',
    example: '',
    mnemonic: '',
    extra: '',
    deckName: '',
    deckId: '',
    noteType: '',
  })

  // Any other logic related to formData

  return [formData, setFormData]
}

export const initialStateFormData = {
  image: '',
  cz: '',
  en: '',
  definition: '',
  example: '',
  mnemonic: '',
  extra: '',
  deckName: '',
  deckId: '',
  noteType: '',
}
