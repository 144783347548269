import { useState } from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'

import ResizeHandle from './ResizeHandle.jsx'
import styles from './resizable-panels.module.css'
import Middle from './Middle/Middle.jsx'
import Left from './Left.jsx'
import Right from './Right/Right.jsx'
import './Learn.css'
import { BsFillChatRightDotsFill } from 'react-icons/bs'
import Chat from './Chat/Chat.jsx'
import { KeywordProvider } from '../../contexts/KeywordContext.jsx'
import { useEffect } from 'react'
import { useActivePanels } from '../../contexts/ActivePanels.jsx'
import { toast } from 'react-toastify'

export default function App() {
  const { activePanels, setActivePanels } = useActivePanels()
  const [showChat, setShowChat] = useState(false)
  const [czShort, setCzShort] = useState('moudrý, rozumný (o člověku)')

  const handleChatIconClick = () => {
    setShowChat(true)
    setTimeout(() => {
      document.querySelector('.chat__input').focus()
      window.scrollTo(0, document.body.scrollHeight)
    }, 10)
  }

  // log active panels on change
  useEffect(() => {
    // console.log('active panels ', activePanels)
  }, [activePanels])

  //  on page load scroll down:
  // useEffect(() => {
  // window.scrollTo(0, document.body.scrollHeight)
  // }, [])

  const handlePanelResize = (panelId, newSize, czDict_size, gallery_size) => {
    // console.log(`Panel ${panelId} resized to ${newSize}`)

    if (newSize === 0) {
      if (panelId === 'gallery') {
        setActivePanels({ ...activePanels, gallery: false })
      } else if (panelId === 'czDict') {
        setActivePanels({ ...activePanels, czDict: false })
      } else if (panelId === 'ankiForm') {
        setActivePanels({ ...activePanels, ankiForm: false })
      } else if (panelId === 'left') {
        setActivePanels({ ...activePanels, left: false })
      } else if (panelId === 'right') {
        setActivePanels({ ...activePanels, czDict: false, gallery: false })
      }
    } else if (newSize > 0) {
      if (panelId === 'gallery' && !activePanels.gallery) {
        setActivePanels({ ...activePanels, gallery: true })
      } else if (panelId === 'czDict') {
        if (!activePanels.czDict) {
          setActivePanels({ ...activePanels, czDict: true })
        }
      } else if (panelId === 'ankiForm' && !activePanels.ankiForm) {
        setActivePanels({ ...activePanels, ankiForm: true })
      } else if (panelId === 'left' && !activePanels.left) {
        setActivePanels({ ...activePanels, left: true })
      } else if (panelId === 'right') {
        setActivePanels({
          ...activePanels,
          czDict: czDict_size > 0 ? true : false,
          gallery: gallery_size > 0 ? true : false,
        }) // 0 -> false; > 0 -> true
      }
    }
    // Handle the resize event...
  }

  const handleLeftPanelResize = (size) => {
    handlePanelResize('left', size)
  }

  const [messages, setMessages] = useState([]) // pro jejich displaying
  const [messagesHistory, setMessagesHistory] = useState([]) // holding data i pro openai api
  return (
    <KeywordProvider>
      <div className={styles.Container}>
        {/* chat bot */}
        <button
          type="button"
          className="chat__open-icon"
          onClick={() => {
            handleChatIconClick()
          }}
        >
          <BsFillChatRightDotsFill />
        </button>
        {showChat && (
          <div className="chat">
            <Chat
              setShowChat={setShowChat}
              messages={messages}
              setMessages={setMessages}
              messagesHistory={messagesHistory}
              setMessagesHistory={setMessagesHistory}
            />
          </div>
        )}
        {/* <div className={styles.TopRow}>top row</div> */}
        <div className={styles.BottomRow}>
          <PanelGroup autoSaveId="main" direction="horizontal">
            {/* <> */}
            {/* left */}
            <Panel
              className={styles.Panel}
              collapsible={true}
              order={1}
              defaultSize={38}
              onResize={handleLeftPanelResize}
              id={'left'}
            >
              <div className={styles.PanelContent}>
                {activePanels.left && <Left />}
              </div>
            </Panel>
            <ResizeHandle direction={'horizontal'} />
            {/* </> */}

            {/* middle */}
            <Middle
              handlePanelResize={handlePanelResize}
              czShort={czShort}
              setCzShort={setCzShort}
              showChat={showChat}
            />

            {/* {showLastPanel && (
            <> */}
            <ResizeHandle direction={'horizontal'} />
            {/* right */}
            <Right
              handlePanelResize={handlePanelResize}
              setCzShort={setCzShort}
            />

            {/* </>
          )} */}
          </PanelGroup>
        </div>
      </div>
    </KeywordProvider>
  )
}
