import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import { Link as RouterLink, NavLink, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

// Components
import Sidebar from '../Nav/Sidebar'
import Backdrop from '../Elements/Backdrop'
// Assets
import LogoIcon from '/public/assets/svg/Logo'
import BurgerIcon from '/public/assets/svg/BurgerIcon'
import ProfileButton from '/src/components/ProfileButton'

// login logic
import Modal from '/src/components/10_utils/Modal'
import { useModal } from '/src/contexts/ModalContext'
import HomeModalContent from '/src/pages/Home/HomeModalContent'
import { useAuth } from '/src/contexts/AuthContext'

export default function TopNavbar() {
  const navLinks_app = [
    {
      linkTo: '/uceni',
      label: 'Učení',
      id: 1,
    },
    {
      linkTo: '/opakovani',
      label: 'Opakování',
      id: 2,
    },
    {
      linkTo: '/slovnicek',
      label: 'Slovníček',
      id: 3,
    },
  ]

  const navLinks_webpage = [
    {
      linkTo: 'recenze',
      label: 'Recenze',
      id: 1,
    },
    {
      linkTo: 'o-aplikaci',
      label: 'O aplikaci',
      id: 2,
    },
    {
      linkTo: 'cena',
      label: 'Cena',
      id: 3,
    },
    {
      linkTo: 'faq',
      label: 'Faq',
      id: 4,
    },
  ]

  const navLinks_webpage_notHome = [
    {
      linkTo: '/',
      label: 'Home',
      id: 1,
    },
    {
      linkTo: '/blog',
      label: 'Blog',
      id: 2,
    },
    {
      linkTo: '/kontakt',
      label: 'Kontakt',
      id: 3,
    },
  ]

  const location = useLocation()
  const positionFixed =
    location.pathname === '/' ||
    location.pathname === '/blog' ||
    location.pathname === '/kontakt'

  const navLinks_userLoggedIn = navLinks_app.map((link) => {
    return (
      <li className="semiBold font15 pointer" key={link.id}>
        <NavLink
          onClick={(e) => {
            if (!currentUser?.emailVerified) {
              e.preventDefault()
            }
          }}
          style={{ padding: '10px 15px', color: 'var(--color-black)' }}
          to={link.linkTo}
          className={({ isActive }) => (isActive ? 'active-link' : undefined)}
        >
          {link.label}
        </NavLink>
      </li>
    )
  })

  // if (location.pathname === '/' || location.pathname === '/blog') {
  const navLinks_userLoggedOut = navLinks_webpage.map((link) => {
    return (
      <li className="semiBold font15 pointer" key={link.id}>
        <Link
          // activeClass="active"
          style={{ padding: '10px 15px', color: 'var(--color-theme-2)' }}
          to={link.linkTo}
          spy={true}
          smooth={true}
          offset={-80}
        >
          {link.label}
        </Link>
      </li>
    )
  })

  // }

  const navLinks_userLoggedOut_notHome = navLinks_webpage_notHome.map(
    (link) => {
      return (
        <li className="semiBold font15 pointer" key={link.id}>
          <NavLink
            // onClick={(e) => {
            //   if (!currentUser) {
            //     e.preventDefault()
            //   }
            // }}
            style={{ padding: '10px 15px', color: 'var(--color-black)' }}
            to={link.linkTo}
            className={({ isActive }) => (isActive ? 'active-link' : undefined)}
          >
            {link.label}
          </NavLink>
        </li>
      )
    }
  )

  const rightButton_userLoggedIn = <ProfileButton />

  const rightButtons_userLoggedOut = (
    <>
      <li className="semiBold font15 pointer">
        <button
          style={{
            padding: '10px 30px 10px 0',
            color: 'var(--color-theme-2)',
          }}
          onClick={() => {
            handleAccessAppClick(true)
            setLogin(true)
          }}
        >
          Přihlásit se
        </button>
      </li>

      <li className="semiBold font15 pointer flexCenter">
        <button
          href="/"
          className="radius8 lightBg"
          style={{
            padding: '10px 15px',
            backgroundColor: 'var(--color-theme-1)',
            color: 'var(--color-white)',
          }}
          onClick={() => {
            handleAccessAppClick(false)
            setLogin(false)
          }}
        >
          Zkusit zdarma
        </button>
      </li>
    </>
  )

  const [y, setY] = useState(window.scrollY)
  const [sidebarOpen, toggleSidebar] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => setY(window.scrollY))
    return () => {
      window.removeEventListener('scroll', () => setY(window.scrollY))
    }
  }, [y])

  // signup logic
  const navigate = useNavigate()
  const { modalContent, setModalContent } = useModal()
  const { currentUser, register, setError } = useAuth()
  const [login, setLogin] = useState(false)

  useEffect(() => {
    console.log('currentUser v topnav ', currentUser)
  }, [currentUser])

  useEffect(() => {
    console.log('login v topnav ', login)
  }, [login])

  function handleAccessAppClick(loginButton) {
    console.log('login button ', loginButton)
    setModalContent('accessApp')
    // if (loginButton) {
    //   setLogin(true)
    // } else {
    //   setLogin(false)
    // }
  }

  // useEffect(() => {
  //   if (currentUser?.emailVerified) {
  //     navigate('/opakovani')
  //   }
  // }, [currentUser, navigate])

  // if login true => header = přihlášení

  // useEffect(() => {
  //   console.log('login v topnav ', login)
  // }, [login])

  const loginText = {
    header: 'Vítejte zpět',
    action: 'přihlášení',
    alternativeText: 'Ještě nemáte účet?',
    alternativeLink: 'Vytvořit nový',
    alternativeOptional: 'Obnovit zapomenuté heslo',
  }

  // if registration true => header = přidejt se atd.
  const registrationText = {
    header: 'Přidejte se',
    action: 'registrace',
    alternativeText: 'Už máte účet?',
    alternativeLink: 'Přihlásit se',
    alternativeOptional: '',
  }

  return (
    <>
      {modalContent === 'accessApp' && (
        <Modal onlyCloseButtonClosesIt={true}>
          {login ? (
            <HomeModalContent
              text={loginText}
              setLogin={setLogin}
              login={login}
              setModalContent={setModalContent}
            />
          ) : (
            <HomeModalContent
              text={registrationText}
              setLogin={setLogin}
              login={login}
              setModalContent={setModalContent}
            />
          )}
        </Modal>
      )}

      {/* {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />} */}
      {sidebarOpen && (
        <>
          <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
          {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
        </>
      )}
      <Wrapper
        className="flexCenter animate whiteBg"
        // style={y > 100 ? { height: '60px' } : { height: '80px' }}
        style={{
          height: '60px',
        }}
        $positionFixed={positionFixed}
      >
        <NavInner className="container flexSpaceCenter">
          {/* <Link className="pointer flexNullCenter" to="home" smooth={true}> */}
          <RouterLink to="/" className="pointer flexNullCenter">
            <LogoIcon />
            <h1
              style={{ marginLeft: '15px', color: 'var(--color-theme-1)' }}
              className="font20 extraBold"
            >
              Angličtinomat
            </h1>
          </RouterLink>
          {/* </Link> */}
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            {/* <li className="semiBold font15 pointer">
              <Link
                activeClass="active"
                style={{ padding: '10px 15px' }}
                to="home"
                spy={true}
                smooth={true}
                offset={-80}
              >
                Home
              </Link>
            </li> */}

            {currentUser?.emailVerified
              ? navLinks_userLoggedIn
              : location.pathname === '/'
              ? navLinks_userLoggedOut
              : navLinks_userLoggedOut_notHome}
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            {currentUser?.emailVerified
              ? rightButton_userLoggedIn
              : rightButtons_userLoggedOut}
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.nav`
  width: 100%;
  position: ${(props) => (props.$positionFixed ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  z-index: 999;
`

const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`
