// Assets

import CloseIcon from '/public/assets/svg/CloseIcon'
import HomeModalContent from '/src/pages/Home/HomeModalContent'
import LogoIcon from '/public/assets/svg/Logo'
import Modal from '/src/components/10_utils/Modal'
import { useModal } from '/src/contexts/ModalContext'

import { IoSettingsSharp } from 'react-icons/io5'
import { IoHelpCircleOutline } from 'react-icons/io5'
import { IoLogOutOutline } from 'react-icons/io5'

import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useAuth } from '/src/contexts/AuthContext'
import ProfileButton from '/src/components/ProfileButton'

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const { currentUser, register, setError, logout } = useAuth()
  const navigate = useNavigate()
  const navLinks_app = [
    {
      linkTo: '/uceni',
      label: 'Učení',
      id: 1,
    },
    {
      linkTo: '/opakovani',
      label: 'Opakování',
      id: 2,
    },
    {
      linkTo: '/slovnicek',
      label: 'Slovníček',
      id: 3,
    },
  ]

  const navLinks_webpage_notHome = [
    {
      linkTo: '/',
      label: 'Home',
      id: 1,
    },
    {
      linkTo: '/blog',
      label: 'Blog',
      id: 2,
    },
    {
      linkTo: '/kontakt',
      label: 'Kontakt',
      id: 3,
    },
  ]

  const navLinks_webpage = [
    {
      linkTo: 'recenze',
      label: 'Recenze',
      id: 1,
    },
    {
      linkTo: 'o-aplikaci',
      label: 'O aplikaci',
      id: 2,
    },
    {
      linkTo: 'cena',
      label: 'Cena',
      id: 3,
    },
    {
      linkTo: 'faq',
      label: 'Faq',
      id: 4,
    },
  ]

  const location = useLocation()
  const positionFixed =
    location.pathname === '/' ||
    location.pathname === '/blog' ||
    location.pathname === '/kontakt'

  let profile_links = [
    {
      linkTo: 'nastaveni',
      label: 'Nastavení',
      id: 1,
    },
    {
      linkTo: 'napoveda',
      label: 'Nápověda',
      id: 2,
    },
    {
      linkTo: 'prihlasit',
      label: 'Odhlásit se',
      id: 3,
    },
  ]
  let rightButtons_userLoggedIn = profile_links.map((link) => (
    <li
      className="semiBold font15 pointer"
      style={{ color: 'white !important' }}
      key={link.id}
    >
      <RouterLink
        onClick={() => {
          if (link.linkTo === 'prihlasit') {
            handleLogout()
          } else {
            toggleSidebar(!sidebarOpen)
          }
        }}
        // activeClass="active"
        className="whiteColor"
        style={{ padding: '10px 15px', color: 'white' }}
        to={link.linkTo}
      >
        {link.label}
      </RouterLink>
    </li>
  ))

  let navLinks_userLoggedIn = navLinks_app.map((link) => {
    return (
      <li
        className="semiBold font15 pointer"
        style={{ color: 'white !important' }}
        key={link.id}
      >
        <RouterLink
          onClick={(e) => {
            if (!currentUser?.emailVerified) e.preventDefault()
            toggleSidebar(!sidebarOpen)
          }}
          // activeClass="active"
          className="whiteColor"
          style={{ padding: '10px 15px', color: 'white' }}
          to={link.linkTo}
        >
          {link.label}
        </RouterLink>
      </li>
    )
  })

  // if (location.pathname === '/' || location.pathname === '/blog') {
  const navLinks_userLoggedOut = navLinks_webpage.map((link) => {
    return (
      <li className="semiBold font15 pointer" key={link.id}>
        <Link
          // activeClass="active"
          style={{ padding: '10px 15px' }}
          to={link.linkTo}
          spy={true}
          smooth={true}
          offset={-80}
          onClick={() => toggleSidebar(!sidebarOpen)}
        >
          {link.label}
        </Link>
      </li>
    )
  })
  // }

  let navLinks_userLoggedOut_notHome = navLinks_webpage_notHome.map((link) => {
    return (
      <li
        className="semiBold font15 pointer"
        style={{ color: 'white !important' }}
        key={link.id}
      >
        <RouterLink
          // onClick={(e) => {
          //   if (!currentUser) e.preventDefault()
          //   toggleSidebar(!sidebarOpen)
          // }}
          // activeClass="active"
          className="whiteColor"
          style={{ padding: '10px 15px', color: 'white' }}
          to={link.linkTo}
        >
          {link.label}
        </RouterLink>
      </li>
    )
  })

  let secondPart = rightButtons_userLoggedIn

  const [login, setLogin] = useState(false)
  const { modalContent, setModalContent } = useModal()
  function handleAccessAppClick(loginButton) {
    setModalContent('accessApp')
    if (currentUser?.emailVerified) {
      setModalContent(null)
      navigate('/opakovani')
      return
    } else {
      if (loginButton) {
        toggleSidebar(!sidebarOpen)
        setLogin(true)
      } else {
        toggleSidebar(!sidebarOpen)
        setLogin(false)
      }
    }
  }
  // let rightButtons = {
  //   first: null,
  //   second: <ProfileButton />,
  // }
  // if (location.pathname === '/' || location.pathname === '/blog') {
  const rightButtons_userLoggedOut = (
    <>
      <li className="semiBold font15 pointer">
        <button
          // href="/"
          style={{ padding: '10px 30px 10px 0' }}
          className="whiteColor"
          onClick={() => {
            handleAccessAppClick(true)
          }}
        >
          Přihlásit se
        </button>
      </li>
      <li className="semiBold font15 pointer flexCenter">
        <button
          href="/"
          className="radius8 lightBg"
          style={{ padding: '10px 15px', color: 'var(--color-theme-2)' }}
          onClick={() => {
            handleAccessAppClick(false)
          }}
        >
          Zkusit zdarma
        </button>
      </li>
    </>
  )
  // }

  async function handleLogout() {
    try {
      await logout()
      navigate('/prihlasit')
    } catch (error) {
      console.log(error)
    }
  }

  const loginText = {
    header: 'Vítejte zpět',
    action: 'přihlášení',
    alternativeText: 'Ještě nemáte účet?',
    alternativeLink: 'Vytvořit nový',
    alternativeOptional: 'Obnovit zapomenuté heslo',
  }

  // if registration true => header = přidejt se atd.
  const registrationText = {
    header: 'Přidejte se',
    action: 'registrace',
    alternativeText: 'Už máte účet?',
    alternativeLink: 'Přihlásit se',
    alternativeOptional: '',
  }

  return (
    <>
      {modalContent === 'accessApp' && (
        <Modal onlyCloseButtonClosesIt={true}>
          {login ? (
            <HomeModalContent
              text={loginText}
              setLogin={setLogin}
              login={login}
            />
          ) : (
            <HomeModalContent
              text={registrationText}
              setLogin={setLogin}
              login={login}
            />
          )}
        </Modal>
      )}

      <Wrapper className="animate darkBg" $sidebarOpen={sidebarOpen}>
        <SidebarHeader className="flexSpaceCenter">
          <div className="flexNullCenter">
            <LogoIcon />
            <h1 className="whiteColor font20" style={{ marginLeft: '15px' }}>
              Angličtinomat
            </h1>
          </div>
          <CloseBtn
            onClick={() => toggleSidebar(!sidebarOpen)}
            className="animate pointer"
          >
            <CloseIcon />
          </CloseBtn>
        </SidebarHeader>

        <UlStyle className="flexNullCenter flexColumn">
          {currentUser?.emailVerified
            ? navLinks_userLoggedIn
            : location.pathname === '/'
            ? navLinks_userLoggedOut
            : navLinks_userLoggedOut_notHome}
        </UlStyle>
        <UlStyle
          className={`flexSpaceCenter ${
            currentUser?.emailVerified ? 'flexColumn' : ''
          }`}
        >
          {currentUser?.emailVerified
            ? rightButtons_userLoggedIn
            : rightButtons_userLoggedOut}
          {/* {currentUser && secondPart} */}
        </UlStyle>
      </Wrapper>
    </>
  )
}

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-black);
  padding: 10px 15px;
  &:hover {
    background-color: #f5f5f5;
  }
  &:first-child(2) {
    font-size: 1.25rem;
  }
`

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.$sidebarOpen ? '0px' : '-400px')};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`
const SidebarHeader = styled.div`
  padding: 20px 0;
`
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`
