import React, { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { getAIchatAnswer } from '/src/services/fetchChat'
import ReactDOM from 'react-dom'

import Header from './Header'
import BotMessage from './BotMessage'
import UserMessage from './UserMessage'
import Messages from './Messages'
import Input from './Input'

import API from './ChatbotAPI'

import './styles.css'

export default function Chatbot({
  setShowChat,
  messages,
  setMessages,
  messagesHistory,
  setMessagesHistory,
}) {
  const [sessionUsedTokens, setSessionUsedTokens] = useState(0)
  const [promptSubmitted, setPromptSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [botMessage, setBotMessage] = useState('')

  const AIchatContentRef = useRef(null)
  useEffect(() => {
    console.log('messages ', messages)
  }, [messages])

  useEffect(() => {
    console.log('messagesHistory ', messagesHistory)
  }, [messagesHistory])

  useEffect(() => {
    console.log('sessionUsedTokens ', sessionUsedTokens)
  }, [sessionUsedTokens])

  useEffect(() => {
    console.log('botMessage useeff ', botMessage)
  }),
    [botMessage]

  const send = async (text) => {
    try {
      setIsLoading(true)
      const userMessageId = Date.now() // Unique ID for user message
      const botMessageId = userMessageId + 1 // Unique ID for bot message (loading spinner and actual response)

      // Update messages to include user message immediately
      // setMessages((prevMessages) => [
      //   ...prevMessages,
      //   <UserMessage key={prevMessages.length + 1} text={text} />,
      // ])

      setMessages((prevMessages) => [
        ...prevMessages,
        <UserMessage key={userMessageId} text={text} />,
        <BotMessage key={botMessageId} isLoading={true} />,
      ])

      // 		- [ ] if previousMessages_usedTokens > 3500, před odesláním zmenšit previousMessages, aby se vešlo do 3500
      let previousMessages_copy = messagesHistory
      let sessionUsedTokens_copy = sessionUsedTokens

      if (sessionUsedTokens > 3500) {
        // tady bych měl dát sessionusedtokens + getnumberoftokens(text)
        previousMessages_copy = JSON.parse(JSON.stringify(messagesHistory)) // Deep copy

        while (sessionUsedTokens_copy > 3500 && messagesHistory.length > 0) {
          const removedMessage = previousMessages_copy.shift() // Remove the first element
          sessionUsedTokens_copy -= removedMessage.tokens // Subtract its token count
        }
      }

      // const response = await fetchMessage()
      const response = await getAIchatAnswer(
        // newUserPrompt,
        // messagesHistory,
        // sessionUsedTokens
        text,
        previousMessages_copy,
        sessionUsedTokens_copy
      )

      if (response) {
        console.log('response ze serveru ', response)
        setBotMessage(response.completion)
        console.log('botMessage if ', botMessage)
        // setIsLoading(false)
        // setMessagesHistory((messagesHistory) => [
        setMessagesHistory([
          ...previousMessages_copy,
          {
            role: 'user',
            content: text,
            tokens: response.usedTokens.prompt_tokens,
          },
          {
            role: 'assistant',
            content: response.completion,
            tokens: response.usedTokens.completion_tokens,
          },
        ])
        setSessionUsedTokens(response.usedTokens.total_tokens)

        // other
        // const newMessages = messages.concat(
        //   <UserMessage key={messages.length + 1} text={text} />,
        //   <BotMessage
        //     key={messages.length + 2}
        //     // fetchMessage={async () =>
        //     //   await getAIchatAnswer(text, messagesHistory, sessionUsedTokens)
        //     // }
        //     newUserPrompt={text}
        //     setMessagesHistory={setMessagesHistory}
        //     setSessionUsedTokens={setSessionUsedTokens}
        //     messagesHistory={messagesHistory}
        //     sessionUsedTokens={sessionUsedTokens}
        //     messages={messages}
        //     promptSubmitted={promptSubmitted}
        //     setPromptSubmitted={setPromptSubmitted}
        //     isLoading={isLoading}
        //     setIsLoading={setIsLoading}
        //     botMessage={response.completion}
        //     setBotMessage={setBotMessage}
        //   />
        // )
        // setMessages(newMessages)

        // setMessages((prevMessages) => [
        //   ...prevMessages,
        //   <BotMessage
        //     key={prevMessages.length + 1}
        //     botMessage={response.completion}
        //     isLoading={isLoading}
        //   />,
        // ])
        setMessages((prevMessages) => {
          // Clone the current messages
          let updatedMessages = [...prevMessages]

          // Replace the last element (loading spinner) with actual bot message
          updatedMessages[updatedMessages.length - 1] = (
            <BotMessage
              key={botMessageId}
              botMessage={response.completion}
              isLoading={false}
            />
          )

          return updatedMessages
        })
        // setIsLoading(false)
      }
    } catch (error) {
      if (error.response?.message === 'no tokens')
        toast.error(
          'Vaše tokeny pro chatbota jsou vyčerpány, obnoví se zase první den nového měsíce.'
        )
      else console.log(error.message)
    }
  }

  useEffect(() => {
    // scroll down on message change (submit / new AIchat message)
    setTimeout(() => {
      if (AIchatContentRef.current) {
        AIchatContentRef.current.scrollTop =
          AIchatContentRef.current.scrollHeight
      }
    }, 0)
  }, [messages])

  const handleCleanChat = () => {
    setMessages([])
    setMessagesHistory([])
    setSessionUsedTokens(0)
  }

  return (
    <div className="chatbot">
      <Header setShowChat={setShowChat} />
      <Messages messages={messages} AIchatContentRef={AIchatContentRef} />
      <Input
        onSend={send}
        onCleanChat={handleCleanChat}
        promptSubmitted={promptSubmitted}
        setPromptSubmitted={setPromptSubmitted}
      />
    </div>
  )
}
