import { axiosBearer } from './axiosInstances.js'

export async function getCardNoteTypes_anki() {
  const response = await axiosBearer.post(`http://127.0.0.1:8765`, {
    action: 'modelNames',
    version: 6,
  })

  return response.data
}

export async function getDeckNames_anki() {
  const response = await axiosBearer.post('http://127.0.0.1:8765', {
    action: 'deckNames',
    version: 6,
  })

  return response.data
}

export async function getAnkiDecksAndNoteTypes() {
  // Create promises for each request
  const cardNoteTypesPromise = axiosBearer.post('http://127.0.0.1:8765', {
    action: 'modelNames',
    version: 6,
  })

  const deckNamesPromise = axiosBearer.post('http://127.0.0.1:8765', {
    action: 'deckNames',
    version: 6,
  })

  // Wait for all promises to resolve
  const [cardNoteTypesResponse, deckNamesResponse] = await Promise.all([
    cardNoteTypesPromise,
    deckNamesPromise,
  ])

  const result = {
    ankiNoteTypes: cardNoteTypesResponse.data,
    ankiDeckNames: deckNamesResponse.data,
  }

  console.log('hovno')
  console.log('spojené ', result)
  // Combine and return the results
  return result
}

export async function addNote(note) {
  const response = await axiosBearer.post('http://127.0.0.1:8765', note)

  return response.data
}
