import { toast } from 'react-toastify'

export const handleRequestError = (error) => {
  console.log('got error: ', error)
  if (error?.response?.status === 429) {
    toast.error(error.response.data, {
      autoClose: 4000,
    })
  } else if (error?.response?.data?.userMessage) {
    console.log('jsem zde ')
    toast.error(error.response.data.userMessage, {
      autoClose: 4000,
    })
  } else toast.error('Něco se pokazilo, zkuste to prosím znovu')
}
