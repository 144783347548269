import React, { createContext, useContext, useState, useEffect } from 'react'
import { useAuth } from '/src/contexts/AuthContext'
import { useGetUser, useUpdateUser } from '/src/hooks/useVocabAPI'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const { currentUser, register, setError } = useAuth()
  const { data, isSuccess, isError, isLoading } = useGetUser(
    currentUser?.uid,
    !!currentUser?.uid
    // false
  )

  // console.log(' data ', data)
  useEffect(() => {
    if (isSuccess) {
      setUser(data)
      // console.log('we got user data ', data)
    }
  }, [isSuccess, data])

  const updateUserMutation = useUpdateUser((updatedUserData) => {
    console.log('User updated', updatedUserData)
    setUser(updatedUserData.updatedUser) // Update the user data in the context
  })

  const updateUser = (userId, settingsToUpdate) => {
    updateUserMutation.mutate([userId, settingsToUpdate])
  }

  const value = {
    user,
    setUser,
    updateUser,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
