import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '/src/contexts/AuthContext'
import {
  getRidOfWrappingP,
  getUrlsArrayFromImgsString,
  transliterate,
  sanitizeFilename,
} from '/src/utils/formatString.js'
import AnkiFormRow from './AnkiFormRow'
import AnkiFormSelect from './AnkiFormSelect'
import Button from '../../../components/10_utils/Button'
import { useKeyword } from '../../../contexts/KeywordContext.jsx'
import { useCheckedImages } from '../../../contexts/CheckedImagesContext'
import {
  fetchDeckNames,
  fetchDefinitionAndExample,
  getMnemonic,
} from '../../../services/fetchFields'

import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'
import AnkiFormRowImage from './AnkiFormRowImage'
import { axiosBearer } from '../../../services/axiosInstances'
import PanelSettingsContainer from '../PanelSettingsContainer'
import usePanelSettings from '../usePanelSettings'
import { toast } from 'react-toastify'
import useKeyboardShortcut from '/src/hooks/useKeyboardShortcut'
import { useQueryDeckNames } from '../../../hooks/useQueryDecknames'
import Select from '/src/components/Select'
import {
  useGetUser,
  useGetAnkiDecksAndNoteTypes,
  useAddNote,
  useGetMnemonic,
} from '/src/hooks/useVocabAPI'
import { initialStateFormData } from './AnkiForm_helpers'

function AnkiForm({
  czShort,
  // setCzShort = { setCzShort },

  ankiFormRef,
  definition,
  setDefinition,
  example,
  setExample,
  mnemonic,
  setMnemonic,
  resetAnkiForm,
  ankiFormRowImageRef,
  // ankiFormRowEnRef,
  clearAnkiInputs,
  setClearAnkiInputs,
}) {
  const { keyword, previousKeyword, originalCzInput } = useKeyword()
  const [deckNames, setDeckNames] = useState([{ value: '', _id: 1 }])
  const [saveToAnki, setSaveToAnki] = useState(false)
  const [noteType_anki, setNoteType_anki] = useState([])
  const [mnemonics, setMnemonics] = useState([])
  const [deckNames_anki, setDeckNames_anki] = useState([])
  const editorRef = useRef(null)
  const { checkedImages, setCheckedImages } = useCheckedImages()
  const saveToApp = useRef(null)
  const { currentUser } = useAuth()
  const [fielsVisibility, setFieldsVisibility] = useState(() => {
    if (localStorage.getItem('fieldsVisibility')) {
      return JSON.parse(localStorage.getItem('fieldsVisibility'))
    } else {
      return {
        cz: true,
        en: true,
        definition: true,
        example: true,
        mnemonic: true,
        image: true,
        extra: true,
        deckName: true,

        noteType: true,
      }
    }
  })

  const {
    handleOpenSettingsOverlay,
    handleCloseSettingsOverlay,
    showSettingsOverlay,
  } = usePanelSettings()

  const [formData, setFormData] = useState(initialStateFormData)

  // useEffect(() => {
  //   console.log('formData', formData)
  // }, [formData])

  // useEffect(() => {
  //   console.log('deckNames', deckNames)
  // }, [deckNames])

  //  const { data, isSuccess, isError, isLoading } = useGetMnemonic(keyword, (!originalCzInput))

  const handleInputChange = (value, field) => {
    if (field === 'deckName') {
      // console.log("value.split(':deckid') ", value.split(':deckid'))
      // eslint-disable-next-line no-extra-semi
      const [deckName, deckId] = value.split(':deckid')
      // console.log('deckName, deckId', deckName, deckId)
      setFormData((prevState) => ({ ...prevState, deckName, deckId }))
    } else if (field === 'definition' || field === 'example') {
      setFormData((prevState) => ({ ...prevState, [field]: value }))

      // setDefinition(value)
      // setExample(value)
    } else {
      setFormData((prevState) => ({ ...prevState, [field]: value }))
    }
  }

  useEffect(() => {
    // console.log('decknames change ', deckNames)
    // console.log('decknames změněny, ted: ', deckNames)
    if (deckNames.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        deckName: deckNames[0].value,
        deckId: deckNames[0]._id, // Assuming you also want to set deckId
      }))
    }
  }, [deckNames])

  useEffect(() => {
    if (noteType_anki.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        noteType: noteType_anki[0].value,
      }))
    }
  }, [noteType_anki])

  // useEffect(() => {
  //   if (deckNames_anki.length > 0) {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       deckName: deckNames_anki[0].value,
  //     }))
  //   }
  // }, [deckNames_anki])

  // const { data, isLoading, isError } = useQuery(
  //   ['definitionAndExample', keyword],
  //   () => fetchDefinitionAndExample(keyword),
  //   {
  //     // onSuccess: (newData) => {
  //     // },
  //     // Stop retrying after the first failure
  //     retry: 0,
  //     // so query does not automatically run for the initial data
  //     enabled: keyword !== 'wise' || previousKeyword !== null,
  //   }
  // )

  const results = useQueries({
    queries: [
      {
        queryKey: ['definitionExample', keyword],
        queryFn: () => fetchDefinitionAndExample(keyword),
        enabled: keyword !== 'wise' || previousKeyword !== null,
        meta: {
          errorMessage: 'Nepodailo se načíst všechna pole',
        },
        retry: 0,
        onSuccess: (data) => {
          setDefinition(data.definition)
          setExample(data.example)
        },
      },
      {
        queryKey: ['mnemonic', keyword],
        queryFn: () => getMnemonic(keyword),
        enabled: keyword !== 'wise' || previousKeyword !== null,
        meta: {
          errorMessage: 'Nepodailo se načíst všechna pole',
        },
        retry: 0,
        onSuccess: (data) => {
          // setMnemonic(data.mnemonic)
          setMnemonic(data.mnemonics[0]?.text || '')
          setMnemonics(data.mnemonics)
        },
      },
    ],
  })

  const isError = results?.some((result) => result.isError)

  // if (isError || definition === '' || example === '' || mnemonic === '') {
  //   console.log('results', results)
  //   toast.error('Nepodailo se načíst všechna pole')
  //   // console.error(results.find((result) => result.isError).error)
  // }

  // const results = useQueries({
  //   queries: [
  //     { queryKey: ['post', 1], queryFn: fetchPost, staleTime: Infinity },
  //     { queryKey: ['post', 2], queryFn: fetchPost, staleTime: Infinity },
  //   ],
  // })

  // useEffect(() => {
  //   if (keyword === 'wise' && previousKeyword === null) {
  //     // setImages(defaultImages)
  //     return
  //   }
  //   // setLoading(true)
  //   resetAnkiForm()
  //   keyword
  //   fetchDefinitionAndExample(keyword)
  //     .then((data) => {
  //       setDefinition(data.definition)
  //       setExample(data.example)
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })

  //   fetchMnemonic(keyword).then((data) => {
  //     setMnemonic(data.mnemonic)
  //   })
  // }, [keyword])

  const noteTypeSelect = [
    { label: 'EN-CZ (1 karta)', value: 'englishToCzech', _id: 1 },
    { label: 'CZ-EN (1 karta)', value: 'czechToEnglish', _id: 2 },
    { label: 'oboustranná (2 karty)', value: 'both', _id: 3 },
  ]

  const useAddNoteMutation = useAddNote((data) => {
    console.log('data from addnote anki', data)
    resetStatesAfterSuccessfullFormSubmit()
  })

  // when user saves to Anki
  const useGetAnkiDecksAndNoteTypesMutation = useGetAnkiDecksAndNoteTypes(
    (data) => {
      // console.log('useGetAnkiDecksAndNoteTypesMutation data ', data)

      const deckNames_ = data.ankiDeckNames.result.map((item, i) => {
        return {
          // label: item,
          value: item,
          _id: i + 1,
          statusInfo: null,
        }
      })
      // setDeckNames_anki(deckNames_)
      // console.log('deckNames_', deckNames_)
      setDeckNames(deckNames_)

      const noteTypes_ = data.ankiNoteTypes.result.map((item, i) => {
        return {
          value: item,
          _id: i + 1,
          label: item,
        }
      })

      // console.log('noteTypes_', noteTypes_)
      setNoteType_anki(noteTypes_)
    }
  )

  // console.log('noteType_anki', noteType_anki)

  // useEffect(() => {
  //   if (!saveToApp.current) {
  //     useGetAnkiDecksAndNoteTypesMutation.mutate()
  //   }
  // }, [])

  const resetStatesAfterSuccessfullFormSubmit = () => {
    toast.success('Uloženo', {
      autoClose: 600,
    })
    resetAnkiForm()
    setMnemonic('')
    setMnemonics([])
    setCustomMnemonics([])
    setFormData({
      image: '',
      cz: '',
      en: '',
      definition: '',
      example: '',
      mnemonic: '',
      extra: '',
      deckName: formData.deckName,
      deckId: formData.deckId,
      noteType: formData.noteType,
    })
    setCheckedImages([])
    // ankiFormRef.current.reset()
    // setClearAnkiInputs(true)
  }

  const mutation = useMutation({
    mutationFn: (formData) => {
      // if (saveToAnki) return axiosBearer.post('/localhostanki', formData)
      // else
      return axiosBearer.post('/karty', formData)
    },

    onSuccess: () => {
      resetStatesAfterSuccessfullFormSubmit()
    },
    onError: () => {
      toast.error('Neuloženo', {
        autoClose: 800,
      })
    },
  })

  const [customMnemonics, setCustomMnemonics] = useState([])

  // useEffect(() => {
  //   console.log('mnemonic ', mnemonic)
  // }, [mnemonic])

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    // console.log('saveToApp.current', saveToApp.current)
    if (saveToApp.current === true) {
      let formData_ = formData
      // if(formData_.noteType === 'both') {

      // pokud uživatel přidal custom mnemonic, uložit do keyword schema:
      // console.log('customMnemonics před uložením do db', customMnemonics)

      let customMnemonics_ = customMnemonics.map((item) => {
        return {
          text: item.text,
        }
      })
      // console.log('customMnemonics_ ', customMnemonics_)
      // console.log('mnemonic ', mnemonic)
      if (customMnemonics.length === 0) {
        if (!formData_.mnemonic) {
          customMnemonics_ = []
        } else {
          customMnemonics_ = [{ text: formData_.mnemonic }]
        }
      }

      formData_ = {
        // ...formData,
        cz: getRidOfWrappingP(formData.cz),
        en: getRidOfWrappingP(formData.en),
        definition: getRidOfWrappingP(formData.definition),
        example: getRidOfWrappingP(formData.example),
        mnemonic: getRidOfWrappingP(formData.mnemonic),
        extra: getRidOfWrappingP(formData.extra),
        // image: getRidOfWrappingP(formData.image),
        image: getUrlsArrayFromImgsString(formData.image),
        noteType: formData.noteType,
        deckName: formData.deckName,
        deckId: formData.deckId,
        checkedImages,
        customMnemonics: customMnemonics_,
        keyword,
        isKeywordEnglish: originalCzInput ? false : true,
      }
      console.log('formdata_', formData_)

      // }
      // if (checkedImages.length > 0) {
      // add to the formData
      // }
      // await axiosBearer.post('/karty', formData_)
      mutation.mutate(formData_)
      // ankiFormRef.current.reset()
    } else {
      // save to anki logic
      saveCardToAnki(formData)
    }
  }

  // useEffect(() => {
  //   console.log('formData', formData)
  // }, [formData])

  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const saveCardToAnki = async (formData) => {
    // Validation check (assuming formData has front and back properties)
    // if (!formData.front || !formData.back) {
    //   toast.error('Chybí Front/Back.')
    //   return
    // }

    console.log('image ', getUrlsArrayFromImgsString(formData.image))
    console.log('checkedImages ', checkedImages)

    let image_formattedForAnki = []
    let checkedImages_formattedForAnki = []
    if (formData.image && formData.image.length > 0) {
      image_formattedForAnki = getUrlsArrayFromImgsString(formData.image).map(
        (img, index) => ({
          url: img,
          filename: `${sanitizeFilename(transliterate(keyword))}${index}.jpg`,
          fields: ['image'],
        })
      )
    }

    // přidat tam i checkedImages
    checkedImages_formattedForAnki = checkedImages.map((img, index) => ({
      url: img.url,
      filename: `${sanitizeFilename(transliterate(keyword))}${
        image_formattedForAnki.length + index
      }.jpg`,
      fields: ['image'],
    }))

    const action = 'addNote'
    const version = 6

    const ankiObjectToAddNote = {
      action,
      version,
      params: {
        note: {
          deckName: formData.deckName,
          modelName: formData.noteType,
          fields: {
            cz: getRidOfWrappingP(formData.cz),
            en: getRidOfWrappingP(formData.en),
            definition: getRidOfWrappingP(formData.definition),
            example: getRidOfWrappingP(formData.example),
            mnemonic: getRidOfWrappingP(formData.mnemonic),
            extra: getRidOfWrappingP(formData.extra),
          },

          // image: getRidOfWrappingP(formData.image),

          audio: [
            {
              url: 'https://pub-e8ca240a2a38422c83c460d329d0f573.r2.dev/pronunciation_example.mp3',
              filename: `example-2.mp3`,
              fields: ['pronunciation'],
            },
          ],
          picture: [
            ...image_formattedForAnki,
            ...checkedImages_formattedForAnki,
            // {
            //   url: 'https://images.unsplash.com/photo-1579353977828-2a4eab540b9a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0OTkwMzN8MHwxfHNlYXJjaHwzfHxleGFtcGxlfGVufDB8MHx8fDE3MDM2Nzg0OTl8MA&ixlib=rb-4.0.3&q=80&w=400',
            //   filename: `example.png`,
            //   fields: ['image'],
            // },
          ],

          // tags,
        },
      },
    }

    console.log('ankiObjectToAddNote', ankiObjectToAddNote)

    useAddNoteMutation.mutate(ankiObjectToAddNote)
    // try {
    //   const response = await axios.post('http://127.0.0.1:8765', {
    //     action: 'addNote',
    //     version: 6,
    //     params: {
    //       note: {
    //         deckName,
    //         modelName,
    //         fields,
    //         audio: formData.audio, // Assuming audio is part of formData
    //         picture: formData.picture, // Assuming picture is part of formData
    //         tags,
    //       },
    //     },
    //   })

    //   if (response.data && response.data.error === null) {
    //     // Handle success
    //     setSuccessMessage('Note successfully added to Anki.')
    //     // Additional success logic
    //   } else {
    //     // Handle error
    //     setErrorMessage(response.data.error || 'Unknown error occurred.')
    //   }
    // } catch (error) {
    //   console.error(error)
    //   setErrorMessage('Error connecting to Anki.')
    // }
  }

  const {
    data: userData,
    isError: isUserError,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetUser(currentUser?.uid, !!currentUser?.uid)

  useEffect(() => {
    if (isUserSuccess) {
      saveToApp.current = userData.settings.SRS.useAppSRS

      if (saveToApp.current === false) {
        useGetAnkiDecksAndNoteTypesMutation.mutate()
      } else if (saveToApp.current === true) {
        setFormData((prevState) => ({
          ...prevState,
          noteType: noteTypeSelect[0].value,
        }))
      }
    }
  }, [isUserSuccess, userData])

  // save on ctrl + shift + d

  useKeyboardShortcut([
    {
      keys: ['Ctrl', 'Shift', 'l'],
      enabled: true,
      action: handleSubmit,
    },
  ])

  // get deckNames on initial render
  // useQueryDeckNames(setDeckNames)
  useQueryDeckNames(
    (data) => {
      setDeckNames(data)
    },
    false,
    // true
    saveToApp.current
  )
  // console.log('deckNames', deckNames)

  // console.log(formData)
  // console.log('decknames ', deckNames)

  // useEffect(() => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     noteType: noteType_anki[0].value,
  //   }))
  // }, [noteType_anki])

  // console.log('savetoapp ', saveToApp.current)

  return (
    <div className="middle__anki-form__container">
      {/* top left settings button */}
      {/* <PanelSettingsContainer
        handleCloseSettingsOverlay={handleCloseSettingsOverlay}
        handleOpenSettingsOverlay={handleOpenSettingsOverlay}
        showSettingsOverlay={showSettingsOverlay}
      ></PanelSettingsContainer> */}
      <form
        className="middle__anki-form"
        onSubmit={(e) => handleSubmit(e)}
        ref={ankiFormRef}
      >
        <AnkiFormRow
          id="cz"
          label="Česky"
          value={originalCzInput ? originalCzInput : czShort}
          handleInputChange={handleInputChange}
          clearAnkiInputs={clearAnkiInputs}
        />

        <AnkiFormRow
          id="en"
          label="Anglicky"
          // placeholder={previousKeyword === null ? 'wise' : ''}
          value={keyword}
          handleInputChange={handleInputChange}
          clearAnkiInputs={clearAnkiInputs}
          // ankiFormRowEnRef={ankiFormRowEnRef}
        />

        <AnkiFormRow
          id="definition"
          label="Definice"
          value={definition}
          handleInputChange={handleInputChange}
          clearAnkiInputs={clearAnkiInputs}
          setClearAnkiInputs={setClearAnkiInputs}
        />

        <AnkiFormRow
          id="example"
          label="Příklad"
          value={example}
          handleInputChange={handleInputChange}
          clearAnkiInputs={clearAnkiInputs}
        />

        {/* <InputUsingEditor /> */}
        <AnkiFormRow
          id="mnemonic"
          label="Pomůcka"
          value={mnemonic}
          handleInputChange={handleInputChange}
          customMnemonics={customMnemonics}
          setCustomMnemonics={setCustomMnemonics}
          mnemonics={mnemonics}
          setMnemonics={setMnemonics}
          clearAnkiInputs={clearAnkiInputs}
        />

        <AnkiFormRowImage
          id="image"
          label="Obrázek"
          placeholder=""
          ankiFormRowImageRef={ankiFormRowImageRef}
          // value={example}
          handleInputChange={handleInputChange}
        />

        <div className="middle__anki-form__metadata">
          <Select
            id="deckName"
            label="Balíček"
            // selectData={saveToApp.current ? deckNames : deckNames_anki}
            value={`${formData.deckName}:deckid${formData.deckId}`}
            selectData={deckNames}
            onChange={handleInputChange}
            // value={deckNames[0].value}
          />
          <Select
            id="noteType"
            label="Typ karty"
            value={formData.noteType}
            selectData={saveToApp.current ? noteTypeSelect : noteType_anki}
            onChange={handleInputChange}
          />
        </div>
        <Button
          text={`Uložit ${saveToApp.current ? '' : '(do Anki)'}`}
          type="submit"
          className="__btn-orange middle__anki-form__submit"
        />
      </form>
    </div>
  )
}

export default AnkiForm
