import React from 'react'
import styled from 'styled-components'

const StyledH1 = styled.h1`
  text-align: center;
  margin-bottom: 0.4rem;
  margin-top: 0.8rem;
  font-size: var(--font-size-h1);
`

const H1 = ({ children, ...props }) => {
  return <StyledH1 {...props}>{children}</StyledH1>
}

export default H1
