import { motion } from 'framer-motion'

// interface QuestionInteface {
//     header: string;
//     text: string;
// }

/**
 * [header, text] Parameters.
 *
 * NOTE: We didn't use any parameters here but in Production you gotta use it.
 */
const Question = ({ question, answer }) => {
  const questionVariants = {
    initial: {
      y: -100,
      // y: 0,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  }

  return (
    <div
      className="my-2"
      // variants={questionVariants}
      // initial="initial"
      // whileInView="animate"
      // viewport={{ once: true }}
    >
      <h3
        className="header-color font-bold text-2xl my-2"
        style={{
          fontSize: 'var(--font-size-medium)',
        }}
      >
        {question}
      </h3>
      <div className="text-color text-sm md:text-base">{answer}</div>
    </div>
  )
}

export default Question
