import React, { useState } from 'react'
import Select from '/src/components/Select'
import { useEffect } from 'react'

const SelectAction = ({
  onChange,
  selectedCards = [],
  decks = [],
  placeholderLabel,
  onlyForOneCard = false,
  containerStyling,
  inlineStyle,
  placeholderOption,
  onlySuspendedCardsAreSelected,
}) => {
  const deckNames = decks.map((deck) => (
    <option
      key={deck._id}
      value={`${deck.value}:${deck._id}`}
      data-action={'changeDeck'}
    >
      🃏 {deck.value}
    </option>
  ))

  // useEffect(() => {
  //   console.log('selectedCards', selectedCards)
  // }, [selectedCards])

  // Array.from(selectedCards).every((card) => card.suspended
  return (
    <Select
      id="actions"
      // value={selectedAction}
      value=""
      onChange={onChange}
      containerStyling={containerStyling ? containerStyling : null}
      placeholderOption={placeholderOption}
      selectedCards={selectedCards}
    >
      {/* <option disabled value="">
        Vyberte:
      </option> */}

      {/* pro /opakovani || pro /slovicka  */}
      {(onlyForOneCard || selectedCards.size <= 1) && (
        <option value="modify">✏️ Upravit</option>
      )}

      <option value="delete">🗑️ Smazat</option>
      {/* ✋  ❌ */}
      {!(onlySuspendedCardsAreSelected && selectedCards.size >= 1) && (
        <option value="suspend">🚫 Vyřadit z opakování</option>
      )}
      {onlySuspendedCardsAreSelected && selectedCards.size >= 1 && (
        <option value="unsuspend">🔄 Obnovit opakování</option>
      )}
      <option value="forget">🚽 Zapomenout</option>
      <optgroup label="📂 Přesunout do balíčku...">{deckNames}</optgroup>
      <optgroup label="🚩 Označit:">
        <option>🟥 červeně</option>
        <option>🟦 modře </option>
        <option>🟩 zeleně </option>
      </optgroup>
    </Select>
  )
}

export default SelectAction
