import { createContext, useContext, useState } from 'react'

const GlobalNotificationContext = createContext()

export function useGlobalNotification() {
  return useContext(GlobalNotificationContext)
}

export function GlobalNotificationProvider({ children }) {
  const [globalNotification, setGlobalNotification] = useState('jo')

  const value = {
    globalNotification,
    setGlobalNotification,
  }

  return (
    <GlobalNotificationContext.Provider value={value}>
      {children}
    </GlobalNotificationContext.Provider>
  )
}
