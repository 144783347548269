import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const LeftSide = styled.div`
  flex: 1 1 auto; // Grow to fill available space, but can also shrink
  margin-right: 20px; // Adds some space between the label and the content
`

const RightSide = styled.div`
  flex: 0 1 auto; // Do not grow, but can shrink if needed

  & > input {
    /* width: 30px;  */
    max-width: 100px;
  }
`

const SettingsTabRow = ({ leftSide, rightSide, key_ }) => {
  return (
    <Row key={key_}>
      <LeftSide>{leftSide}</LeftSide>
      <RightSide>{rightSide}</RightSide>
    </Row>
  )
}

export default SettingsTabRow
