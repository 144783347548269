import Title from '/src/components/Title'
import Question from './question/Question'
import '/src/App.css'
import { useNavigate } from 'react-router-dom'

const Faq = () => {
  const navigate = useNavigate()
  const handlePostClick = (id) => {
    console.log('clicked id: ', id)
    navigate(`/blog/${id}`)
  }
  const questions = [
    {
      question: 'Máte i mobilní aplikaci?',
      answer: (
        <p>
          Mobilní aplikace prozatím není k dispozici. Angličtinomat ale i tak
          můžete v telefonu otevřít jedním kliknutím na jeho ikonu - stačí si ho
          přidat na plochu telefonu. Návod{' '}
          <span
            className="link-to-external-page"
            onClick={() => {
              handlePostClick('pridani-na-plochu-telefonu')
            }}
          >
            zde
          </span>
          .
        </p>
      ),

      id: 'pridani-na-plochu-telefonu',
    },
    {
      question: 'Jak funguje "algoritmus pro opakování"?',
      answer: (
        <p>
          Algoritmus vás bude ze slovíček testovat a přitom sledovat, jak dobře
          si každé pamatujete. Podle toho vám vhodně naplánuje opakování každého
          slovíčka - abyste neztráceli čas těmi jednoduššími, ale zaměřili se na
          ty těžší. Bližší vysvětlení v{' '}
          <span
            className="link-to-external-page"
            onClick={() => {
              handlePostClick('bez-spaced-repetition-to-nepujde')
            }}
          >
            tomto
          </span>{' '}
          článku.{' '}
        </p>
      ),
      id: 'bez-spaced-repetition-to-nepujde',
    },
    {
      question: 'Jak aplikace používá paměťové pomůcky?',
      answer: (
        <p>
          Díky paměťovým pomůckám (slovní, vizuální), si slovíčka snadněji
          zapamatujete (více{' '}
          <span
            className="link-to-external-page"
            onClick={() => {
              handlePostClick('pametove-pomucky-pro-uceni-slovicek')
            }}
          >
            zde
          </span>
          ). V aplikaci si buď můžete vytvořit vlastní nebo si vybrat z těch,
          které k danému slovíčku vytvořili jiní uživatelé. Počet takových
          slovíček neustále roste.
        </p>
      ),
      id: 2,
    },
    {
      question: 'Jaké slovníky v aplikaci používáte?',
      answer: (
        <p>
          Existují paměťové pomůcky (slovní, vizuální), díky kterým si slovíčka
          snadněji zapamatujete (více{' '}
          <span
            className="link-to-external-page"
            onClick={() => {
              handlePostClick('pametove-pomucky-pro-uceni-slovicek')
            }}
          >
            zde
          </span>
          ). V aplikaci si buď můžete vytvořit vlastní nebo si vybrat z těch,
          které k danému slovíčku vytvořili jiní uživatelé. Počet takových
          slovíček neustále roste.
        </p>
      ),
      id: 3,
    },
  ]
  return (
    <section id="faq" className="max-w-6xl mx-auto">
      <div
        className="container mx-auto border-b border-gray-800"
        style={{
          padding: '0 0.8rem',
        }}
      >
        {/* <Title header="Často kladené otázky" hasSpan={false} /> */}
        <h2
          className="font-bold header-color"
          style={{
            display: 'flex',
            justifyContent: 'center',

            // marginBlock: 'var(--margin-block)',
          }}
        >
          Často kladené otázky
        </h2>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-1 mb-12">
          {questions.map((question) => (
            <Question
              key={question.id}
              question={question.question}
              answer={question.answer}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Faq
