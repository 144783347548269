import React, { useEffect, useState } from 'react'
import VocabTableRow from './VocabTableRow'
import Loading from '/src/components/Loading'
import styled from 'styled-components'
import Button from '/src/components/10_utils/Button'

const CardTable = ({
  data,
  // searchResult,
  isLoading,
  isError,
  error,
  currentPage,
  selectedCards,
  setSelectedCards,
  setAreAllDataCheckboxesChecked,
  areAllDataCheckboxesChecked,
}) => {
  // const [selectedCards, setSelectedCards] = useState([])
  // const cardsToDisplay = searchResults.length > 0 ? searchResults : data?.cards

  const [areAllPageCheckboxesChecked, setAreAllPageCheckboxesChecked] =
    useState(false)

  useEffect(() => {
    let areAllCheckboxesCheckedOnCurrentPage = true
    for (let i = 0; i < data?.cards?.length; i++) {
      if (!selectedCards.has(data.cards[i]._id)) {
        areAllCheckboxesCheckedOnCurrentPage = false
        break
      }
    }
    setAreAllPageCheckboxesChecked(areAllCheckboxesCheckedOnCurrentPage)
  }, [currentPage, data?.cards?.length, selectedCards])

  if (isLoading) return <Loading />
  if (isError) console.log(error)
  // console.log(data)

  const toggleCheckbox = () => {
    if (areAllPageCheckboxesChecked) {
      const newSet = new Set(selectedCards)
      data.cards.forEach((card) => newSet.delete(card._id))
      setSelectedCards(newSet)
      setAreAllPageCheckboxesChecked(false)
    } else {
      const newSet = new Set(selectedCards)
      data.cards.forEach((card) => newSet.add(card._id))
      setSelectedCards(newSet)

      setAreAllPageCheckboxesChecked(true)
    }
  }

  // select all > areAllDataCheckboxesChecked = true (important for server actions) + buttonText = deselect all
  const toggleAllCards = () => {
    if (areAllDataCheckboxesChecked) {
      setSelectedCards(new Set())
      setAreAllDataCheckboxesChecked(false)
      setAreAllPageCheckboxesChecked(false)
    } else {
      setSelectedCards(new Set())
      setAreAllDataCheckboxesChecked(true)
      setAreAllPageCheckboxesChecked(true)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <BeforeTableLine>
            <Button
              text={`${
                areAllDataCheckboxesChecked ? 'Zrušit' : 'Vybrat'
              } vše (${data?.totalCards})`}
              padding="var(--padding-button-smaller)"
              onClick={toggleAllCards}
              backgroundColor={
                areAllDataCheckboxesChecked
                  ? 'var(--color-blog)'
                  : 'var(--color-black)'
              }
              disabled={data?.totalCards === 0}
            />
            <div>
              Vybráno:{' '}
              {areAllDataCheckboxesChecked
                ? data?.totalCards
                : selectedCards.size}
            </div>

            {/* <Button
              text={`${
                areAllDataCheckboxesChecked ? 'Zrušit' : 'Vybrat'
              } vše (${data?.totalCards})`}
              padding="var(--padding-button-smaller)"
              onClick={toggleAllCards}
              backgroundColor={
                areAllDataCheckboxesChecked
                  ? 'var(--color-blog)'
                  : 'var(--color-black)'
              }
              disabled={data?.totalCards === 0}
            />

            <Button></Button> */}
          </BeforeTableLine>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <Th>
                    <input
                      type="checkbox"
                      checked={
                        areAllPageCheckboxesChecked ||
                        areAllDataCheckboxesChecked
                      }
                      onChange={toggleCheckbox}
                    />
                  </Th>
                  <Th>anglicky</Th>
                  <Th>česky</Th>
                  <Th>balíček</Th>
                  {/* <Th>obrázek</Th>
                  <Th>výslovnost</Th>
                  <Th>příklad</Th>
                  <Th> pomůcka</Th> */}
                  {/* <Th>opakování</Th>
                  <Th>chyby</Th> */}
                  <Th>vytvořeno</Th>
                  <Th>k opakování</Th>
                  <Th>typ karty</Th>
                  <Th>karta</Th>
                </tr>
              </thead>
              <Tbody>
                {data?.cards.map((card) => (
                  <VocabTableRow
                    key={card._id}
                    card={card}
                    setSelectedCards={setSelectedCards}
                    selectedCards={selectedCards}
                    areAllPageCheckboxesChecked={areAllPageCheckboxesChecked}
                    setAreAllPageCheckboxesChecked={
                      setAreAllPageCheckboxesChecked
                    }
                    areAllDataCheckboxesChecked={areAllDataCheckboxesChecked}
                    setAreAllDataCheckboxesChecked={
                      setAreAllDataCheckboxesChecked
                    }
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

const Tbody = styled.tbody`
  img {
    max-width: 130px;
    height: auto;
    max-height: 100px;
  }
  tr {
    transition: background-color 0.3s;
  }
  tr:nth-child(even) {
    background-color: var(--color-gray-light-3);
  }
  tr:hover {
    background-color: var(--color-gray-light-2);
  }

  & > tr > td {
    padding: 0.3rem;
    border-right: 1px solid black;
    border-left: 1px solid black;
    text-align: center;
  }
`
const TableContainer = styled.div`
  overflow-x: auto;
`

const Table = styled.table`
  min-width: 100%; /* Set min-width to 100% so that it takes up the full width of the container */
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
  /* padding-block: 0.3rem; */
  margin: 0 auto;
  border: 1px solid black;
`

const Th = styled.th`
  border: 1px solid black;
  padding: 0.3rem;
  vertical-align: middle;
`

const BeforeTableLine = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  z-index: 12;
`

export default CardTable
