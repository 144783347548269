import React, { useEffect, useState, useRef } from 'react'
import { useAuth } from '/src/contexts/AuthContext'
import InputBasic from '/src/components/InputBasic'
import InputSearchForm from '/src/components/InputSearchForm'
import BasicSelect from '../../components/BasicSelect'
import { useQueryClient } from '@tanstack/react-query'
import { resetDB } from '/src/services/fetchCards'
import Button from '/src/components/10_utils/Button'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useQueryDeckNames } from '/src/hooks/useQueryDecknames'
import { useGetDecks, useGetUser, useUpdateUser } from '/src/hooks/useVocabAPI'
import H2 from '/src/components/H2'
import SettingsAccount from './SettingsAccount'
import SettingsTabContent from './SettingsTabContent'
import SettingsContentCards from './SettingsContentCards'

const Settings = () => {
  const [activeTab, setActiveTab] = useState('cards')
  const [updatedSettings, setUpdatedSettings] = useState({})
  const [user, setUser] = useState({})
  const { currentUser } = useAuth()

  const initialNoteTypesAndTheirFields = {
    englishToCzech_front: [
      {
        value: 'en',
        label: 'Anglicky',
        checked: false,
      },
      {
        value: 'example',
        label: 'Příklad',
        checked: false,
      },
      {
        value: 'definition',
        label: 'Definice',
        checked: false,
      },
      {
        value: 'image',
        label: 'Obrázek',
        checked: false,
      },
      {
        value: 'pronunciation',
        label: 'Výslovnost',
        checked: false,
      },
      {
        value: 'extra',
        label: 'Extra (vlastní)',
        checked: false,
      },
    ],
    englishToCzech_back: [
      {
        value: 'cz',
        label: 'Česky',
        checked: false,
      },
      {
        value: 'pronunciation',
        label: 'Výslovnost',
        checked: false,
      },
      {
        value: 'example',
        label: 'Příklad',
        checked: false,
      },
      {
        value: 'image',
        label: 'Obrázek',
        checked: false,
      },
      {
        value: 'definition',
        label: 'Definice',
        checked: false,
      },
      {
        value: 'mnemonic',
        label: 'Pomůcka',
        checked: false,
      },
      {
        value: 'extra',
        label: 'Extra (vlastní)',
        checked: false,
      },
      {
        value: 'en',
        label: 'Anglicky',
        checked: false,
      },
    ],
    czechToEnglish_front: [
      {
        value: 'cz',
        label: 'Česky',
        checked: false,
      },
      {
        value: 'example',
        label: 'Příklad',
        checked: false,
      },
      {
        value: 'image',
        label: 'Obrázek',
        checked: false,
      },
      {
        value: 'pronunciation',
        label: 'Výslovnost',
        checked: false,
      },
      {
        value: 'extra',
        label: 'Extra (vlastní)',
        checked: false,
      },
    ],
    czechToEnglish_back: [
      {
        value: 'en',
        label: 'Anglicky',
        checked: false,
      },
      {
        value: 'pronunciation',
        label: 'Výslovnost',
        checked: false,
      },
      {
        value: 'example',
        label: 'Příklad',
        checked: false,
      },
      {
        value: 'image',
        label: 'Obrázek',
        checked: false,
      },
      {
        value: 'definition',
        label: 'Definice',
        checked: false,
      },
      {
        value: 'mnemonic',
        label: 'Pomůcka',
        checked: false,
      },
      {
        value: 'extra',
        label: 'Extra (vlastní)',
        checked: false,
      },
      {
        value: 'cz',
        label: 'Česky',
        checked: false,
      },
    ],
  }

  const [englishToCzech_front, setEnglishToCzech_front] = useState(
    initialNoteTypesAndTheirFields.englishToCzech_front
  )

  const [englishToCzech_back, setEnglishToCzech_back] = useState(
    initialNoteTypesAndTheirFields.englishToCzech_back
  )

  const [czechToEnglish_front, setCzechToEnglish_front] = useState(
    initialNoteTypesAndTheirFields.czechToEnglish_front
  )

  const [czechToEnglish_back, setCzechToEnglish_back] = useState(
    initialNoteTypesAndTheirFields.czechToEnglish_back
  )

  const updateUserMutation = useUpdateUser((data) => {
    console.log('updatovano settings, data ', data)
  })

  const handleSubmitSettingsChanges = (dbSchemaLocation, newValue) => {
    console.log('submit settings changes')

    setUpdatedSettings({
      ...updatedSettings,
      dbSchemaLocation: newValue,
    })

    let settingsToUpdate = {}
    if (activeTab === 'SRS') {
      settingsToUpdate = {
        //obecné
        'settings.SRS.useAppSRS': SRSFormData.useAppSRS,
        'settings.app.audioAutoplay': SRSFormData.audioAutoplay,
        'settings.SRS.preferences.learnAhead': SRSFormData.learnAhead,
        // new cards
        'settings.SRS.deck.new_cards.max_per_day': SRSFormData.max_per_day,
        'settings.SRS.deck.new_cards.steps': SRSFormData.steps,
        'settings.SRS.deck.new_cards.graduating_interval':
          SRSFormData.graduating_interval,
        'settings.SRS.deck.new_cards.easy_interval': SRSFormData.easy_interval,
        'settings.SRS.deck.new_cards.starting_ease': SRSFormData.starting_ease,
        // reviews
        'settings.SRS.deck.reviews.easy_multiplier':
          SRSFormData.easy_multiplier,
        'settings.SRS.deck.reviews.hard_multiplier':
          SRSFormData.hard_multiplier,
        'settings.SRS.deck.reviews.interval_factor':
          SRSFormData.interval_factor,
        // lapses
        'settings.SRS.deck.lapses.lapse_steps': SRSFormData.lapse_steps,
        'settings.SRS.deck.lapses.lapse_multiplier':
          SRSFormData.lapse_multiplier,
        'settings.SRS.deck.lapses.lapse_min_interval':
          SRSFormData.lapse_min_interval,
      }
    } else if (activeTab === 'cards') {
      const englishToCzech_front_fields = englishToCzech_front
        .filter((item) => item.checked)
        .map((item) => item.value)

      const englishToCzech_back_fields = englishToCzech_back
        .filter((item) => item.checked)
        .map((item) => item.value)
      console.log('englishToCzech_back_fields ', englishToCzech_back_fields)

      const czechToEnglish_front_fields = czechToEnglish_front
        .filter((item) => item.checked)
        .map((item) => item.value)

      const czechToEnglish_back_fields = czechToEnglish_back
        .filter((item) => item.checked)
        .map((item) => item.value)

      settingsToUpdate = {
        'settings.noteTypes.englishToCzech.front': englishToCzech_front_fields,
        'settings.noteTypes.englishToCzech.back': englishToCzech_back_fields,
        'settings.noteTypes.czechToEnglish.front': czechToEnglish_front_fields,
        'settings.noteTypes.czechToEnglish.back': czechToEnglish_back_fields,
      }
    } else if (activeTab === 'account') {
      settingsToUpdate = {
        'account.username': accountSettings.username,
      }
    }
    console.log('user ', user)
    console.log('settignstoupdate ', settingsToUpdate)
    updateUserMutation.mutate([user._id, settingsToUpdate])
  }

  const {
    data: userData,
    isError: isUserError,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetUser(currentUser?.uid, !!currentUser?.uid)

  useEffect(() => {
    if (isUserSuccess) {
      console.log('jsem v use effect, dají se tam initial values asi')
      setUser(userData)
      // obecné
      setSRSFormData({
        useAppSRS: userData.settings.SRS.useAppSRS,
        audioAutoplay: userData.settings.app.audioAutoplay,
        learnAhead: userData.settings.SRS.preferences.learnAhead,

        // new
        max_per_day: userData.settings.SRS.deck.new_cards.max_per_day,
        steps: userData.settings.SRS.deck.new_cards.steps,
        graduating_interval:
          userData.settings.SRS.deck.new_cards.graduating_interval,
        easy_interval: userData.settings.SRS.deck.new_cards.easy_interval,
        starting_ease: userData.settings.SRS.deck.new_cards.starting_ease,

        // reviews
        easy_multiplier: userData.settings.SRS.deck.reviews.easy_multiplier,
        hard_multiplier: userData.settings.SRS.deck.reviews.hard_multiplier,
        interval_factor: userData.settings.SRS.deck.reviews.interval_factor,

        // lapses
        lapse_steps: userData.settings.SRS.deck.lapses.lapse_steps,
        lapse_multiplier: userData.settings.SRS.deck.lapses.lapse_multiplier,
        lapse_min_interval:
          userData.settings.SRS.deck.lapses.lapse_min_interval,
      })

      // initialNoteTypesAndTheirFields.englishToCzech_front.forEach((field) => {
      //   if (
      //     userData.settings.noteTypes.englishToCzech.front.includes(field.value)
      //   ) {
      //     field.checked = true
      //   }
      // })

      // initialNoteTypesAndTheirFields.englishToCzech_back.forEach((field) => {
      //   if (
      //     userData.settings.noteTypes.englishToCzech.back.includes(field.value)
      //   ) {
      //     field.checked = true
      //   }
      // })

      updateNoteTypesWithFetched('englishToCzech', 'front')
      updateNoteTypesWithFetched('englishToCzech', 'back')

      updateNoteTypesWithFetched('czechToEnglish', 'front')
      updateNoteTypesWithFetched('czechToEnglish', 'back')

      // initialNoteTypesAndTheirFields.czechToEnglish_front.forEach((field) => {
      //   if (
      //     userData.settings.noteTypes.czechToEnglish.front.includes(field.value)
      //   ) {
      //     field.checked = true
      //   }
      // })

      // initialNoteTypesAndTheirFields.czechToEnglish_back.forEach((field) => {
      //   if (
      //     userData.settings.noteTypes.czechToEnglish.back.includes(field.value)
      //   ) {
      //     field.checked = true
      //   }
      // })

      setEnglishToCzech_front(
        initialNoteTypesAndTheirFields.englishToCzech_front
      )

      setEnglishToCzech_back(initialNoteTypesAndTheirFields.englishToCzech_back)

      setCzechToEnglish_front(
        initialNoteTypesAndTheirFields.czechToEnglish_front
      )

      setCzechToEnglish_back(initialNoteTypesAndTheirFields.czechToEnglish_back)

      setAccountSettings({
        username: userData.account.username,
      })
    }
  }, [isUserSuccess, userData])

  const [SRSFormData, setSRSFormData] = useState({})
  const [accountSettings, setAccountSettings] = useState({})

  function updateNoteTypesWithFetched(noteType, cardSide) {
    // Create a Map for quick lookup
    const fieldsMap = new Map(
      initialNoteTypesAndTheirFields[`${noteType}_${cardSide}`].map((field) => [
        field.value,
        field,
      ])
    )

    console.log('fieldsmap ', fieldsMap)

    // Arrays to hold checked and unchecked fields
    const checkedFields = []
    const uncheckedFields = []

    // Iterate over the fetched array to maintain order
    userData.settings.noteTypes[noteType][cardSide].forEach((value) => {
      const field = fieldsMap.get(value)
      if (field) {
        field.checked = true
        checkedFields.push(field)
      }
    })

    // Add unchecked fields
    initialNoteTypesAndTheirFields[`${noteType}_${cardSide}`].forEach(
      (field) => {
        if (!field.checked) {
          uncheckedFields.push(field)
        }
      }
    )

    // Combine checked and unchecked fields
    const combined = checkedFields.concat(uncheckedFields)
    initialNoteTypesAndTheirFields[`${noteType}_${cardSide}`] = combined
  }

  // console.log(SRSFormData)
  return (
    <SettingsContainer>
      <TabContainer>
        <Tab
          onClick={() => setActiveTab('account')}
          $isActive={activeTab === 'account'}
        >
          Účet
        </Tab>
        <Tab
          onClick={() => setActiveTab('cards')}
          $isActive={activeTab === 'cards'}
        >
          Karty
        </Tab>

        <Tab
          onClick={() => setActiveTab('SRS')}
          $isActive={activeTab === 'SRS'}
        >
          Chytré opakování
        </Tab>
      </TabContainer>

      <TabContentContainer>
        {activeTab === 'account' && (
          <SettingsTabContent
            settings={{
              email: {
                key: 1,
                label: 'email',
                content: user?.account?.email,
              },
              premium: {
                key: 2,
                label: 'prémium',
                content: user?.account?.premium ? (
                  'ano'
                ) : (
                  <>
                    ne <Button text="Získat premium" />
                  </>
                ),
              },
            }}
          />
        )}
        {activeTab === 'cards' && (
          <SettingsContentCards
            englishToCzech_front={englishToCzech_front}
            setEnglishToCzech_front={setEnglishToCzech_front}
            englishToCzech_back={englishToCzech_back}
            setEnglishToCzech_back={setEnglishToCzech_back}
            czechToEnglish_front={czechToEnglish_front}
            setCzechToEnglish_front={setCzechToEnglish_front}
            czechToEnglish_back={czechToEnglish_back}
            setCzechToEnglish_back={setCzechToEnglish_back}
          />
        )}
        {activeTab === 'SRS' && (
          <>
            <h3>Obecné</h3>
            <SettingsTabContent
              settings={{
                useAppSRS: {
                  key: 3,
                  label: 'Ukládat slovíčka do:',
                  content: (
                    <BasicSelect
                      value={SRSFormData.useAppSRS ? 'app' : 'anki'}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          useAppSRS: e.target.value === 'app' ? true : false,
                        })
                      }
                    >
                      <option value="app">aplikace</option>
                      <option value="anki">Anki</option>
                    </BasicSelect>
                  ),
                },
                learnAhead: {
                  key: 45,
                  label: 'učit se dopředu (min.)',
                  content: (
                    <InputBasic
                      type="text"
                      value={SRSFormData.learnAhead}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          learnAhead: e.target.value,
                        })
                      }
                    />
                  ),
                },
                audioAutoplay: {
                  key: 46,
                  label: 'Automaticky přehrát výslovnost',
                  content: (
                    <BasicSelect
                      value={SRSFormData.audioAutoplay}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          audioAutoplay: e.target.value,
                        })
                      }
                    >
                      <option value={true}>ano</option>
                      <option value={false}>ne</option>
                    </BasicSelect>
                  ),
                },
              }}
            />
            <h3>Nové karty</h3>
            <SettingsTabContent
              settings={{
                max_per_day: {
                  key: 4,
                  label: 'Max. k učení/den',
                  content: (
                    <InputBasic
                      type="number"
                      step={5}
                      min={0}
                      value={SRSFormData.max_per_day}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          max_per_day: e.target.value,
                        })
                      }
                    />
                  ),
                },
                steps: {
                  key: 5,
                  label: 'první intervaly učení',
                  content: (
                    <InputBasic
                      type="text"
                      value={SRSFormData.steps.join(' ')}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          steps: e.target.value.split(' '),
                        })
                      }
                    />
                  ),
                },
                graduating_interval: {
                  key: 6,
                  label: 'interval absolvování',
                  content: (
                    <InputBasic
                      type="number"
                      step={1}
                      min={0}
                      value={SRSFormData.graduating_interval}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          graduating_interval: e.target.value,
                        })
                      }
                    />
                  ),
                },
                easy_interval: {
                  key: 7,
                  label: 'interval pro snadné',
                  content: (
                    <InputBasic
                      type="number"
                      step={1}
                      min={0}
                      value={SRSFormData.easy_interval}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          easy_interval: e.target.value,
                        })
                      }
                    />
                  ),
                },
                starting_ease: {
                  key: 8,
                  label: 'počáteční faktor snadnosti',
                  content: (
                    <InputBasic
                      type="number"
                      step={0.1}
                      min={0}
                      value={SRSFormData.starting_ease}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          starting_ease: e.target.value,
                        })
                      }
                    />
                  ),
                },
              }}
            />
            <h3>Opakované karty</h3>
            <SettingsTabContent
              settings={{
                easy_multiplier: {
                  key: 9,
                  label: 'Multiplikátor pro snadné',
                  content: (
                    <InputBasic
                      type="number"
                      step={0.1}
                      min={0}
                      value={SRSFormData.easy_multiplier}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          easy_multiplier: e.target.value,
                        })
                      }
                    />
                  ),
                },
                hard_multiplier: {
                  key: 10,
                  label: 'Multiplikátor pro těžké',
                  content: (
                    <InputBasic
                      type="number"
                      step={0.1}
                      min={0}
                      value={SRSFormData.hard_multiplier}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          hard_multiplier: e.target.value,
                        })
                      }
                    />
                  ),
                },
                interval_factor: {
                  key: 11,
                  label: 'Multiplikátor intervalu',
                  content: (
                    <InputBasic
                      type="number"
                      step={0.1}
                      min={0}
                      value={SRSFormData.interval_factor}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          interval_factor: e.target.value,
                        })
                      }
                    />
                  ),
                },
              }}
            />
            <h3>Chyby</h3>
            <SettingsTabContent
              settings={{
                lapse_steps: {
                  key: 12,
                  label: 'intervaly po chybě',
                  content: (
                    <InputBasic
                      type="text"
                      value={SRSFormData.lapse_steps.join(' ')}
                      // value={SRSFormData.lapse_steps}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          lapse_steps: e.target.value.split(' '),
                          // lapse_steps: e.target.value,
                        })
                      }
                    />
                  ),
                },
                lapse_multiplier: {
                  key: 13,
                  label: 'Multiplikátor pro chybné',
                  content: (
                    <InputBasic
                      type="number"
                      step={0.1}
                      min={0}
                      value={SRSFormData.lapse_multiplier}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          lapse_multiplier: e.target.value,
                        })
                      }
                    />
                  ),
                },
                lapse_min_interval: {
                  key: 14,
                  label: 'Minimální interval',
                  content: (
                    <InputBasic
                      type="number"
                      step={1}
                      min={0}
                      value={SRSFormData.lapse_min_interval}
                      onChange={(e) =>
                        setSRSFormData({
                          ...SRSFormData,
                          lapse_min_interval: e.target.value,
                        })
                      }
                    />
                  ),
                },
              }}
            />
          </>
        )}
        <Button
          text="Uložit změny"
          onClick={() => {
            handleSubmitSettingsChanges()
          }}
        />
      </TabContentContainer>
    </SettingsContainer>
  )
}

export default Settings

const SettingsContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;

  & h3 {
    /* text-align: center; */
    font-size: 1.2rem;
    font-weight: bolder;
  }
`

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`
const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`

const Tab = styled.button`
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: inherit;
  font-size: 16px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  /* border-bottom-color: ${({ $isActive }) =>
    $isActive ? '#333' : 'transparent'}; */
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 'normal')};
`
