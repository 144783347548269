import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'

import styles from '../resizable-panels.module.css'
import ResizeHandle from '../ResizeHandle.jsx'
import CzDict from './CzDict.jsx'

// import Images from './Images.jsx'
import { default as Gallery } from './Gallery.jsx'
import Gallery2 from './Gallery2.jsx'
import { useKeyword } from '../../../contexts/KeywordContext.jsx'
import { useActivePanels } from '../../../contexts/ActivePanels.jsx'

const Right = ({ handlePanelResize, setCzShort }) => {
  const { keyword } = useKeyword()
  const { activePanels } = useActivePanels()

  const galleryRef = useRef(null) // Ref for Panel component
  const czDictRef = useRef(null)
  const [galleryPanelHeight, setGalleryPanelHeight] = useState('50px') // State to store the height for Gallery component

  const handleGalleryPanelResize = (size) => {
    const panelSize_percent = size // 50
    const viewportHeight_px = window.innerHeight // 700px
    const imageSize = viewportHeight_px * (panelSize_percent / 100) - 5
    // console.log('viewportHeight', viewportHeight_px)
    // console.log('panelSize', panelSize_percent)
    // console.log(imageSize)
    setGalleryPanelHeight(imageSize)

    // update activePanels context
    handlePanelResize('gallery', size)
  }

  const handleCzDictPanelResize = (size) => {
    handlePanelResize('czDict', size)
  }

  const handleRightPanelResize = (size) => {
    handlePanelResize(
      'right',
      size,
      czDictRef.current.getSize(),
      galleryRef.current.getSize()
    )
  }

  useEffect(() => {
    // Using a timeout to delay the execution
    const timer = setTimeout(() => {
      if (galleryRef.current) {
        const panelSize_percent = galleryRef.current.getSize() // 50
        const viewportHeight_px = window.innerHeight // 700px
        const imageSize = viewportHeight_px * (panelSize_percent / 100) - 5
        // console.log('viewportHeight', viewportHeight_px)
        // console.log('panelSize', panelSize_percent)
        // console.log(imageSize)
        setGalleryPanelHeight(imageSize)
      }
    }, 1000) // Delaying by 1000 milliseconds (1 second)

    // Cleanup function to clear the timeout
    return () => clearTimeout(timer)
  }, [])

  return (
    <Panel
      className={styles.Panel}
      collapsible={true}
      order={3}
      defaultSize={30}
      onResize={handleRightPanelResize}
      id={'right'}
    >
      <PanelGroup direction="vertical" autoSaveId="rightGroup">
        {/* <Panel>
          <div className={styles.PanelContent}>quick links</div>
        </Panel>
        <ResizeHandle /> */}
        <Panel
          order={1}
          collapsible={true}
          ref={czDictRef}
          // defaultSize={65}
          onResize={handleCzDictPanelResize}
          id={'czDict'}
        >
          <div className={styles.PanelContent}>
            {activePanels.czDict && <CzDict setCzShort={setCzShort} />}
          </div>
        </Panel>
        <ResizeHandle />
        <Panel
          order={2}
          collapsible={true}
          ref={galleryRef}
          onResize={handleGalleryPanelResize}
          id={'gallery'}
        >
          <div
            className={styles.PanelContent}

            // style={{ height: galleryHeight }}
          >
            {/* <Images /> */}
            {activePanels.gallery && (
              <Gallery imageHeight={galleryPanelHeight} />
            )}
            {/* <Gallery2 /> */}
          </div>
        </Panel>
      </PanelGroup>
    </Panel>
  )
}

export default Right
