import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import './style/flexboxgrid.min.css'
import './style/index.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode> // nefunguje v něm dragging of items in /opakovani -> cards; vždy vypnout pro jeho testování
  <App />
  // </React.StrictMode>
)
