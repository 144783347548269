import { useState, useRef } from 'react'
import { axiosWithoutBearer } from '/src/services/axiosInstances.js'
import ReCAPTCHA from 'react-google-recaptcha'

import RightSvg from '/src/components/svg/Right-svg'
import Title from '/src/components/Title'
import Button from '/src/components/10_utils/Button'
import { motion } from 'framer-motion'
import { contactUs } from '/src/services/fetchOther.js'
import { toast } from 'react-toastify'
import { handleRequestError } from '/src/utils/handleRequestError.js'
// handleRequestError(err)

const ContactForm = () => {
  const captchaRef = useRef(null)

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleContactMe = async (e) => {
    e.preventDefault()
    const token = captchaRef.current.getValue()
    captchaRef.current.reset()

    // console.log('token ', token)
    captchaRef.current.reset()
    const metadata = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      subject: formData.subject,
    }
    contactUs(formData.message, metadata, token)
      .then((res) => {
        toast.success('Děkuji za zprávu, brzy se Vám ozvu')
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          subject: '',
          message: '',
        })
        // if (res.status >= 400) {
        //   throw Error('Něco se pokazilo, zkuste to prosím znovu')
        // }
      })
      .catch((err) => {
        // handleRequestError(err)
        // if (err?.response?.data?.userMessage)
        //   toast.error(err.response.data.userMessage)
        // else toast.error('Něco se pokazilo, zkuste to prosím znovu')
      })
  }
  const formVariants = {
    initial: {
      y: -100,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  }

  return (
    <motion.div
      className="py-5 relative"
      variants={formVariants}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
    >
      <div className="container mx-auto px-10">
        <Title
          header="Kontaktujte mě"
          hasSpan={false}
          textBelow={'Můžete mi také napsat přímo na info@anglictinomat.cz'}
        />
        <form className="w-full md:w-3/4 lg:w-2/4 mx-auto">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 sm:gap-0 md:gap-5">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="jméno"
              className="block w-full mb-5 p-2 bg-inherit border border-gray-600 rounded-lg"
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="příjmení"
              className="block w-full mb-5 p-2 bg-inherit border border-gray-600 rounded-lg"
            />
          </div>
          <input
            type="email"
            name="email"
            placeholder="email"
            value={formData.email}
            onChange={handleChange}
            className="block w-full mb-5 p-2 bg-inherit border border-gray-600 rounded-lg"
          />
          <input
            type="text"
            name="subject"
            placeholder="předmět"
            value={formData.subject}
            onChange={handleChange}
            className="block w-full mb-5 p-2 bg-inherit border border-gray-600 rounded-lg"
          />
          <textarea
            className="block w-full mb-5 p-2 bg-inherit border border-gray-600 rounded-lg"
            name="message"
            placeholder="zpráva"
            value={formData.message}
            onChange={handleChange}
            rows={5}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0.3rem auto',
            }}
          >
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
            />
          </div>
          <Button
            text="poslat zprávu"
            onClick={(e) => {
              handleContactMe(e)
            }}
            inlineStyle={{ margin: '0 auto', display: 'block', width: '100%' }}
          />
          {/* <button className="bg-main py-2 px-4 w-full">poslat zprávu</button> */}
        </form>
      </div>
      <div className="absolute top-0 right-0 -z-10">
        <RightSvg />
      </div>
    </motion.div>
  )
}

export default ContactForm
