import React, { useState, useEffect } from 'react'
import InputSearchForm from '/src/components/InputSearchForm'
import MenuComponent from '/src/components/MenuComponent'
import ModalContentConfirmationAlert from '/src/components/ModalContentConfirmationAlert'
import Button from '/src/components/10_utils/Button'
import H2 from '/src/components/H2'
import { BsThreeDotsVertical } from 'react-icons/bs'
import styled from 'styled-components'
import {
  useCreateDeck,
  useRenameDeck,
  useDeleteDeck,
} from '/src/hooks/useVocabAPI'
import Modal from '/src/components/10_utils/Modal'
import { useModal } from '../../contexts/ModalContext'
import { toast } from 'react-toastify'
import Loading from '/src/components/Loading'
import { getNumberOfCardsInDeck } from '/src/services/fetchDecks'

const ReviewOptions = ({ decks, handleDeckClick, isDecksLoading }) => {
  const { showModal, setShowModal, modalContent, setModalContent } = useModal()
  const [action, setAction] = useState('')
  const [deckNameToActOn, setDeckNameToActOn] = useState('')
  const [newDeckName, setNewDeckName] = useState('')
  const handleDeckOptionsClick = () => {
    console.log('handleDeckOptionsClick')
  }
  const [numberOfCardsInClickedDeck, setNumberOfCardsInClickedDeck] =
    useState(0)
  // const [triggerFetch, setTriggerFetch] = useState(false)

  useEffect(() => {
    if (modalContent === null) {
      setAction('')
      // setDeckNameToActOn('')
    }
  }, [modalContent])

  useEffect(() => {
    // if (triggerFetch) {
    if (action === 'delete') {
      getNumberOfCardsInDeck(deckNameToActOn)
        .then((cardsCount) => {
          setNumberOfCardsInClickedDeck(cardsCount)
        })
        .catch((error) => {
          console.error(error)
          // Handle error (e.g., show error message)
        })
      // .finally(() => {
      //   setTriggerFetch(false) // Reset trigger
      // })
    }
  }, [action])

  useEffect(() => {
    console.log('action ', action)
  }, [action])

  // useEffect(() => {
  //   if (action === 'delete') {
  //     setTriggerFetch(true)
  //   }
  // }, [action])

  // useEffect(() => {
  //   async function fetchNumberOfCards() {
  //     const numCards = await getNumberOfCardsInDeck(deckNameToActOn)
  //     setNumberOfCardsInClickedDeck(numCards)
  //   }
  //   if(numberOfCardsInClickedDeck_needed){

  //     fetchNumberOfCards()

  //   }
  //   // let deckId = ''
  //   // for (let i = 0; i < decks.length; i++) {
  //   //   if (decks[i].value === deckName) {
  //   //     deckId = decks[i]._id
  //   //     return
  //   //   }
  //   // }
  // }, [numberOfCardsInClickedDeck_needed])

  console.log('decks ', decks)
  console.log('decknametoacton ', deckNameToActOn)

  console.log('action ', action)
  const getModalContent = (action, deckNameToActOn) => {
    console.log(' switch action ', action)
    console.log(' switch deckname ', deckNameToActOn)
    switch (action) {
      case 'create':
        return (
          <ModalContent>
            <H2>Název nového balíčku:</H2>
            <InputSearchForm
              value={newDeckName}
              onChange={(e) => setNewDeckName(e.target.value)}
              onSubmit={(e) => createNewDeck(e, newDeckName)}
              customSubmitButton={
                <Button
                  type="submit"
                  text="vytvořit"
                  backgroundColor="var(--color-green)"
                />
              }
            />
          </ModalContent>
        )
      case 'rename':
        return (
          <ModalContent>
            <H2>Nový název balíčku:</H2>
            <InputSearchForm
              value={newDeckName}
              onChange={(e) => setNewDeckName(e.target.value)}
              onSubmit={(e) => renameDeck(e, deckNameToActOn, newDeckName)}
              customSubmitButton={
                <Button
                  type="submit"
                  text="přejmenovat"
                  backgroundColor="var(--color-green)"
                />
              }
            />
          </ModalContent>
        )
      case 'delete':
        return (
          <ModalContent>
            <ModalContentConfirmationAlert
              message={`Opravdu chcete smazat tento balíček?`}
              detail={`obsahuje celkem karet: ${numberOfCardsInClickedDeck} `}
              onConfirm={(e) => {
                console.log(
                  'deckname to act on těsně před fetchnutím: ',
                  deckNameToActOn
                )
                deleteDeck(e, deckNameToActOn)
              }}
              // onCancel={() => { setModalContent(null)}; setAction('')}
              onCancel={() => {
                setModalContent(null)
                setAction('')
              }}
            />
          </ModalContent>
        )
      default:
        return null
    }
  }

  const doDeckAction = (action, deckNameToActOn) => {
    setModalContent('renameDeck')
    setDeckNameToActOn(deckNameToActOn)
    setAction(action)
  }
  // console.log(newDeckName)

  const deleteDeck = (e, deckName) => {
    deleteDeckMutation.mutate([deckName])
    setModalContent(null)
    setAction('')
  }
  const renameDeck = (e, deckName, newDeckName) => {
    e.preventDefault()

    // check if name is free
    const insertedNewDeckName = newDeckName.trim()
    for (let i = 0; i < decks.length; i++) {
      if (decks[i].value === insertedNewDeckName) {
        toast.error('Takový balíček už existuje, zvolte jiné jméno', {
          autoClose: 3000,
        })
        return
      }
    }

    renameDeckMutation.mutate([deckName, newDeckName])
    setNewDeckName('')
    setModalContent(null)
    setAction('')
  }

  const reviewAll = () => {}

  const createDeckMutation = useCreateDeck(() => {
    console.log('deck created')
  })

  const renameDeckMutation = useRenameDeck(() => {
    console.log('deck renamed')
  })

  const deleteDeckMutation = useDeleteDeck(() => {
    console.log('deck renamed')
  })

  const createNewDeck = (e, deckName) => {
    e.preventDefault()
    const newDeckName = deckName.trim()
    for (let i = 0; i < decks.length; i++) {
      if (decks[i].value === newDeckName) {
        toast.error('Takový balíček už existuje, zvolte jiné jméno', {
          autoClose: 3000,
        })
        return
      }
    }

    createDeckMutation.mutate([newDeckName])
    setNewDeckName('')
    setModalContent(null)
    setAction('')
  }

  return (
    <div>
      <H2>Balíčky</H2>
      {isDecksLoading ? (
        <Loading />
      ) : (
        <ButtonsContainer>
          {decks.map((deck) => {
            const newCardsCount = deck.statusInfo.new
            const toReviewCardsCount =
              deck.statusInfo.toReview + deck.statusInfo.learning
            return (
              // const new = deck.statusInfo.new
              <Row key={deck._id}>
                <Button
                  inlineStyle={{
                    maxWidth: '400px',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    textAlign: 'center',
                    fontSize: 'var(--font-size-small)',
                    borderTopRightRadius: '0',
                    borderBottomLeftRadius: '0',
                    borderRight: '1px solid black !important',
                    border: '1px solid black',
                    borderLeft: 'none',
                    borderTop: 'none',
                    borderBottom: 'none',
                  }}
                  title="balíček"
                  id={deck._id}
                  onClick={() => {
                    handleDeckClick(
                      deck.value,
                      deck._id,
                      newCardsCount,
                      toReviewCardsCount
                    )
                  }}
                >
                  {deck.value}
                </Button>
                <DeckStatus title="status">
                  {newCardsCount > 0 && <div> nové:{newCardsCount} </div>}

                  {toReviewCardsCount > 0 && (
                    <div> opakovat: {toReviewCardsCount} </div>
                  )}

                  {newCardsCount === 0 && toReviewCardsCount === 0 && (
                    <div>
                      {' '}
                      <div style={{}}>
                        {toReviewCardsCount > 0 &&
                          `opakovat: ${toReviewCardsCount}`}
                      </div>
                      ✅
                    </div>
                  )}
                </DeckStatus>

                <MenuComponent
                  button={
                    <Button
                      onClick={handleDeckOptionsClick}
                      padding="0.4rem 0.3rem"
                      inlineStyle={{
                        display: 'flex',
                        borderRadiusLeft: 'none',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderTopRightRadius: '0',
                        borderBottomLeftRadius: '0',
                        borderLeft: '1px solid black !important',
                        border: '1px solid black',
                        borderRight: 'none',
                        borderTop: 'none',
                        borderBottom: 'none',
                      }}
                      title="možnosti"
                    >
                      <BsThreeDotsVertical />
                    </Button>
                  }
                  items={[
                    {
                      text: 'smazat',
                      onClick: () => {
                        if (decks.length === 1) {
                          toast.info(
                            'Nelze smazat jediný balíček, vždy musíte mít aspoň jeden.',
                            {
                              autoClose: 5000,
                            }
                          )
                          return
                        }
                        doDeckAction('delete', deck.value)
                      },
                    },
                    {
                      text: 'přejmenovat',
                      onClick: () => doDeckAction('rename', deck.value),
                    },
                  ]}
                />

                {/* <Button>
              <BsThreeDotsVertical />
            </Button> */}
              </Row>
            )
          })}
        </ButtonsContainer>
      )}

      <H2>Další možnosti</H2>
      <OtherOptions>
        {/* <Button
          text="opakovat vše"
          onClick={reviewAll}
          backgroundColor="var(--color-white)"
          color="var(--color-black)"
          border="var(--border)"
        /> */}
        <Button
          text="+ nový balíček"
          onClick={() => doDeckAction('create')}
          backgroundColor="var(--color-green)"
        />
      </OtherOptions>
      {/* modal */}
      {/* {showModal && <Modal>{getModalContent(action, deckNameToActOn)}</Modal>} */}
      {modalContent && (
        <Modal>{getModalContent(action, deckNameToActOn)}</Modal>
      )}
    </div>
  )
}

export default ReviewOptions

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
`

const DeckName = styled.div``

const DeckStatus = styled.div`
  width: 35%;
  font-size: var(--font-size-super-small);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.2rem;
`

const Row = styled.div`
  color: var(--color-black);
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  width: 95%;

  margin: 0.4rem auto;
  /* padding: 0.4rem 0.3rem; */
  border: 1px solid black;
  border-radius: var(--border-radius);
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
`

const OtherOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  margin-bottom: 1rem;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
  width: 85%;
  max-width: 400px;
`
