export default function formatDate(dateString, format) {
  if (format === 'dd.mm.yyyy') {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()

    return `${day}.${month}.${year}`
  }
}

export function formatTimeFromMinutes(minutes) {
  // get days , hours etc from minutes (for review buttons)
  // const minutesInYear = 525600 // 365 days
  // const minutesInMonth = 43200 // Based on an average month length of 30.44 days
  // const minutesInDay = 1440 // 24 hours
  const minutesInHour = 60

  // if (minutes >= minutesInYear) {
  //   const years = Math.floor(minutes / minutesInYear)
  //   return `${years} rok`
  // } else if (minutes >= minutesInMonth) {
  //   const months = Math.floor(minutes / minutesInMonth)
  //   return `${months} měs`
  // } else if (minutes >= minutesInDay) {
  //   const days = Math.floor(minutes / minutesInDay)
  //   return `${days} d`
  // }
  if (minutes >= minutesInHour) {
    const hours = Math.floor(minutes / minutesInHour)
    return `${hours} hod`
  } else {
    return `${Math.ceil(minutes)} min`
  }
}
