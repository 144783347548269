import { axiosBearer } from './axiosInstances.js'

export async function getAIchatAnswer(
  newPrompt,
  previousMessages,
  previousMessages_usedTokens
) {
  try {
    const response = await axiosBearer.post('/other/AIchat', {
      newPrompt,
      previousMessages,
      previousMessages_usedTokens,
    })
    // const completion = response.data.completion
    // const usage = response.data.usage
    // return { completion, usage}
    return response.data
  } catch (error) {
    if (error?.response?.message === 'no tokens') {
      return error.response.data.message
    }
    // Handle other errors as needed
  }
}
