import { createContext, useContext, useState } from 'react'

const ActivePanelsContext = createContext()

export function useActivePanels() {
  return useContext(ActivePanelsContext)
}

export function ActivePanelsProvider({ children }) {
  const [activePanels, setActivePanels] = useState({
    left: true,

    czDict: false,
    gallery: false,
  })

  const value = {
    activePanels,
    setActivePanels,
  }

  return (
    <ActivePanelsContext.Provider value={value}>
      {children}
    </ActivePanelsContext.Provider>
  )
}
