import React, { useState } from 'react'
import { useAuth } from '/src/contexts/AuthContext'
import { signUp, sendVerificationEmail } from '/src/services/fetchUser'
import Button from '/src/components/10_utils/Button'
import { FaCheckCircle } from 'react-icons/fa'
import styled from 'styled-components'

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  /* height: 100vh;
  background-color: #f5f5f5; */
`

// const Message = styled.p`
//   font-size: 1.2rem;
//   color: #333;
//   margin-bottom: 20px;
// `

// Component
const ConfirmEmailPage = () => {
  const { currentUser, register, setError, logout } = useAuth()
  const [confirmationEmailResent, setConfirmationEmailResent] = useState(false)

  const handleConfirmEmail = async () => {
    // Handle confirm email logic
    console.log('Confirming email...')
    await sendVerificationEmail(currentUser.email)
      .then((res) => {
        console.log(res)
        setConfirmationEmailResent(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Container>
      {confirmationEmailResent ? (
        <>
          <h2>Potvrzovací email odeslán</h2>
          <FaCheckCircle color="rgb(26, 137, 23)" fontSize={'4.5rem'} />
        </>
      ) : (
        <>
          <h3 style={{ fontSize: '1.3rem' }}>
            Nemáte ověřený email. Prosím, zkontrolujte svůj email včetně koše a
            spamu.
          </h3>
          <Button
            inlineStyle={{ fontSize: '1.1rem' }}
            onClick={handleConfirmEmail}
          >
            Poslat potvrzovací email znovu
          </Button>
        </>
      )}
    </Container>
  )
}

export default ConfirmEmailPage
